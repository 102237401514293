import {
  Affix,
  Button,
  Checkbox,
  CheckboxProps,
  Form,
  Input,
  Radio,
  Space,
  Spin,
  Tooltip,
  message,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { TagSelect } from "components/tagSelect";
import { ImageCrop } from "components/crop-img";
import { getFileUrlByCosUpload } from "config/cos";
import { getFileName } from "utils/functions/common";
import { CosDirectoryType } from "types/enums/media-type";
import { apiDraftWorkDetail, getWorkCategory } from "api/work";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SubUpdateStyle } from "./style";
import { SubPicturesWall } from "../components/uploadImageList";
import { SubVideoWall } from "../components/uploadVideoList";
import {
  apiCreateSubWork,
  apiGetMediaField,
  apiGetSubSetting,
  apiGetWorkField,
  apiUpdateSubWork,
} from "api/sub";
import { SignupField, UploadMediaField } from "types/sub/application";
import { SingleSelect } from "../components/single-select";
import { CategorySecondSelect } from "../components/category-second-select";
import { ListItem } from "views/work/components/comboUpload/type";
import { MediaListItem } from "types/media";
import { SubChildrenSelect } from "../components/children-select";
import { CreationWare } from "views/work/update/creationWare";
import CzwjModal from "./czwjModal";
import TinyEditor from "ui-lib/tinyMCE";
import { RiQuestionLine } from "react-icons/ri";
import Collabration, {
  collabrationUserSimple,
} from "views/work/update/collabration";
import { draftWorkDetailType } from "types/work";
import { MediaCategorySecondSelect } from "../components/media-category-second-select";
import ComboUpload from "views/work/components/comboUpload";
import GrandContestSelect from "../components/grand-contest-select";
import { getContestDetail } from "api/contest";
import { ContestDetailResponse } from "types/contest";
import { SecondSelect } from "components/work-category";
import { WareItem, apiGetWareList } from 'api/ware';
import TagList from "views/user-creator-center/work/tag-list";
import { CategoryItem } from "types/user/work";
import ImagePreviewPc from "./imagePreview/pc";
import ImagePreviewMobile from "./imagePreview/mobile";
import { apiGetDictList } from "api/common";

type WorkUpdateForm = {
  moreDetail?: ListItem[];
  detailSupplementImage?: ListItem[];
  draftImage?: ListItem[];
  effectImage?: ListItem[];
  effectVideo?: ListItem[];
  processImage?: ListItem[];
  textureImage?: ListItem[];
  threeDImage?: ListItem[];
  content: { html: string; media: number[] };
  theme: number[];
  themeA: number[];
  creativeLab: number[];
  team: number[];
  mediaCategory: number[];
  category: number[];
  title: string;
  tagList: string[];
  categoryId: number;
  coverUrl: {
    file?: File;
    imgUrl?: string;
  };
  coverMobileUrl: {
    file?: File;
    imgUrl?: string;
  };
  description: string;
  hardwareList?: string[];
  softwareList?: string[];
  categoryIds: number[]
};


type AntSelectOption = {
  label: string;
  value: string | number;
};

export const GContestUpload = () => {
  const navigate = useNavigate();
  const [top] = useState(64);
  const [isShowCzwjModal, setIsShowCzwjModal] = useState(false);

  const [form] = Form.useForm<WorkUpdateForm>();

  const isAni = Form.useWatch('isAni', { form, preserve: true });
  // const team = Form.useWatch('team', { form, preserve: true });
  const mediaCategory = Form.useWatch("mediaCategory", {
    form,
    preserve: true,
  });
  const initTitle = Form.useWatch("title", form);
  const initDescription = Form.useWatch("description", form);
  const initTheme = Form.useWatch("theme", form);

  const [chooseArticle, setChooseArticle] = useState(false);

  const [autoImage, setAutoImage] = useState(false);

  const [coverUrlFile, setCoverUrlFile] = useState<{
    file?: File;
    imgUrl?: string;
  }>();

  const [coverMobileUrlFile, setCoverMobileUrlFile] = useState<{
    file?: File;
    imgUrl?: string;
  }>();

  const [loading, setLoading] = useState(false);

  const [fieldList, setFieldList] = useState<SignupField[]>();

  const [mediaFieldList, setMediaFieldList] = useState<UploadMediaField[]>([]);

  const [cate, setCate] = useState<any>("");

  const [creatorList, setCreatorList] = useState<collabrationUserSimple[]>([]);
  const [detail, setDetail] = useState<draftWorkDetailType>();
  const [copyDetail, setCopyDetail] = useState<draftWorkDetailType>();


  let { id } = useParams();
  const [contestDetail, setContestDetail] = useState<ContestDetailResponse>()
  const [categoryList, setCategoryList] = useState<CategoryItem[]>([])

  const [isSelectPcCover, setIsSelectPcCover] = useState(true) // PC 手机端封面

  const [isSelectRecruit, setIsSelectRecruit] = useState(true)
  const [placeholder, setPlaceholder] = useState('在这里介绍你的作品，分享你的故事')
  const [isGameDesigner, setIsGameDesigner] = useState(false) // 创作类型是否游戏策划

  const [formValues, setFormValues] = useState<{
    title: string;
    categoryIds: number[];
    mediaCategory: number[];
  }>();

  const from = new URLSearchParams(useLocation().search).get("from") || ""

  useEffect(() => {
    getWorkCategory({ useWorkUpload: 1, isContest: 1 }).then(res => {
      setCategoryList(res)
    })
    getMediaCategoryOptions()
  }, [])

  useEffect(() => {
    getContestDetail({ id: Number(id) }).then(res => {
      setContestDetail(res)
    })
  }, [id])

  let [searchParams] = useSearchParams();

  const workId = searchParams.get("workId");

  useEffect(() => {
    // 从2025CUSGA比赛入口进入的比赛直接选中对于的类型 游戏策划
    if (from === '2025cusga' && !workId) {
      // form.setFieldValue("mediaCategory", [10])
      if (!fieldList) {
        return
      }
      const theme = fieldList.find(item => item.code === 'theme')
      const options = theme?.options
      const puzzleTheme = options?.find(item => item.name === 'Puzzle Pro组队邀约')
      form.setFieldValue('theme', [puzzleTheme?.id, 10])
    }
  }, [from, workId, fieldList])



  const [softwareList, setSoftwareList] = useState<string[]>([]);
  const [style3DSpecialAwardFlag, setStyle3DSpecialAwardFlag] = useState(0);
  const [style3DSpecialAward, setStyle3DSpecialAward] = useState(0);

  const [mediaCategoryOptions, setMediaCategoryOptions] =
    useState<AntSelectOption[]>();

  const isChooseStyle3DSpecialAward: CheckboxProps['onChange'] = (e) => {
    setStyle3DSpecialAward(e.target.checked == true ? 1 : 0);
    if (e.target.checked == true) {
      let chooseSoftWares = form.getFieldValue("softwareList") ? form.getFieldValue("softwareList") : [];
      let chooseTags = form.getFieldValue("tagList") ? form.getFieldValue("tagList") : [];
      if (Array.isArray(chooseTags)) {
        if (chooseTags.length >= 0 && chooseTags.length < 5) {
          if (!chooseTags.includes("Style3D")) {
            chooseTags.push("Style3D");
          }
        }
      } else {
        chooseTags.push("Style3D");
      }
      if (Array.isArray(chooseSoftWares)) {
        if (!chooseSoftWares.includes("Style3D Atelier")) {
          chooseSoftWares.push("Style3D Atelier");
        }
        if (!chooseSoftWares.includes("Style3D Simulator")) {
          chooseSoftWares.push("Style3D Simulator");
        }
      } else {
        chooseSoftWares = chooseSoftWares.concat(["Style3D Atelier", "Style3D Simulator"]);
      }
      form.setFieldsValue({
        softwareList: chooseSoftWares,
        tagList: chooseTags,
      });
    }
  };

  const mediaNameList = [
    "effectVideo",
    "effectImage",
    "draftImage",
    "processImage",
    "textureImage",
    "threeDImage",
    "detailSupplementImage",
    "moreDetail",
  ];


  useEffect(() => {
    let mediaCategory = form.getFieldValue("mediaCategory");
    if (form.getFieldValue("mediaCategory")) {
      let index = fieldList?.findIndex((item) => item.code === "mediaCategory");
      if (index === -1) {
        return;
      }
      // 原创未来 选择文章
      let location = fieldList![index!].options.findIndex(
        (item) => item.name === "文章"
      );
      if (
        location > -1 &&
        fieldList![index!].options[location].id === mediaCategory[0]
      ) {
        setChooseArticle(true);
      } else {
        setChooseArticle(false);
      }
      apiGetMediaField({ mediaCategory: mediaCategory[0] }).then((res) => {
        const listData = res.filter((item) => {
          return ![
            "processImage",
            "draftImage",
            "detailSupplementImage",
          ].includes(item.code);
        });
        setMediaFieldList(listData);
      });
    } else {
      setChooseArticle(false);
      apiGetMediaField().then((res) => {
        const listData = res.filter((item) => {
          return ![
            "processImage",
            "draftImage",
            "detailSupplementImage",
          ].includes(item.code);
        });
        setMediaFieldList(listData);
      });
    }
  }, [mediaCategory]);


  useEffect(() => {
    if (contestDetail && contestDetail.code === '2025cusga') {
      if (!!isGameDesigner) {
        mediaFieldList.forEach(item => {
          if (item.code === 'effectImage') {
            item.name = '游戏方案图'
          }
        })
      } else {
        mediaFieldList.forEach(item => {
          if (item.code === 'effectImage') {
            item.name = '最终效果图'
          }
        })
      }
      setMediaFieldList([...mediaFieldList]);
    }
  }, [contestDetail, isGameDesigner, mediaFieldList])

  const getItem = (list: MediaListItem[]) => {
    return list?.map((item) => {
      return {
        id: item.id,
        url: item.url,
        key: item.uuid,
        percent: 100,
        coverUrl: item.coverUrl,
        type: item.type,
        caption: item.caption,
        params: {
          hdrImageUrl: item.params?.hdrImageUrl,
          threeDBgUrl: item.params?.threeDBgUrl,
          specialEffectTemplateId: item.params?.specialEffectTemplateId,
          specialEffectVideo: item.params?.specialEffectVideo,
        },
      };
    });
  };

  useEffect(() => {
    if (id) {
      apiGetSubSetting({ id: id }).then((res) => {
        if (res.autoCoverFlag) {
          setAutoImage(true);
        } else {
          setAutoImage(false);
        }
        if (res.code === "ggac6") {
          setStyle3DSpecialAwardFlag(1);
        }
      });
      let tempCate: any = "theme";
      apiGetWorkField(+id!).then((resField) => {
        setFieldList(resField);
        resField.forEach((item) => {
          if (item.hasCategory) {
            tempCate = item.code;
            setCate(item.code);
          }
        });
        if (workId) {
          apiDraftWorkDetail(+workId!).then((res) => {
            setDetail(res);
            setCopyDetail(res)
            let {
              categoryList,
              title,
              description,
              hardwareList,
              softwareList,
              mediaCategory,
              contestWork,

            } = res;
            let {
              theme,
              detailSupplementImage,
              draftImage,
              effectImage,
              theme2,
              effectVideo,
              processImage,
              textureImage,
              threeDImage,
              team,
              moreDetail,
              themeA
            } = contestWork;

            setIsSelectRecruit(!!res.isRecruit)
            setCoverUrlFile({
              file: undefined,
              imgUrl: res.coverUrl,
            });

            setCoverMobileUrlFile({
              file: undefined,
              imgUrl: res.coverMobileUrl || res.coverUrl,
            });

            if (theme) {
              form.setFieldValue('theme', (theme2 || mediaCategory) ? [theme, theme2 ?? mediaCategory] : [theme!])
            }
            if (themeA) {
              form.setFieldValue('themeA', ((theme2 || mediaCategory)) ? [themeA, theme2 ?? mediaCategory] : [themeA!])
            }

            setFormValues({
              title,
              categoryIds: res.categoryList?.map((item) => {
                return item.id;
              }) || [],
              mediaCategory: [mediaCategory],
            })

            form.setFieldsValue({
              title: title, //标题
              // theme:(theme2|| mediaCategory) ? [theme, theme2??mediaCategory] : [theme!],
              // themeA:((theme2|| mediaCategory)&&themeA) ? [themeA, theme2??mediaCategory] : [themeA!],
              mediaCategory: [mediaCategory],
              team: [team],
              content: {
                html: res.contestWork.content,
                media: res.mediaList?.map((item) => item.id),
              },
              detailSupplementImage: getItem(detailSupplementImage),
              draftImage: getItem(draftImage),
              effectImage: getItem(effectImage),
              effectVideo: getItem(effectVideo),
              processImage: getItem(processImage),
              textureImage: getItem(textureImage),
              threeDImage: getItem(threeDImage),
              moreDetail: getItem(moreDetail),
              description: description, //描述
              category: categoryList && [
                categoryList[categoryList.length - 1].id,
              ],
              categoryIds: res.categoryList?.map((item) => {
                return item.id;
              }) || [],
              categoryId:
                categoryList && categoryList[categoryList.length - 1].id, // 类目ID
              softwareList: softwareList?.map((item) => item.name),
              hardwareList: hardwareList?.map((item) => item.name), // 软硬件标签
              tagList: res.tagList?.map((item) => item.name),
            });
            // @ts-ignore
            let arr = form.getFieldValue(tempCate);
            arr?.push(categoryList[categoryList.length - 1].id);

            form.setFieldValue(tempCate, arr);
            let chooseTagList = res.tagList?.map((item) => item.name);
            if (chooseTagList.length > 0 && chooseTagList.includes("Style3D")) {
              setStyle3DSpecialAward(1);
            }
          });
        }
      });

    }
  }, [id, workId]);

  useEffect(() => {
    if (!initTheme) {
      return
    }
    if (initTheme) {
      const newTheme = JSON.parse(JSON.stringify(initTheme));
      if (!(newTheme.constructor === Object) && newTheme.length > 1) {
        form.setFieldValue("mediaCategory", [initTheme[1]])
      }
    }
  }, [initTheme])

  useEffect(() => {
    if (!mediaCategory) {
      return
    }
    let obj = mediaCategoryOptions?.find(
      (category) => Number(category.value) === mediaCategory[0]
    );
    if (!!obj) {
      if (Number(obj.value) === 10) {
        setIsGameDesigner(Number(obj.value) === 10)
        const des = `输入你的邀约需求：例如
我们正在组队参加2025CUSGA，现招募：游戏美术：负责角色、场景、UI设计，熟练使用PS、Blender等工具。
游戏编程：熟悉Unity/Unreal，负责游戏逻辑与开发。
参赛地点：上海
自由创作空间、团队合作与成长机会、比赛荣誉与行业曝光
一起打造独特游戏，留下我们的名字！`
        setPlaceholder(des)
        setIsSelectRecruit(true)
        // 选中游戏策划时内容分类默认选中独立游戏
        if (!!copyDetail && copyDetail.categoryList.length) {
          copyDetail.categoryList = []
          setCopyDetail(copyDetail)
          return
        }
        if (!categoryList) {
          return
        }
        const game = categoryList.find(item => item.code === 'youxi')
        if (!!game) {
          const dlyx = game.children.find(item => item.code === 'dlyx')
          if (!!dlyx) {
            form.setFieldValue("categoryIds", [game.id, dlyx && dlyx.id])
          }
        }

      } else {
        setIsGameDesigner(false)
        setIsSelectRecruit(false)
        setPlaceholder("在这里介绍你的作品，分享你的故事")
      }
    }
  }, [mediaCategory, initTitle, initDescription, mediaCategoryOptions, categoryList, copyDetail]);

  const getComponent = (item: SignupField) => {

    if (
      (item.code === "theme" ||
        item.code === "themeA" || item.code === "ly3d" ||
        item.code === "xrtz") && !workId
    ) {
      return;
    }

    if (
      (item.code === "ly3d" ||
        item.code === "xrtz") && workId
    ) {
      return;
    }



    if (item.code === "category") {
      return <Form.Item
        label={item.name}
        name="categoryIds"
        rules={[{ required: true, message: "类目为必填项" }]}
      >
        <SecondSelect categoryList={categoryList}></SecondSelect>
      </Form.Item>
    }


    if (
      //  ( workId && item.code === "theme" && detail?.contestWork.theme )||
      //  ( workId && item.code === "themeA" && detail?.contestWork.themeA )||
      item.code === "team" ||
      item.code === "mediaCategory"
    ) {

      if (item.hasCategory) {
        return (
          <Form.Item
            key={item.code}
            label={item.name}
            name={item.code}
            rules={[{ required: !!item.isRequired }]}
          >
            <CategorySecondSelect data={item.options}></CategorySecondSelect>
          </Form.Item>
        );
      } else if (item.hasSecondOption) {
        return (
          <Form.Item
            key={item.code}
            label={item.name}
            name={item.code}
            rules={[{ required: !!item.isRequired }]}
          >
            <SubChildrenSelect
              data={item.options}
              multiple={false}
            ></SubChildrenSelect>
          </Form.Item>
        );
      } else if (item.hasMediaCategory) {
        return (
          <Form.Item
            key={item.code}
            label={item.name}
            name={item.code}
            rules={[{ required: !!item.isRequired }]}
          >
            <MediaCategorySecondSelect
              data={item.options}
            ></MediaCategorySecondSelect>
          </Form.Item>
        );
      } else {
        return (
          <Form.Item
            key={item.code}
            label={item.name}
            name={item.code}
            rules={[{ required: !!item.isRequired }]}
          >
            <SingleSelect data={item.options} multiple={false}></SingleSelect>
          </Form.Item>
        );
      }
    } else {
      const userInfo = JSON.parse(localStorage.getItem("user_info")!);
      if (item.code === "mobile") {
        form.setFieldValue(item.code, userInfo.mobile);
      }
      if (item.code === "nickname") {
        form.setFieldValue(item.code, userInfo.username);
      }
      if (item.code === "theme" ||
        item.code === "themeA" ||
        item.code === "creativeLab") {
        return
      }
      return (
        <Form.Item
          key={item.code}
          label={item.name}
          name={item.code}
          rules={[{ required: !!item.isRequired }]}
        >
          <Input placeholder={`请填写您的${item.name}`}></Input>
        </Form.Item>
      );
    }
  };

  const generateImage = (text: string) => {
    const canvas = document.createElement("canvas");

    const content = canvas.getContext("2d");

    var img = new Image();

    img.src =
      "https://cdn-prd.ggac.com/ggac/assets/image/contest/ycwl2/ycwl2-work-cover.png";
    img.setAttribute("crossOrigin", "Anonymous");
    img.onload = function () {
      let width = img.width;
      let height = img.height;
      canvas.setAttribute("width", img.width + "px");
      canvas.setAttribute("height", img.height + "px");
      // 将图片添加到canvas
      content!.drawImage(img, 0, 0, img.width, img.height);

      // 设置字体
      // canvas.width = width;
      // canvas.height = height;

      const context = canvas.getContext("2d");

      // Draw the white square background
      // context!.fillStyle = 'white';
      // context!.fillRect(0, 0, width, height);

      // Set the text style
      context!.fillStyle = "white";
      context!.font = "bold 30px Arial"; // Adjust the font size and style as needed

      // Center the text
      context!.textAlign = "center";
      context!.textBaseline = "middle";

      // Wrap the text
      let words: string[] = [];
      let index = 0;
      let str = "";
      while (index < text.length) {
        // debugger
        str += text[index];
        if (context!.measureText(str)!.width >= width - 100) {
          words.push(str);
          str = "";
        }
        index++;
      }
      if (index >= text.length) {
        words.push(str);
      }

      const lineHeight = 35; // Adjust the line height as needed
      const lines = [];
      let currentLine = words[0];

      for (let i = 1; i < words.length; i++) {
        const word = words[i];
        const testLine = currentLine + " " + word;
        const metrics = context!.measureText(testLine);
        const lineWidth = metrics.width;

        if (lineWidth > width) {
          lines.push(currentLine);
          currentLine = word;
        } else {
          currentLine = testLine;
        }
      }
      lines.push(currentLine);

      // Draw the wrapped text
      const textY = (height - lines.length * lineHeight) / 2;
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        const textX = width / 2;
        const textYPosition = textY + i * lineHeight;
        context!.fillText(line, textX, textYPosition);
      }

      canvas.toBlob((blob) => {
        let file = new File([blob!], "image.jpg", { type: "image/jpeg" });
        upload({ imgUrl: "", file: file });
      });
    };
  };

  const onClickAutoCover = () => {
    if (!form.getFieldValue("title")) {
      message.warning("请先输入标题");
      return;
    }

    generateImage(form.getFieldValue("title"));
  };

  const getMediaComponent = (code: string) => {
    let item = mediaFieldList.find((element) => element.code === code);
    // console.log(item,'-------/////---',isAni);
    if (item) {
      if (item.name.includes("视频") && isAni === 1) {
        // alert("视频")
        return (
          <Form.Item
            key={item.code}
            label={item.name}
            name={item.code}
            required={item.minCount > 0}
            rules={[
              {
                validator: (rules, value, callback) => {
                  // if (!value) {
                  // callback()
                  // }
                  if (
                    (Array.isArray(value) && value.length >= item!.minCount) ||
                    (!Array.isArray(value) && item!.minCount === 0)
                  ) {
                    console.log(value);
                    callback();
                  } else {
                    callback(`至少上传：${item!.minCount} 个 ${item!.name}`);
                  }
                },
              },
            ]}
          >
            <SubVideoWall maxLength={20} mark={item.code} />
          </Form.Item>
        );
      } else if (code === "moreDetail") {
        // alert("moreDetail")
        return (
          <Form.Item
            key={item.code}
            label={item.name}
            name={item.code}
          // required={item.minCount > 0}
          // rules={[{ required: true, message: "作品媒体为必填项" }]}
          >
            <ComboUpload mark={'moreDetail'}></ComboUpload>
          </Form.Item>
        );
      } else if (!item.name.includes("视频") && isAni !== 1) {
        // alert("other")
        return (
          <Form.Item
            key={item.code}
            label={item.name}
            name={item.code}
            required={item.minCount > 0}
            rules={[
              {
                validator: (rules, value, callback) => {
                  if (
                    (Array.isArray(value) && value.length >= item!.minCount) ||
                    (!Array.isArray(value) && item!.minCount === 0)
                  ) {
                    console.log(value);
                    callback();
                  } else {
                    callback(`至少上传：${item!.minCount} 张 ${item!.name}`);
                  }
                },
              },
            ]}
          >
            <SubPicturesWall maxLength={20} mark={item.code} />
          </Form.Item>
        );
      }
    }
  };


  const getMediaCategoryOptions = () => {
    apiGetDictList({ type: "mediaCategory" }).then((res) => {
      const options = res.map((item) => {
        return { value: String(item.code), label: item.name };
      });
      setMediaCategoryOptions(options);
    });
  };



  const onClickSave = (submit: number, isShowModal?: boolean, isPre?: true) => {
    if (!coverUrlFile?.imgUrl) {
      message.warning("请上传封面！");
      return;
    }
    if (loading || coverUrlFile?.imgUrl.includes("data:image")) {
      message.error("有文件正在上传中,请稍后重试！");
      return;
    }
    if (workId) {
      form.validateFields().then(async (values) => {
        if (contestDetail?.code === 'czwj2' && submit === 2 && isShowModal) {
          setIsShowCzwjModal(true);
          return;
        }
        let {
          title,
          tagList,
          description,
          hardwareList,
          softwareList,
          detailSupplementImage,
          draftImage,
          content,
          effectImage,
          effectVideo,
          processImage,
          textureImage,
          threeDImage,
          theme,
          themeA,
          team,
          mediaCategory,
          category,
          moreDetail,
          creativeLab,
          categoryIds
        } = values;

        let theme2;
        let val = values[(cate as "theme") || "team" || "mediaCategory"];
        let categoryId = val && val[1];
        if (theme) {
          const newTheme = JSON.parse(JSON.stringify(theme));
          if (newTheme.constructor === Object) {
            theme = newTheme?.label;
            theme2 = newTheme?.children[0];
          } else {
            theme = newTheme;
            //let val = values[(cate as "theme") || "team" || "mediaCategory"];
            // categoryId = val && val[1];
            mediaCategory = [theme[1]]
          }
        }
        if (themeA) {
          const newTheme = JSON.parse(JSON.stringify(themeA));
          if (newTheme.constructor === Object) {
            themeA = newTheme?.label;
            theme2 = newTheme?.children[0];
          } else {
            themeA = newTheme;

            // let val = values[(cate as "themeA") || "team" || "mediaCategory"];
            // categoryId = val && val[1];
            mediaCategory = [themeA[1]]
          }
        }
        let params: any = {
          draftId: +workId!,
          theme: theme && theme[0],
          themeA: themeA && themeA[0],
          team: team && team[0],
          mediaCategory: mediaCategory && mediaCategory[0],
          contestId: Number(id),
          coverUrl: coverUrlFile?.imgUrl!,
          coverMobileUrl: coverMobileUrlFile?.imgUrl! || coverUrlFile?.imgUrl!,
          content: content?.html || "",
          tagList: tagList,
          softwareList: softwareList,
          description: description, //描述
          title: title, //标题
          categoryId: categoryIds && categoryIds[1], // 类目ID
          hardwareList: hardwareList, // 软硬件标签
          detailSupplementImage: detailSupplementImage?.map((item) => item.id),
          draftImage: draftImage?.map((item) => item.id),
          effectImage: effectImage?.map((item) => item.id),
          effectVideo: effectVideo?.map((item) => item.id),
          processImage: processImage?.map((item) => item.id),
          textureImage: textureImage?.map((item) => item.id),
          threeDImage: threeDImage?.map((item) => item.id),
          moreDetail: moreDetail?.map((item) => item.id),
          publishType: !!isPre ? 1 : submit,
          creators: creatorList,
          creativeLab: creativeLab && creativeLab[0],
          isRecruit: !isGameDesigner ? 0 : isSelectRecruit ? 1 : 0
        };

        if (theme2) {
          params.theme2 = theme2;
        }

        if (submit !== 0) {
          params.submit = 1;
        }

        apiUpdateSubWork(params).then((res) => {
          if (!!isPre) {
            window.open(`/work/preview/${workId}`);
            return;
          }
          message.success("编辑成功！");
          navigate("/user-center/home/work/draft/list");
        });

        // navigate('/user-creator-center/work/draft/list')
        // message.success('编辑草稿成功！')
      });
    } else {
      form.validateFields().then(async (values) => {
        if (contestDetail?.code === 'czwj2' && submit === 2 && isShowModal) {
          setIsShowCzwjModal(true);
          return;
        }
        let {
          title,
          tagList,
          description,
          hardwareList,
          softwareList,
          detailSupplementImage,
          draftImage,
          effectImage,
          effectVideo,
          processImage,
          textureImage,
          threeDImage,
          theme,
          themeA,
          team,
          mediaCategory,
          category,
          content,
          moreDetail,
          creativeLab,
          categoryIds
        } = values;

        //自定义二级
        let theme2;
        // let val = values[cate as 'theme' || 'team' || 'mediaCategory']
        let val = values[(cate as "team") || "mediaCategory"];
        let categoryId;
        categoryId = val && val[1];

        if (theme) {
          const newTheme = JSON.parse(JSON.stringify(theme));
          if (newTheme.constructor === Object) {
            theme = newTheme?.label;
            theme2 = newTheme?.children[0];
          } else {
            theme = newTheme;
            mediaCategory = mediaCategory || [theme[1]]

            // let val = values[(cate as "theme") || "team" || "mediaCategory"];
            // categoryId = val && val[1];

          }
        }

        if (themeA) {
          const newTheme = JSON.parse(JSON.stringify(themeA));
          if (newTheme.constructor === Object) {
            themeA = newTheme?.label;
            theme2 = newTheme?.children[0];
          } else {
            themeA = newTheme;

            // let val = values[(cate as "themeA") || "team" || "mediaCategory"];
            // categoryId = val && val[1];

            // mediaCategory = [themeA[1]]
            mediaCategory = mediaCategory || [theme[1]]
          }
        }
        let creativeTheme
        if (creativeLab) {

          mediaCategory = [creativeLab[1]]
          creativeTheme = creativeLab[0]
        }

        let params: any = {
          theme: theme && theme[0] || creativeTheme,
          themeA: themeA && themeA[0],
          team: team && team[0],
          mediaCategory: mediaCategory && mediaCategory[0],
          contestId: Number(id),
          coverUrl: coverUrlFile?.imgUrl!,
          coverMobileUrl: coverMobileUrlFile?.imgUrl! || coverUrlFile?.imgUrl!,
          tagList: tagList,
          softwareList: softwareList,
          description: description, //描述
          title: title, //标题
          content: content?.html,
          categoryId: categoryIds && categoryIds[1], // 类目IDWE
          hardwareList: hardwareList, //W 软硬件标签
          detailSupplementImage: detailSupplementImage?.map((item) => item.id),
          draftImage: draftImage?.map((item) => item.id),
          effectImage: effectImage?.map((item) => item.id),
          effectVideo: effectVideo?.map((item) => item.id),
          processImage: processImage?.map((item) => item.id),
          textureImage: textureImage?.map((item) => item.id),
          threeDImage: threeDImage?.map((item) => item.id),
          moreDetail: moreDetail?.map((item) => item.id),
          publishType: !!isPre ? 1 : submit,
          creators: creatorList,
          creativeLab: creativeLab && creativeLab[0],
          isRecruit: !isGameDesigner ? 0 : isSelectRecruit ? 1 : 0
        };
        if (theme2) {
          params.theme2 = theme2;
        }

        if (submit !== 0) {
          params.submit = 1;
        }

        apiCreateSubWork(params).then((res) => {
          if (!!isPre) {
            navigate(`/g-contest/upload/${id}?workId=${res.id}`);
            window.open(`/work/preview/${res.id}`);
            return;
          }
          message.success("创建成功");
          //navigate("/user-center/home/work/draft/list");
          navigate(`/work/promote-to-sell/${res.id}?isContest=1`);
        });

        // navigate('/user-creator-center/work/draft/list')
        // message.success('编辑草稿成功！')
      });
    }
  };

  const upload = async (params: { imgUrl: string; file: File | undefined }) => {
    if (params.file) {
      setLoading(true);
      let urlKey = getFileName(CosDirectoryType.workCover, params.file.name);
      let res = await getFileUrlByCosUpload(params.file!, urlKey);
      setCoverUrlFile({
        file: undefined,
        imgUrl: res.url,
      });
      setLoading(false);
    }
  };

  const uploadMobileImage = async (params: { imgUrl: string; file: File | undefined }) => {
    if (params.file) {
      setLoading(true);
      let urlKey = getFileName(CosDirectoryType.workMobileCover, params.file.name);
      let res = await getFileUrlByCosUpload(params.file!, urlKey);
      setCoverMobileUrlFile({
        file: undefined,
        imgUrl: res.url,
      });
      setLoading(false);
    }
  };


  useEffect(() => {

    const org = searchParams.get("org");
    if (org) {
      form.setFieldValue("tagList", [org])
    }
  }, [searchParams]);

  const onChangeIsPcCover = (e: any) => {
    setIsSelectPcCover(e.target.value)
  }

  return (
    <SubUpdateStyle>
      <div className="main-container">
        <div className="left">

          <Form
            name="basic"
            layout="vertical"
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 24 }}
            initialValues={{ remember: true, softwareList: softwareList }}
            autoComplete="off"
            requiredMark={true}
            className="form-area"
            form={form}
            onValuesChange={(changedValues, allValues) => setFormValues(allValues)}
          >
            <div className="form-section">
              {
                !isGameDesigner && <div style={{ paddingBottom: 10 }}>{contestDetail?.name}</div>
              }
              {fieldList && (
                <GrandContestSelect fieldList={fieldList} isGameDesigner={isGameDesigner} detail={detail}></GrandContestSelect>
              )}

              {!!style3DSpecialAwardFlag && <Form.Item
                name="style3DSpecialAward"
                className="checkout"
              >
                <Checkbox checked={style3DSpecialAward == 1 ? true : false} onChange={isChooseStyle3DSpecialAward}>参与Style3D 特别奖</Checkbox>
              </Form.Item>}
              <Form.Item
                name="title"
                rules={[{ required: true, message: "" }]}
              >
                <Input placeholder="*请输入标题" count={{ show: true, max: 20 }} maxLength={20} />
              </Form.Item>
              <Form.Item label="" name="description">
                <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} count={{ show: true, max: 1000 }} placeholder={placeholder} maxLength={1000} />
              </Form.Item>
              {fieldList?.map((item) => getComponent(item))}

              {mediaNameList?.map((item) => getMediaComponent(item))}

              {chooseArticle && (
                <Form.Item
                  label="正文"
                  name="content"
                  rules={[{ required: true, message: "内容为必填项" }]}
                >
                  {
                    <TinyEditor
                      cosImagePath={CosDirectoryType.workImage}
                      cosVideoPath={CosDirectoryType.workVideo}
                    ></TinyEditor>
                  }
                </Form.Item>
              )}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.categoryIds !== currentValues.categoryIds ||
                  prevValues.category !== currentValues.category
                }
              >
                {({ getFieldValue }) =>
                  <div>
                    <Form.Item label="" name="tagList">
                      <TagSelect
                        dataTable="work"
                        categoryId={
                          (form.getFieldValue(cate) &&
                            form.getFieldValue(cate)![1]) ||
                          (form.getFieldValue("category") && form.getFieldValue("category")[0]) || 0
                        }
                        maxCount={5}
                      ></TagSelect>
                    </Form.Item>
                  </div>
                }
              </Form.Item>
              <Form.Item>
                <div className="header-wrap">
                  <div className="header">
                    <span>共同创作</span>{" "}
                    <Tooltip title="添加除本人外的共同创作人或导师，最多可添加5人">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </div>
                  <Collabration
                    onListChange={(list) => setCreatorList(list)}
                    creators={detail?.creators}
                  ></Collabration>
                  {
                    !!isGameDesigner && <div className="recruit">
                      <Button type="primary">
                        <Checkbox checked={isSelectRecruit} onChange={(e) => setIsSelectRecruit(e.target.checked)}>招募共创成员</Checkbox>
                        <Tooltip title="招募申请可以在个人中心-我的邀约中查看">
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </Button>
                    </div>
                  }
                </div>
              </Form.Item>

              {!chooseArticle && (
                <>
                  <Form.Item label="软件工具" name="softwareList" layout="horizontal">
                    <CreationWare type={1} selectMode="multiple" ></CreationWare>
                  </Form.Item>
                  <Form.Item label="硬件工具" name="hardwareList" layout="horizontal">
                    <CreationWare type={2} selectMode="multiple"></CreationWare>
                  </Form.Item>
                </>
              )}
            </div>
          </Form>
        </div>
        <div className="fixed">
          <Space className="block-btn">
            <Button onClick={() => onClickSave(0, false, true)} block>
              预览
            </Button>
            {
              !isGameDesigner && <>
                <Button onClick={() => onClickSave(1)} block>
                  阶段发布
                </Button>
                <Button
                  type="primary"
                  className="primary"
                  onClick={() => onClickSave(2, true)}
                  block
                >
                  最终提交
                </Button>
              </>
            }

            {
              !!isGameDesigner && <Button type="primary" className="primary" onClick={() => onClickSave(1)} block>
                立即发布
              </Button>
            }
          </Space>
        </div>
        <div className="right">
          <div className="card">
            <div className="header">
              <div>
                <span style={{ color: "red", marginRight: "5px" }}>*</span>
                封面预览
              </div>
            </div>
            <div className="image-crop-wrapper">
              {/* <Spin spinning={loading}>
                <ImageCrop
                  value={coverUrlFile}
                  onChange={(params: {
                    imgUrl: string;
                    file: File | undefined;
                  }) => {
                    setCoverUrlFile({ ...params });
                  }}
                  uploadImage={upload}
                />
              </Spin> */}
              {
                isSelectPcCover && <Spin spinning={loading}>
                  <ImagePreviewPc value={coverUrlFile}
                    onChange={(params: {
                      imgUrl: string;
                      file: File | undefined;
                    }) => {
                      setCoverUrlFile({ ...params });
                    }}
                    mediaCategoryOptions={mediaCategoryOptions || []}
                    formValues={{ title: formValues?.title, categoryIds: formValues?.categoryIds, mediaCategory: formValues && String(formValues.mediaCategory && formValues.mediaCategory[0]) }}
                    categoryList={categoryList}
                    uploadImage={upload} />
                </Spin>
              }
              {
                !isSelectPcCover && <Spin spinning={loading}>
                  <ImagePreviewMobile
                    value={coverMobileUrlFile}
                    onChange={(params: {
                      imgUrl: string;
                      file: File | undefined;
                    }) => {
                      setCoverMobileUrlFile({ ...params });
                    }}
                    uploadImage={uploadMobileImage}
                    aspect={300 / 400}
                  />
                </Spin>
              }
            </div>
            <div className="tab-wrap">
              <Radio.Group
                onChange={onChangeIsPcCover}
                value={isSelectPcCover}
                optionType="button"
                buttonStyle="solid"
              >
                <Radio value={true}>电脑端</Radio>
                <Radio value={false}>移动端</Radio>
              </Radio.Group>
            </div>
            {/* <canvas id="canvas" ></canvas> */}
            {autoImage && (
              <div className="autocover-wrapper">
                <div className="autoCover" onClick={onClickAutoCover}>
                  一键生成封面
                </div>
                <Tooltip title="如无默认图片，系统将默认以作品标题生成封面">
                  <RiQuestionLine></RiQuestionLine>
                  {/* <RiQuestionAnswerLine>如无默认图片，系统将默认以作品标题生成封面</RiQuestionAnswerLine> */}
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </div>
      <CzwjModal
        isShow={isShowCzwjModal}
        finallysubmit={onClickSave}
        close={() => {
          setIsShowCzwjModal(false);
        }}
      />
    </SubUpdateStyle>
  );
};
