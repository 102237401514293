import { Modal, message } from "antd";
import { ShareStyle } from "./style";
import { RiLinkM, RiQqFill, RiStarSmileFill, RiWeiboFill } from "react-icons/ri";
import { share, shareInfoType } from "utils/functions/common";
import copy from "copy-to-clipboard";
import { ReactNode, useEffect } from "react";


type Props = { isShow?: boolean; onClose: () => void, rightNode?: ReactNode, shareType: string, info: shareInfoType, shareUrl?: string, shareImageInfo?: { qrCode: string, shareImageUrl: string } };
function GlobalShare({ isShow = false, onClose, shareType, rightNode, info, shareImageInfo, shareUrl }: Props) {

  const toShare = (type: string) => {
    if (type === 'copy') {
      copy(shareUrl || document.location.href)
      message.success("复制成功")
      return
    }
    share(type, "workDetial", { ...info }, shareUrl)
  }

  return (
    <Modal
      className="modal"
      onCancel={onClose}
      footer={<></>}
      title="分享"
      destroyOnClose={true}
      centered={true}
      open={isShow}
      width={720}
    >
      <ShareStyle>
        <div className="container_">
          <div className="left">
            <img src={shareImageInfo?.shareImageUrl} alt="" className="share-img" />
            <div className="product-tip">
              <div className="flex">
                <div className="bold">重要提醒：</div>
                <div className="red">非官方验证的站外交易都是诈骗!</div>
              </div>
              <div className="flex">
                <div className="bold">遇可疑行为→速联官方或110!</div>
                <div>官方客服微信号:GGAC2025</div>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="up">
              <span>点击鼠标右键-图片另存为 保存图片即可分享</span>
            </div>
            {
              rightNode && <div className="right-node">
                {rightNode}
              </div>
            }
            <div className="wrap">
              {
                shareImageInfo?.qrCode && <div className="qr-code">
                  <img src={shareImageInfo?.qrCode} alt="" />
                  <span>分享给微信好友</span>
                </div>
              }
              <div className="down">
                <div className="item" onClick={() => toShare('copy')}>
                  <div className="icon">
                    <RiLinkM></RiLinkM>
                  </div>
                  <span>复制链接</span>
                </div>
                <div className="item" onClick={() => toShare('sina')}>
                  <div className="icon" style={{ background: 'rgb(194, 53, 47)' }}>
                    <RiWeiboFill></RiWeiboFill>
                  </div>
                  <span>新浪微博</span>
                </div>
                <div className="item" onClick={() => toShare('qq')}>
                  <div className="icon" style={{ background: 'rgb(110, 179, 234)' }}>
                    <RiQqFill></RiQqFill>
                  </div>
                  <span>QQ</span>
                </div>
                <div className="item">
                  <div className="icon" onClick={() => toShare('qzone')} style={{ background: 'rgb(241, 208, 96)' }}>
                    <RiStarSmileFill></RiStarSmileFill>
                  </div>
                  <span>QQ空间</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ShareStyle>
    </Modal>
  );
}

export default GlobalShare;
