import { ContestDetailResponse } from "types/contest"
import { Regtulation2024AboutStyle } from "./style"
import { useOutletContext } from "react-router"

const Regtulation2024About = () => {
    const { detail } = useOutletContext<{ detail: ContestDetailResponse }>()
    const contestJSON = JSON.parse(detail?.pageCfg || "{}")
    const organ: {
        title: string,
        direc: { title: string, list: { name: string, job: string }[] },
        deputyDirec: { title: string, list: { name: string, job: string }[] },
        general: { title: string, list: { name: string, job: string }[] },
        deputyGeneral: { title: string, list: { name: string, job: string }[] },
    } = contestJSON.organ || {}
    const jury2024: {
        title: string,
        chairman: { title: string, list: { name: string, job: string }[] },
        secondD: { title: string, list: string[] },
        threeD: { title: string, list: string[] },
        ani: { title: string, list: string[] },
    } = contestJSON.jury2024 || {}
    const member2022: { title: string, list: { name: string, job: string }[] } = contestJSON.member2022 || {}
    const board2022: {
        title: string,
        chairman: { title: string, list: { name: string, job: string }[] },
        matchBoard: { title: string, list: { name: string, job: string }[] },
    } = contestJSON.board2022 || []
    const cooperCompany2022: { title: string, allList: { title: string, list: string[] }[] } = contestJSON.cooperCompany2022 || {}
    const about1: string[] = contestJSON.about1 || []
    const about2: { title: string, desc: string[], logo: string } = contestJSON.about2 || {}

    const lang = localStorage.getItem('lang')

    return <Regtulation2024AboutStyle>
        {
            detail && <div className={`about_wrapper ${lang === 'en_GB' ? 'about_wrapper_en' : ''}`}>
                <div className="about_match ggac_container">
                    <img src="https://cdn.ggac.com/images/result_2020/about_badge.png" alt="" className="about_match_bg" />
                    <div className="title" lang="t_lang">关于大赛</div>
                    {
                        lang === 'zh_CN' && <div className="text">
                            {
                                about1.map(item => {
                                    return <div className="cut" key={item} lang="t_lang">{item}</div>
                                })
                            }
                        </div>
                    }
                    {
                        lang === 'en_GB' && <div className="text">
                            <div className="cut">The GGAC Digital Art Competition (GGAC Awards) was founded in 2018 and has been hosted by GGAC.com, a CG art creation and sharing platform. As one of the largest, most influential, and most prestigious CG art competitions in the world, it is widely regarded as "the Oscars of the Digital Art World."</div>
                            <div className="cut">Each year, the competition invites hundreds of CG artists and esteemed industry experts from around the world to form a jury panel. It attracts participants from nearly 100 countries and regions, thousands of game, animation, and film companies, and tens of thousands of professional artists and students from top art institutions, all competing for the highest honors in CG art.</div>
                            <div className="cut">With a vision to discover and nurture outstanding CG art talent, foster global cultural exchange in CG art, and enhance the international presence of Chinese CG art, GGAC has long been dedicated to integrating traditional aesthetics with modern CG creation. It strives to showcase and connect Chinese art and culture on the world stage.</div>
                            <div className="cut">GGAC is committed to providing an authoritative international platform and expansive creative space. Together, we build a world-renowned, spectacular visual art extravaganza!</div>
                            <div className="cut">The 6th GGAC Digital Art Competition, hosted by GGAC.com, features three main categories: GGAC Creation Award，2.GGAC Academy Award, 3.Creative Laboratory. Each category is further divided into multiple groups. The competition adheres to the principles of fairness, impartiality, and professionalism, and invites renowned international judges to evaluate submissions from around the globe.</div>
                        </div>
                    }
                    <div className="about_match_leader">
                        <img src={about2.logo} alt="" />
                        <p lang="t_lang"> {about2.title}</p>
                    </div>
                    {
                        lang === 'zh_CN' && <div className="text">
                            {
                                about2.desc.map(item => {
                                    return <div className="cut" key={item} lang="t_lang">{item}</div>
                                })
                            }
                        </div>
                    }
                    {
                        lang === 'en_GB' && <div className="text">
                            <div className="cut">Mr. Lin Yongmin is the founder of the GGAC Digital Art Competition, Deputy Director of the Digital Art Professional Committee of the China Film Art Direction Academy, CEO of Shanghai Dianqing Technology, and founder of Art Box ABOX. He embarked on his journey into the "ninth art" during the nascent stages of China's gaming industry. With over a decade of experience, he served as one of China's first game producers, established the country's initial game art production service teams, and launched specialized professional game art platforms. Throughout his career, he has been dedicated to advancing China's digital creative industry.</div>
                        </div>
                    }
                </div>
                <div className="match_Judge">
                    <div className="title second-title" lang="t_lang"> 大赛组委</div>
                    <div className="match_judge_card ggac_container">
                        <div className="card_left">
                            <p className="card_title" lang="t_lang">{organ.title}</p>
                            <div className="top_cnt">
                                <div>
                                    <p className="blod" lang="t_lang">{organ.direc.title}</p>
                                    {
                                        organ.direc.list.map(item => {
                                            return <p key={item.name} ><span lang="t_lang">{item.name}</span> <span lang="t_lang">{item.job}</span></p>
                                        })
                                    }
                                </div>
                                <div className="top_cnt">
                                    <div>
                                        <p className="blod" lang="t_lang">{organ.deputyDirec.title}</p>
                                        {
                                            organ.deputyDirec.list.map(item => {
                                                return <p key={item.name} lang="t_lang"><span lang="t_lang">{item.name}</span> {item.job}</p>
                                            })
                                        }
                                        <p className="blod" lang="t_lang">{organ.general.title}</p>
                                        {
                                            organ.general.list.map(item => {
                                                return <p key={item.name} lang="t_lang"><span lang="t_lang">{item.name}</span> {item.job}</p>
                                            })
                                        }
                                        <p className="blod" lang="t_lang">{organ.deputyGeneral.title}</p>
                                        {
                                            organ.deputyGeneral.list.map(item => {
                                                return <p key={item.name} lang="t_lang"><span lang="t_lang">{item.name}</span> {item.job}</p>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card_right">
                            <div>
                                <p className="card_title" lang="t_lang">{jury2024.title}</p>
                                <p className="blod" lang="t_lang">{jury2024.chairman.title}</p>
                                {
                                    jury2024.chairman.list.map(item => {
                                        return <p key={item.job} lang="t_lang"><span lang="t_lang">{item.name}</span> {item.job}</p>
                                    })
                                }
                                <p className="blod" lang="t_lang">{jury2024.secondD.title}</p>
                                {
                                    jury2024.secondD.list.map(item => {
                                        return <p key={item} lang="t_lang"><span lang="t_lang">{item}</span></p>
                                    })
                                }
                            </div>
                            <div>
                                <p className="card_title" lang="t_lang">{board2022.title}</p>
                                <p className="blod" lang="t_lang">{board2022.chairman.title}</p>
                                {
                                    board2022.chairman.list.map(item => {
                                        return <p key={item.job} lang="t_lang"><span lang="t_lang">{item.name}</span> {item.job}</p>
                                    })
                                }
                                <p className="blod" lang="t_lang">{board2022.matchBoard.title}</p>
                                {
                                    board2022.matchBoard.list.map(item => {
                                        return <p key={item.name} lang="t_lang"><span lang="t_lang">{item.name}</span> {item.job}</p>
                                    })
                                }
                            </div>

                        </div>
                    </div>
                    <div className="judge_card ggac_container">
                        <p className="card_title" lang="t_lang">{member2022.title}</p>
                        <div className="top-cnt member" style={{ height: "1325px" }}>
                            {
                                member2022.list.map((item, index) => {
                                    return <p key={index} lang="t_lang">
                                        <span lang="t_lang">{item.name}</span>{item.job}
                                    </p>
                                })
                            }
                        </div>
                    </div>

                    <div className="work_together ggac_container">
                        {
                            lang === "zh_CN" && <p className="title">{cooperCompany2022.title}</p>
                        }
                        {
                            lang === "en_GB" && <p className="title">Partners</p>
                        }
                        <div className="work_list">
                            {
                                cooperCompany2022.allList.map((item, index) => {
                                    return <div className="work_list_item" key={index}>
                                        <p className="sub-title" lang="t_lang">{item.title}</p>
                                        {
                                            item.list.map((ele, i) => {
                                                return <div key={i} dangerouslySetInnerHTML={{ __html: ele }} lang="t_lang"></div>
                                            })
                                        }
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div >
            </div >
        }
    </Regtulation2024AboutStyle >
}

export default Regtulation2024About
