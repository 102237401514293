import styled from "styled-components";

export const SecondReplyStyle = styled.div`
    .input-wrap{
        width: 100%;
        margin-top: ${(props) => props.theme.space.margin}px;
        .left-wrap {
            display: flex;
            gap: 15px;
            align-items: center;
        }
        .btn-wrap {
            margin-top: ${(props) => props.theme.space.margin}px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .emoji{
                color: ${(props) => props.theme.colors.colorPrimary};
            }
        }
    }
    .operate {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .cover-wrap {
        display: flex;
        gap: 5px;
        .cover {
            width: 80px;
            aspect-ratio: 1;
            position: relative;
            cursor: pointer;
            background-size: cover;
            background-position: center;
            margin-top: 10px;
            &:hover {
                .mask-wrap {
                    display: block;
                } 
            }
            .mask-wrap {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.5);
                display: none;
                .mask {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    &-item {
                        display: flex;
                        gap: 5px;
                        color: #fff;
                        align-items: center;
                        font-size: 12px;
                        &:hover {
                            color: ${props => props.theme.colors.colorPrimary};
                        }
                    }
                }
            }
        }
    }
`