import styled from "styled-components";

export const SimsPopupSyle = styled.div`

    @font-face {
        font-family: 'YOUSHEhaoshenti';
        src: url('https://cdn-prd.ggac.com/ggac/font/YSHaoShenTi.ttf');
    }

    .close-wrap {
        position: absolute;
        right: 100px;
        top: 100px;
        img {
            cursor: pointer;
        }
    }

    
    .content-wrap {
        padding: 0 200px;
        .content {
            padding-right: 60px;
            position: relative;
            font-family: 'YOUSHEhaoshenti';
            height: 400px;
            overflow: auto;
            position: relative;
            .close {
                width: 30px;
                cursor: pointer;
            }

            ::-webkit-scrollbar {
                width: 6px; /* 设置滚动条的宽度 */
                background-color: transparent; /* 滚动条的背景色 */
            }
            ::-webkit-scrollbar-track {
              background: #7f412b; /* 轨道的背景色 */
              border-radius: 10px; /* 轨道的圆角 */
            }
            /* 自定义滚动条的滑块（thumb） */
            ::-webkit-scrollbar-thumb {
              background-color: #f16419; /* 滑块的背景色 */
              border-radius: 10px; /* 滑块的圆角 */
            }
        }
    }

    .first {
        margin-top: 10px !important;
    }

    .sub-title {
        display: flex;
        gap: 30px;
        font-size: 30px;
	    letter-spacing: 5px;
	    color: #f16419;
        align-items: center;
        justify-content: center;
        position: relative;
        margin: 30px 0;
        &::after {
            content: "";
            width: 256px;
            height: 4px;
            background: url("https://cdn-prd.ggac.com/ggac/submatch/sims/sims-part6-line.png") center center no-repeat;
            background-size: 100% 100%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -10px;
        }
        img {
            width: 19px;
            height: 18px;
        }
    }

    .tip {
        font-size: 20px;
	    line-height: 35px;
	    letter-spacing: 2px;
	    color: #f16418;
        text-align: center;
        margin-top: -20px;
    }

    .word {
        font-size: 20px;
        div {
	        line-height: 35px;
	        color: #643000;
            text-align: center;
        }
    }

    

    .btn {
        height: 70px;
        aspect-ratio: 310 / 70;
        background: url(https://cdn-prd.ggac.com/ggac/submatch/sims/sims-banner-tag1-active.png) center center / 100% 100% no-repeat;
        font-size: 34px;
        letter-spacing: 4px;
        color: #feeba8;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding-left: 105px;
        margin: 30px auto 0;
        font-family: 'REEJI';
        cursor: pointer;
    }

    .tip2 {
        font-size: 18px;
	    letter-spacing: 2px;
	    color: #b67436;
        text-align: center;
        margin-top: 15px;
    }
`