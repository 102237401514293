import { useState, useEffect } from 'react';
import { Select } from 'antd';
import { CategoryItem } from 'types/user/work';

export const SecondSelect = (props: { value?: number[], categoryList: CategoryItem[], type?: string, onChange?: (value: number[]) => void, isContest?: boolean }) => {
  const [firstData, setFirstData] = useState<CategoryItem[]>([])
  const [secondData, setSecondData] = useState<CategoryItem[]>([])

  const [first, setFirst] = useState<number>(0);
  const [second, setSecond] = useState<number>(0);

  useEffect(() => {
    if(props.categoryList.length === 0) {
      return
    }

    const res = props.categoryList
    setFirstData(res)
      if (props.value && props.value.length > 0) {
        setFirst(props.value[0])
        setSecond(props.value[1])

        res.forEach(item => {
          item.children?.forEach((childrenItem) => {
            if (+props.value![1] === childrenItem.id) {
              setSecondData(item.children)
              setSecond(childrenItem.id)
            }
          })
        })
      } else {
        setSecondData(res[0]?.children)
        setFirst(res[0]?.id)
        setSecond(res[0]?.children[0].id)
        props.onChange && props.onChange([res[0]?.id, res[0]?.children[0]?.id])
      }
  }, [props?.value, props.type, props.categoryList])



  const handleFirstChange = (value: number) => {
    setSecondData([])
    let index = firstData.findIndex((item) => item.id === value)
    let item = firstData[index].children
    setSecondData(item)
    setFirst(value)
    setSecond(item && item[0]?.id)
    props.onChange && props.onChange([value, item[0]?.id])
  };

  const handleSecondChange = (value: number) => {
    setSecond(value)
    props.onChange && props.onChange([first, value])
  };


  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
      <Select
        style={{ width: '48%' }}
        value={first}
        onChange={handleFirstChange}
        options={firstData && firstData.map((item) => ({ label: item.name, value: item.id }))}
      />

      <Select
        style={{ width: '48%' }}
        value={second}
        onChange={handleSecondChange}
        options={secondData?.map((item) => ({ label: item.name, value: item.id }))}
      />
    </div>
  );
};
