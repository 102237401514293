import { useNavigate, useOutletContext } from "react-router-dom"
import { ContestDetailResponse } from "types/contest"
import { hasToken } from "utils/functions/common"
import { loginPathWithRedirect } from "utils/functions/user"
import { PuzzleDetailStyle } from "./style"
const PuzzleDetail = () => {
    const { detail } = useOutletContext<{ detail: ContestDetailResponse }>()
    const contestJSON = JSON.parse(detail?.pageCfg || "{}")

    const dataList: {
        word: string,
        rule: { word: string, id: number, sub?: { name: string, id: number, sub: string[] }[] }[]
        rule2: { word: string, id: number, tip?: string, sub?: string[] }[]
        judge: { name: string, id: number, url: string, desc: string[] }[]
        standard: { name: string, id: number, word: string }[]
        rule3: string[]
    } = contestJSON?.detail



    const navigate = useNavigate()
    const token = hasToken()

    const toApply = () => {
        if (!token) {
            navigate(loginPathWithRedirect())
            return
        }
        if (detail?.signupId === 0) {
            navigate(`/g-contest/apply/${detail.id}`)
        } else if (detail?.canUpload > 0) {
            navigate(`/g-contest/upload/${detail.id}?from=2025cusga`)
        }
    }

    return <PuzzleDetailStyle>
        {
            !!detail && <div className="detail">
                <div className="border1">
                    <div className="word">{dataList.word}</div>
                </div>
                <div className="border2">
                    <img src="https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-border2.png" alt="" />
                    <img src="https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-icon3.png" className="icon" />
                </div>
                <div className="border8">
                    <img src="https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-border8.png" alt="" />
                    <img src="https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-icon7.png" alt="" className="icon" />
                    <img src="https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-icon4.png" alt="" className="icon2" />
                </div>
                <div className="border3">
                    <div className="border3-tip">（仅美术赛道） </div>
                    <img src="https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-icon5.png" className="icon" />
                    <div className="content">
                        {
                            dataList?.rule.map((item, index) => {
                                return <div className="content-item" key={item.id}>
                                    <div className="num">{index + 1}. </div>
                                    <div className="right">
                                        <div className="word">{item.word}</div>
                                        {
                                            !!item.sub && item.sub.map(sub => {
                                                return <div key={sub.id} className="sub">
                                                    <div className="sub-title">{sub.name}</div>
                                                    {
                                                        sub.sub.map(word => {
                                                            return <div className="word">- {word}</div>
                                                        })
                                                    }
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>

                <div className="border5">
                    <img src="https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-icon9.png" className="icon" />
                    <div className="judge">
                        {
                            dataList.judge.map(item => {
                                return <div className="judge-item" key={item.id}>
                                    <div className="img-wrap">
                                        <img src={item.url} alt="" />
                                    </div>
                                    <div className="desc">
                                        {
                                            item.desc.map(desc => {
                                                return <div key={desc}>{desc}</div>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className="border6">
                    <img src="https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-icon11.png" className="icon" />
                    <img src="https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-icon12.png" className="icon2" />
                    <img src="https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-icon13.png" className="icon3" />
                    <div className="content">
                        {
                            dataList.standard.map(item => {
                                return <div className="content-item" key={item.id}>
                                    <div className="name">
                                        <svg width="100%" height="100%">
                                            <text
                                                x="50%" y="50%"
                                                className="text"
                                                dominant-baseline="middle"
                                            >
                                                {item.name}
                                            </text>
                                        </svg>
                                    </div>
                                    <div className="word">{item.word}</div>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className="border7">
                    <img src="https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-icon14.png" className="icon" />
                    <img src="https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-icon15.png" className="icon2" />
                    <div className="content">
                        {
                            dataList.rule3.map((item, index) => {
                                return <div className="content-item" key={item}>
                                    <div>{index + 1}.</div>
                                    <div>{item}</div>
                                </div>
                            })
                        }
                    </div>
                </div>

                <div className="border4">
                    <img src="https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-icon6.png" className="icon" />
                    <img src="https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-icon7.png" className="icon2" />
                    <div className="content">
                        {
                            dataList?.rule2.map(item => {
                                return <div className="content-item" key={item.id}>
                                    <div className="word">{item.word}</div>
                                    {
                                        item.tip && <div className="sub-title">{item.tip}</div>
                                    }
                                    {
                                        !!item.sub && <div className="sub">
                                            {
                                                item.sub.map((sub) => {
                                                    return <div className="sub-item" key={sub} dangerouslySetInnerHTML={{ __html: sub }} />
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            })
                        }

                        <div className="btns">
                            {
                                (!!detail.canSignup || !!detail.canUpload) && <div className="btn" onClick={toApply}>
                                    <svg width="100%" height="100%">
                                        <text
                                            x="50%" y="50%"
                                            className="text"
                                            dominant-baseline="middle"
                                        >
                                            Puzzle Pro组队招募
                                        </text>
                                    </svg>
                                </div>
                            }
                            <div className="btn btn2" onClick={() => window.open("https://www.ugdap.com/cusga")}>前往官网报名参赛{`>`}</div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </PuzzleDetailStyle>
}

export default PuzzleDetail