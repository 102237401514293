import { Button, Checkbox, Input, message, Image } from 'antd'
import { SecondReplyStyle } from './style'
import { useState, useCallback, } from 'react'
import { postCreateComment } from 'api/work/comment';
import { CommentListItem, CreateCommentRequest } from 'types/comment';
import { useParams } from 'react-router-dom';
import EmojiPicker from 'components/emoji-picker';
import { emojiParse, getFileName } from "utils/functions/common";
import CommentUpload from 'components/comment-upload';
import { getFileUrlByCosUpload } from 'config/cos';
import { CosDirectoryType } from 'types/enums/media-type';
import { RiDeleteBack2Fill, RiEyeFill } from 'react-icons/ri';


type SecondReplyType = {
    params: {
        visible: boolean,
        detail: CommentListItem
        sendSubComment: (val: CommentListItem) => void,
        close: () => void,
        type: string,
        pid: number,
        word?: string,
        dataId?: number,
        anonymity?: boolean
    }
}

const SecondReply = (props: SecondReplyType) => {
    const { params } = props
    const [subValue, setSubValue] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const { id, productId } = useParams<any>();
    const [isAnonymity, setIsAnonymity] = useState(false)
    const [uploadImageFile, setUploadImageFile] = useState<{
        file?: File;
        imgUrl?: string;
    }>();

    const [visible, setVisible] = useState(false); // 控制预览的可见性

    const [previewImageUrl, setPreviewImageUrl] = useState("")

    const [uploadImageList, setUploadImageList] = useState<string[]>([])

    const upload = async (params: { imgUrl: string; file: File | undefined }) => {
        if (uploadImageList.length === 3) {
            message.warning("最多可上传3张图片")
            return
        }
        if (params.file) {
            setLoading(true);
            let urlKey = getFileName(CosDirectoryType.commentImage, params.file.name);
            let res = await getFileUrlByCosUpload(params.file!, urlKey);
            setUploadImageFile({
                file: undefined,
                imgUrl: res.url,
            });
            uploadImageList.push(res.url)
            setUploadImageList([...uploadImageList])
            setLoading(false);
        }
    };
    const changeValue = useCallback((e: any) => {
        setSubValue(e.target.value)
    }, [])

    const appendEmoji = (item: { url: string, title: string }) => {
        setSubValue((res) => res + item.title)
    }

    const sendSub = () => {
        let content = JSON.parse(JSON.stringify(subValue)).trim()
        if (!content) {
            message.error(`${params.word ? params.word : '评论'}内容不能为空`)
            return
        }
        setLoading(true)

        if (uploadImageList.length > 0) {
            content = `${content} [img]${uploadImageList.join(",")}[/img]`
        }
        const req: CreateCommentRequest = {
            content: emojiParse(content),
            dataId: params.dataId || Number(id) || Number(productId),
            dataTable: params.type,
            pid: params.pid
        }
        if (params.detail.level === 2) {
            req.replyId = params.detail.id
        }
        if (isAnonymity) {
            req.isAnonymity = isAnonymity ? 1 : 0
        }
        postCreateComment(req).then((res) => {
            message.success('创建成功')
            params.sendSubComment(res)
            setIsAnonymity(false)
            params.close()
            setUploadImageFile({
                imgUrl: "",
                file: undefined
            })
        }).finally(() => {
            setSubValue('')
            setLoading(false)
        })
    }

    const onChangeCheck = (e: any) => {
        setIsAnonymity(e.target.checked)
    }

    const previewImage = (url: string) => {
        setVisible(true)
        setPreviewImageUrl(url)
    };

    const deleteImage = (index: number) => {
        uploadImageList.splice(index, 1)
        setUploadImageList([...uploadImageList])
    }

    return <SecondReplyStyle>
        {params.visible && <div className='input-wrap'>
            <Input.TextArea value={subValue} onChange={changeValue} placeholder={`回复${params?.detail.isAnonymity === 1 ? '匿名' : params?.detail.replyUserInfo?.username || params?.detail.userInfo.username}：`}></Input.TextArea>
            <div className="btn-wrap">
                <div className="left-wrap">
                    <div className="operate">
                        <EmojiPicker appendEmoji={appendEmoji} />
                        <CommentUpload uploadImage={upload} value={uploadImageFile} color="#FFC712" size={20} />
                    </div>
                    {
                        !!params.anonymity && <Checkbox checked={isAnonymity} onChange={onChangeCheck}>是否匿名</Checkbox>
                    }
                </div>
                <Button type='primary' onClick={() => sendSub()} className='btn' loading={loading}>{params.word ? params.word : "评论"}</Button>
            </div>
            {
                uploadImageList.length > 0 && <>
                    <div className="cover-wrap">
                        {
                            uploadImageList.map((item, index) => {
                                return <div className="cover" key={item} style={{ backgroundImage: `url(${item})` }}>
                                    <div className="mask-wrap">
                                        <div className="mask">
                                            <div className="mask-item" onClick={() => previewImage(item)}>
                                                <RiEyeFill />预览
                                            </div>
                                            <div className="mask-item" onClick={() => deleteImage(index)}>
                                                <RiDeleteBack2Fill />删除
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    {/* 使用 antd 的 Image 组件实现预览功能 */}
                    <Image
                        width={0} // 隐藏 Image 组件
                        height={0}
                        style={{ display: 'none' }}
                        src={previewImageUrl}
                        preview={{
                            visible, // 控制预览的可见性
                            onVisibleChange: (value) => setVisible(value), // 关闭时更新状态
                        }}
                    />
                </>
            }
        </div>}
    </SecondReplyStyle>
}

export default SecondReply