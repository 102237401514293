import { getFileUrlByCosUpload } from "config/cos";
import { CosDirectoryType } from "types/enums/media-type";
import { getFileName } from "utils/functions/common";

const loadAndCropImage = (imageUrl: string, scale?: number, type?: string) => {

    return new Promise((resolve) => {
        try {
            const img = new Image();
            img.src = imageUrl;
            img.crossOrigin = "anonymous"; // 解决跨域问题
            const imageScale = scale || 291 / 219

            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                // 计算裁剪区域
                const imgWidth = img.width;
                const imgHeight = img.height;
                const imgAspectRatio = imgWidth / imgHeight;

                let cropWidth, cropHeight, cropX, cropY, cropImageWidth, cropImageHeight;

                if (type === 'pc') {
                    if (imgAspectRatio > 1.3) {
                        // 图片比目标比例宽，裁剪宽度
                        cropHeight = imgHeight;
                        cropWidth = imgHeight * imageScale;
                        // 防止超框
                        if ((imgWidth - cropWidth) / 2 < 0) {
                            cropX = 0;
                            cropWidth = imgWidth
                        } else {
                            cropX = (imgWidth - cropWidth) / 2;
                        }
                        cropY = 0;
                    } else {
                        // 图片比目标比例高，裁剪高度
                        cropWidth = imgWidth;
                        cropHeight = imgWidth / imageScale;
                        cropX = 0;
                        // 防止超框
                        if (((imgHeight - cropHeight) / 2) < 0) {
                            cropY = 0
                            cropHeight = imgHeight
                        } else {
                            cropY = (imgHeight - cropHeight) / 2
                        }
                    }
                    cropImageWidth = 291
                    cropImageHeight = (cropHeight / cropWidth) * cropImageWidth
                } else {
                    if (imgAspectRatio > 1.3) {
                        // 图片比目标比例宽，裁剪宽度
                        cropHeight = imgHeight;
                        cropWidth = imgHeight * (291 / 219);
                        // 防止超框
                        if ((imgWidth - cropWidth) / 2 < 0) {
                            cropX = 0;
                            cropWidth = imgWidth
                        } else {
                            cropX = (imgWidth - cropWidth) / 2;
                        }
                        cropY = 0;
                        cropImageWidth = 291
                        cropImageHeight = (cropHeight / cropWidth) * cropImageWidth
                    } else {
                        // 图片比目标比例高，裁剪高度
                        cropWidth = imgWidth;
                        cropHeight = imgWidth / (300 / 400);
                        cropX = 0;
                        // 防止超框
                        if (((imgHeight - cropHeight) / 2) < 0) {
                            cropY = 0
                            cropHeight = imgHeight
                        } else {
                            cropY = (imgHeight - cropHeight) / 2
                        }
                        cropImageWidth = 300
                        cropImageHeight = (cropHeight / cropWidth) * cropImageWidth
                    }
                }

                // 设置 canvas 尺寸并裁剪
                canvas.width = cropWidth;
                canvas.height = cropHeight;
                ctx && ctx.drawImage(img, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

                const newCanvas = document.createElement('canvas');
                newCanvas.width = cropImageWidth * 1.2; // 目标宽度
                newCanvas.height = cropImageHeight * 1.2; // 目标高度
                const newCtx = newCanvas.getContext('2d');

                // 将原始画布内容绘制到新画布上，并缩放
                newCtx && newCtx.drawImage(canvas, 0, 0, cropImageWidth * 1.2, cropImageHeight * 1.2);

                newCanvas.toBlob(async (blob) => {
                    let file = new File([blob!], "image.jpg", { type: "image/jpeg" });
                    let urlKey = getFileName(CosDirectoryType.workCover, file.name)
                    let res = await getFileUrlByCosUpload(
                        file!,
                        urlKey
                    )
                    resolve(res.url)
                });

                // 将裁剪后的图片转换为 base64

                // console.log(base64Image)
                // return Promise.resolve(base64Image)
            };
        } catch (error) {
            console.error(error);
        }


    })


};

export default loadAndCropImage