import { Button, Input, message, Image } from "antd"
import { FloatChatInputStyle } from "./style"
import { useState, useCallback, useRef } from 'react'
import { CommentListItem } from "types/comment"
import { postCreateComment } from "api/work/comment";
import { useParams } from 'react-router-dom';
import EmojiPicker from "components/emoji-picker";
import { emojiParse, getFileName } from "utils/functions/common";
import CommentUpload from "components/comment-upload";
import { CosDirectoryType } from "types/enums/media-type";
import { getFileUrlByCosUpload } from "config/cos";
import { RiDeleteBack2Fill, RiEyeFill } from "react-icons/ri";

type ChatInputType = {
  params: {
    createComment: (val: CommentListItem) => void,
    dataTable?: string,
    subId?: number
  }
}



function FloatChatInput(props: ChatInputType) {
  const { id } = useParams<any>();
  const [value, setValue] = useState<string>('')
  const [visible, setVisible] = useState(false); // 控制预览的可见性
  const changeValue = useCallback((e: any) => {
    setValue(e.target.value)
  }, [])

  const [uploadImageFile, setUploadImageFile] = useState<{
    file?: File;
    imgUrl?: string;
  }>();

  const [uploadImageList, setUploadImageList] = useState<string[]>([])

  const [previewImageUrl, setPreviewImageUrl] = useState("")

  const [loading, setLoading] = useState<boolean>(false)

  const appendEmoji = (item: { url: string, title: string }) => {
    setValue((res) => res + item.title)
  }

  const upload = async (params: { imgUrl: string; file: File | undefined }) => {
    if (uploadImageList.length === 3) {
      message.warning("最多可上传3张图片")
      return
    }
    if (params.file) {
      setLoading(true);
      let urlKey = getFileName(CosDirectoryType.commentImage, params.file.name);
      let res = await getFileUrlByCosUpload(params.file!, urlKey);
      setUploadImageFile({
        file: undefined,
        imgUrl: res.url,
      });
      uploadImageList.push(res.url)
      setUploadImageList([...uploadImageList])
      setLoading(false);
    }
  };

  const send = () => {
    if (!value.trim()) {
      message.error("请输入评论内容！")
      return
    }
    setLoading(true)

    let content = JSON.parse(JSON.stringify(value))

    if (uploadImageList.length > 0) {
      content = `${content} [img]${uploadImageList.join(",")}[/img]`
    }

    postCreateComment({
      content: emojiParse(content),
      dataId: (props.params.subId!) || Number(id),
      dataTable: props.params?.dataTable || 'work'
    }).then((res) => {
      message.success('创建成功')
      props.params.createComment(res)
    }).finally(() => {
      setValue('')
      setLoading(false)
      setUploadImageList([])
      setUploadImageFile({
        file: undefined,
        imgUrl: ""
      })
    })
  }

  const deleteImage = (index: number) => {
    uploadImageList.splice(index, 1)
    setUploadImageList([...uploadImageList])
  }

  const onKeyDown = (code: number) => {
    if (code === 13) {
      send()
    }
  }

  const previewImage = (url: string) => {
    setVisible(true)
    setPreviewImageUrl(url)
  };

  return (
    <FloatChatInputStyle className="input-wrap">
      <div className="inputBar">
        <Input
          placeholder="请输入你的评论"
          className="input"
          value={value}
          onChange={changeValue}
          onKeyDown={(e) => onKeyDown(e.keyCode)}
        />
        <Button loading={loading} type="primary" className="btn" onClick={() => send()}>
          评论
        </Button>
      </div>
      <div className="operate">
        <EmojiPicker appendEmoji={appendEmoji} />
        <CommentUpload uploadImage={upload} value={uploadImageFile} color="#FFC712" size={20} />
      </div>

      {
        uploadImageList.length > 0 && <>
          <div className="cover-wrap">
            {
              uploadImageList.map((item, index) => {
                return <div className="cover" key={item} style={{ backgroundImage: `url(${item})` }}>
                  <div className="mask-wrap">
                    <div className="mask">
                      <div className="mask-item" onClick={() => previewImage(item)}>
                        <RiEyeFill />预览
                      </div>
                      <div className="mask-item" onClick={() => deleteImage(index)}>
                        <RiDeleteBack2Fill />删除
                      </div>
                    </div>
                  </div>
                </div>
              })
            }
          </div>
          {/* 使用 antd 的 Image 组件实现预览功能 */}
          <Image
            width={0} // 隐藏 Image 组件
            height={0}
            style={{ display: 'none' }}
            src={previewImageUrl}
            preview={{
              visible, // 控制预览的可见性
              onVisibleChange: (value) => setVisible(value), // 关闭时更新状态
            }}
          />
        </>
      }

    </FloatChatInputStyle>
  )
}

export default FloatChatInput