import styled from "styled-components";

export const SubUpdateStyle = styled.div`
 background-color: ${(props) => props.theme.colors.colorBgLayout};
 padding-bottom: 55px;
 /* padding-top: 50px; */
    .form-section{
        background-color: ${(props) => props.theme.colors.colorBgContainer};
        padding:  ${(props) => props.theme.space.paddingLG}px ${(props) => props.theme.space.paddingLG}px  ${(props) => props.theme.space.paddingSM}px   ${(props) => props.theme.space.paddingLG}px ;
        border-radius:  ${(props) => props.theme.border.borderRadiusLG}px;
        .checkout {
            margin-bottom: 15px;
        }
    }
    .main-container{
        width:  ${(props) => props.theme.space.mainContainer*0.82}px;
        margin: 0 auto;
        background-color: ${(props) => props.theme.colors.colorBgLayout};
        padding:  ${(props) => props.theme.space.paddingLG}px;
        display: flex;
        .left{
            flex: 1;
            .form-area{
                width: 100%;
            }
        }
        .ant-form-item-horizontal {
            label {
                height: 100%;
            }
        }
        .header-wrap {
            max-width: 300px;
            position: relative;
            .recruit {
                position: absolute;
                left: 330px;
                top: 34px;
                button {
                    display: flex;
                    align-items: center;
                    gap: 0;
                    svg {
                        font-size: 14px;
                    }
                    .ant-checkbox-checked .ant-checkbox-inner {
                        background-color: #fff !important;
                        &::after {
                            border-color: ${props => props.theme.colors.colorPrimary} !important;
                        }
                    }
                }
            }
        }
        .header{
            padding-bottom: ${(props) => props.theme.space.paddingSM}px;
            display: flex;
            justify-content: space-between;
        }
        .ant-tag {
            border: none !important;
        }
        .ant-input-show-count-suffix {
            font-size: 14px !important;
        }
        .ant-select-selector {
            border-color: #e4e4e4 !important;
        }
        .ant-input-data-count {
            font-size: 14px !important;
            bottom: 7px !important;
            right: 12px;
        }
        textarea {
            padding-bottom: 25px;
            font-size: 14px !important;
        }
        input,.ant-select-selection-item {
            font-size: 14px !important;
        }
        .fixed {
            position: fixed;
            display: flex;
            justify-content: center;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: #fff;
            height: 82px;
            align-items: center;
            box-shadow: 0px -2px 4px 0px rgba(138, 136, 169, 0.1);
            .block-btn {
                width: 1100px;
                button {
                    height: 40px;
                    border: solid 1px #dedbdd;
                    border-radius: 8px;
                    font-size: 14px !important;
                    width: 110px;
                }
                .primary {
                    width: 160px !important;
                    border: none !important;
                }
            }
        }
        .right{
            width: 320px;
            background-color: ${(props) => props.theme.colors.colorBgContainer};
            padding: ${(props) => props.theme.space.paddingSM}px ${(props) => props.theme.space.paddingSM}px;
            margin-left:  ${(props) => props.theme.space.paddingLG}px;
            border-radius:  ${(props) => props.theme.border.borderRadiusLG}px;
            .card{
                background-color: ${(props) => props.theme.colors.colorBgContainer};
                margin-bottom:  ${(props) => props.theme.space.paddingSM}px;
                .tab-wrap {
                    display: flex;
                    justify-content: center;
                    margin-top: 10px;
                }
                .footer{
                    padding: ${(props) => props.theme.space.paddingSM}px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    .choosePublic{
                        margin-bottom: ${(props) => props.theme.space.margin}px;
                        display: flex;
                        justify-content: center;
                    }
                    .block-btn{
                        width: 100%;
                        margin: ${(props) => props.theme.space.marginXS}px 0;
                    }
                    .wrap{
                        display: flex;
                        margin: ${(props) => props.theme.space.marginXS}px 0;
                        .btn{
                        display: flex;
                        align-items: center;
                        margin-right: ${(props) => props.theme.space.marginXS}px;
                        .btn-text{
                            padding:0 ${(props) => props.theme.space.paddingXXS}px;
                        }
                    }
                    }
                }
                .autocover-wrapper{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .autoCover{
                        text-align: center;
                        color: ${(props) => props.theme.colors.colorPrimary};
                        cursor: pointer;
                        margin-right: ${(props) => props.theme.space.marginXS}px;
                    }
                }
                
            }
        }
        .custom-title{
            padding-bottom:  ${(props) => props.theme.space.padding}px;
        }
        .tag-list-wrapper .title {
            font-size: 14px !important;
            span {
                color: #2b2e36;
            }
        }
    }
`