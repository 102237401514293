import { Checkbox } from "antd"
import { WorkFilterStyle } from "./style"
import { useEffect, useState } from "react"
import { ContestDetailResponse, WorkContestListRequest } from "types/contest"
import { hasToken, queryUrl } from "utils/functions/common";
import { useLocation, useNavigate } from "react-router";
import { loginPathWithRedirect } from "utils/functions/user";
import { apiGetSignUpField, apiPostSub } from "api/sub";

const ContestWorkFilter = ({ subDetail, showVoteFilter }: { updateValue: (value: WorkContestListRequest) => void, subDetail: ContestDetailResponse, showVoteFilter?: boolean }) => {
    const pathname = window.location.pathname
    const navigate = useNavigate()
    const sortField = new URLSearchParams(useLocation().search).get("sortField") || 'lastSubmitTime'
    let publishType = new URLSearchParams(useLocation().search).get("publishType") || ''
    let type = new URLSearchParams(useLocation().search).get("type") || ''
    const [checked, setCheckout] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setCheckout(publishType)
    }, [publishType])

    const handleChangeSort = (value: string) => {
        const query = queryUrl({ sortField: value, pageNumber: 1 })
        navigate(`${pathname}?${query}`)
    }
    let list = [
        // {
        //     label: "全部",
        //     value: ""
        // },
        {
            label: "最新",
            value: "lastSubmitTime"
        },
        {
            label: "最热",
            value: "threeDaysHot"
        },
    ]

    const token = hasToken()
    let userInfo = JSON.parse(localStorage.getItem('user_info')!)

    const toApply2 = () => {
        if (!token) {
            navigate(loginPathWithRedirect())
            return
        }
        if (!!loading) {
            return
        }
        if (subDetail?.canSignup === 1) {
            setLoading(true)
            apiGetSignUpField(subDetail.id).then((resField) => {
                const themeObj = resField.find(item => item.code === 'theme')
                const puzzleId = themeObj?.options.find(item => item.name === 'Puzzle Pro组队邀约')?.id
                apiPostSub({ theme: [Number(puzzleId)], contestId: subDetail.id, nickname: userInfo.username, mobile: userInfo.mobile, organization: "2025cusga" }).then(() => {
                    navigate(`/g-contest/upload/${subDetail.id}?from=2025cusga`)
                }).finally(() => {
                    setLoading(false)
                })
            }).finally(() => {
                setLoading(false)
            })
            return
        }
        navigate(`/g-contest/upload/${subDetail.id}?from=2025cusga`)
    }

    if (showVoteFilter) {
        list = [...list, {
            label: "票数",
            value: "ticketCount"
        }]
    }

    const onCheckoutChange = (e: boolean) => {
        const query = queryUrl({ publishType: e ? '2' : '', pageNumber: 1 })
        setCheckout(e ? '2' : '')
        navigate(`${pathname}?${query}`)
    }

    return <WorkFilterStyle>
        <div className="type-list">
            <div className="type-list-item">
                <div className="type-wrap">
                    <div className="type">
                        {
                            list.map(item => {
                                return <div key={item.value} className={`type-item ${item.value === sortField ? 'active' : ''}`} onClick={() => handleChangeSort(item.value)}>
                                    <span>{item.label}</span>
                                </div>
                            })
                        }
                        {
                            type === 'art' && <div className="type-item">
                                <Checkbox checked={checked === '2' ? true : false} onChange={(e) => onCheckoutChange(e.target.checked)}>
                                    只看完成作品
                                </Checkbox>
                            </div>
                        }

                    </div>
                    {
                        type === 'puzzle' && !!subDetail && (!!subDetail.canSignup || !!subDetail.canUpload) && <div className="btn btn2" onClick={toApply2}>
                            <svg width="100%" height="100%">
                                <text
                                    x="50%" y="50%"
                                    className="tab-text"
                                    dominant-baseline="middle"
                                >
                                    立即招募
                                </text>
                            </svg>
                        </div>
                    }
                </div>
            </div>
        </div>
    </WorkFilterStyle>
}

export default ContestWorkFilter