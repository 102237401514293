import styled from "styled-components";

export const Ly3dWorkprizeStyle = styled.div`
    @font-face {
      font-family: 'ShuHeiTi';
      src: url('https://cdn.ggac.com/fonts/Alimama_ShuHeiTi_Bold.ttf');
    }
    @font-face {
        font-family: 'AlibabaPuHuiTiR';
        src: url('https://cdn-prd.ggac.com/ggac/font/AlibabaPuHuiTi.ttf');
    }
    .workprize {
        background: url("https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-workprize-bg.png") center center no-repeat;
        background-size: cover;
        margin-top: -10px;
        padding: 100px 0;
        font-family: 'alibabaPuHuiTi';
        .title-1 {
          width: 1206px !important;
          height: 83px !important;
          letter-spacing: 0 !important;
          padding-left: 0 !important;
        }
        .title {
            font-family: 'ShuHeiTi';
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin-bottom: 30px;
            &-word {
                display: flex;
                align-items: center;
                font-size: 64px;
	            font-weight: normal;
	            font-stretch: normal;
	            letter-spacing: 13px;
	            color: #15435e;
                margin: 0 40px 0 50px;
                /* text-shadow: 0px 2px 3px rgba(0,45,107,0.95); */
                background: linear-gradient(0deg, #48DAFF 0%, #F7FEFF 50%);
                background: -webkit-linear-gradient(90deg, #48DAFF 0%, #F7FEFF 50%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                img {
                    width: 17px;
                    margin-right: 13px;
                    margin-top: 10px;
                }

            }
            .arrow {
                width: 152px;
                margin-top: 10px;
            }
          
            .top {
                position: absolute;
                top: 25px;
                left: 50%;
                transform: translateX(-50%);
                span {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    font-size: 36px;
	                letter-spacing: 5px;
	                background: linear-gradient(0deg, #F7FEFF 0%, #8EE8FF 100%);
                    opacity: 0.5;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                img {
                    width: 142px;
                }
            }
        }
        .tip {
            text-align: center;
            font-size: 20px;
            margin-bottom: 20px;
        }
        .work {
          display: flex;
          justify-content: center;
          gap: 50px;
          width: 1200px;
          margin: 0 auto 50px;
          flex-wrap: wrap;
          .work-item {
            border: solid 1px rgba(255,255,255,.5);
            border-radius: 8px;
            box-sizing: border-box;
          }
          .cover {
            width: 1200px;
	          height: 400px;
            box-sizing: border-box;
            background-size: 100%;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            cursor: pointer;
            background-size: cover;
            background-position: center;
            display: block;
          }
          video {
            width: 1200px;
	          height: 450px;
            object-fit: cover;
          }
          .info-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .link {
              color: #6758e3;
              padding-right: 26px;
              cursor: pointer;
              font-weight: bold;
            }
          }
          .info {
            height: 72px;
            display: flex;
            padding: 0 26px;
            align-items: center;
            gap: 20px;
            color: #fff;
            .user_avatar_image {
              width: 40px;
	            height: 40px;
              border-radius: 50%;
              background-size: cover;
              cursor: pointer;
              background-position: center center;
            }
            .user-name {
              font-size: 16px;
              max-width: calc(100% - 60px);;
              a {
                color: #fff;
              }
            }
          }
        }

        .work-5{
            .work-item {
                width: 575px !important;
            }
            .cover {
                width: 573px !important;
                aspect-ratio: 900 / 677;
                height: auto !important;
            }
        }
    }
`