import { WorkContestListItem } from "types/contest"
import { LeftTopNodeStyle } from "./style"
import { WorkExhibitionListItem } from "types/user/work"

const LeftTopNode = ({ item }: { item: WorkContestListItem | WorkExhibitionListItem }) => {
    return <LeftTopNodeStyle>
        {
            !!item.isRecruit && <div className="left-top">
                <div className="item">招募中</div>
            </div>
        }

    </LeftTopNodeStyle>
}

export default LeftTopNode