import { useEffect, useRef, useState } from "react";
import { ImagePreviewPcStyle } from "./style"
import { CategoryItem } from "types/user/work";
import { UserBaseInfo } from "types/user";
import { RiEyeLine, RiFireLine } from "react-icons/ri";
import ImgCrop from "antd-img-crop";
import { Button, message, Upload, UploadFile } from "antd";

type AntSelectOption = {
    label: string;
    value: string | number;
};

type WorkUpdateForm = {
    title?: string;
    categoryIds?: number[];
    mediaCategory?: string;
};

type ImageUploadProps = {
    limit?: number;
    width?: number;
    height?: number;
    value?: {
        imgUrl?: string;
        file?: Blob;
    };
    aspect?: number;
    isSquare?: boolean;
    onChange?: (params: { imgUrl: string; file: File | undefined }) => void;
    disabled?: boolean;
    uploadImage: (params: { imgUrl: string; file: File | undefined }) => void;
    deleteImage?: () => void;
    noNeedCrop?: boolean;
    limitSize?: number
    formValues?: WorkUpdateForm
    mediaCategoryOptions: AntSelectOption[]
    categoryList: CategoryItem[]
    originCoverImage: string
};

const ImagePreviewPc = (props: ImageUploadProps) => {
    const [mediaCategoryName, setMediaCategoryName] = useState("")
    const [title, setTitle] = useState("")
    const [mediaCategory, setMediaCategory] = useState("")
    const [categoryIds, setCategoryIds] = useState<number[]>([])
    const [categoryName, setCategoryName] = useState("")
    let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)
    const [imgUrl, setImageUrl] = useState<string>(props.value?.imgUrl ?? "");
    const [loading, setLoading] = useState<boolean>(false);
    const uploadRef = useRef() as React.MutableRefObject<any>;
    const triggerChange = (changedValue: {
        imgUrl: string;
        file: File | undefined;
    }) => {
        props.onChange?.({
            ...props!.value,
            ...changedValue,
        });
    };

    useEffect(() => {
        setImageUrl(props.value?.imgUrl || '')
    }, [props.value])

    useEffect(() => {
        setTitle(props.formValues?.title || '')
        setMediaCategory(props.formValues?.mediaCategory || '')
        setCategoryIds(props.formValues?.categoryIds || [])
    }, [props.formValues])

    useEffect(() => {
        if (props.mediaCategoryOptions.length === 0) {
            return
        }
        if (!mediaCategory) {
            return
        }
        console.log(mediaCategory)
        const detail = props.mediaCategoryOptions.find(item => item.value === mediaCategory)
        if (!!detail) {
            setMediaCategoryName(detail.label)
        }
    }, [props.mediaCategoryOptions, mediaCategory])

    useEffect(() => {
        if (categoryIds.length === 0) {
            return
        }
        if (props.categoryList.length === 0) {
            return
        }
        const firstData = props.categoryList.find(item => item.id === categoryIds[0])
        const secondData = firstData?.children.find(item => item.id === categoryIds[1])
        setCategoryName(secondData?.name || '')
    }, [props.categoryList, categoryIds])

    const [fileList, setFileList] = useState<UploadFile<{ url: string }>[]>([]);

    const beforeUpload = (file: File) => {
        setLoading(true);
        const isJpgOrPng =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/gif";
        if (!isJpgOrPng) {
            message.error("只允许上传 JPG/PNG/GIF 格式的文件!");
            setLoading(false);
            return;
        }
        let isLimit = true;

        isLimit = file.size / 1024 / 1024 < (props.limitSize || 10);
        if (!isLimit) {
            message.error("图片大小超出限制");
            setLoading(false);
            return;
        }

        if (isJpgOrPng && isLimit) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                let Img = new Image();
                Img.onload = () => {
                    let isRightWidth = true;
                    let isRightHeight = true;
                    if (props.width) {
                        isRightWidth = props.width === Img.width;
                    }
                    if (props.height) {
                        isRightHeight = props.height === Img.height;
                    }
                    let isSquaewNeed = true;
                    if (!isSquaewNeed) return;
                    if (isRightWidth && isRightHeight) {
                        fileList.length = 0;
                        setFileList([
                            {
                                uid: "1",
                                url: reader.result as string,
                                name: file.name,
                            },
                        ]);
                        triggerChange({
                            imgUrl: reader.result as string,
                            file: file,
                        });

                        setImageUrl(reader.result as string);
                        setLoading(false);
                    } else {
                        message.error("图片尺寸不满足要求");
                    }
                };
                Img.src = reader.result as string;
            });
            props?.uploadImage({
                imgUrl: reader.result as string,
                file: file,
            });
            reader.readAsDataURL(file);
        }
        return false;
    };


    const editCover = async () => {

        const response = await fetch("https://cdn-dev.ggac.com/ggac/work/cover/2024/11/28/cf3503bd-7a1c-4c93-8fea-ef4db6893103-900x.jpg");
        const blob = await response.blob();
        const file = new File([blob], 'image.png', { type: blob.type });

        // 设置文件列表
        setFileList([
            {
                uid: '-1',
                name: 'image.png',
                // status: 'done',
                url: URL.createObjectURL(file),
                // originFileObj: file,
            },
        ]);
        uploadRef.current.upload.uploader.onChange({
            file: file,
            fileList: [file],
        });
        console.log(uploadRef.current.upload)
    }

    const uploadCover = () => {

        // 手动触发裁剪弹窗
        if (uploadRef.current) {
            uploadRef.current.upload.uploader.fileInput.click(); // 触发裁剪弹窗
        }
    }

    // 文件列表变化时的回调
    const onFileChange = (e: any) => {
        // setFileList(newFileList);
        console.log(e)
    };

    return <ImagePreviewPcStyle>
        <div className="borde">
            <div className="cover" style={{ backgroundImage: `url(${imgUrl})` }}>
                <div className="btn-wrap">
                    <Button type="primary" loading={loading} onClick={uploadCover}>编辑封面</Button>
                    {/* <Button type="primary" loading={loading} onClick={uploadCover}>上传封面</Button> */}
                </div>
            </div>
            <div className="detail">
                <div className="title">{title || 'XXX'}</div>
                <div className="category">
                    <span>{mediaCategoryName || 'XXX'}</span>
                    |
                    <span>{categoryName || 'XXX'}</span>
                </div>
                <div className="user-info">
                    <div className="left">
                        <div className="user-cover" style={{ backgroundImage: `url(${userInfo.avatarUrl})` }} />
                        <div className="username">{userInfo.username}</div>
                    </div>
                    <div className="user-right">
                        <div className="user-right-item">
                            <RiEyeLine className='icon' />
                            <span>XXX</span>
                        </div>
                        <div className="user-right-item">
                            <RiFireLine className='icon' />
                            <span>XXX</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* 自定义按钮 */}

        <div>
            <ImgCrop aspect={props.aspect || 291 / 219} rotationSlider>
                <Upload
                    listType="picture-card"
                    beforeUpload={beforeUpload}
                    fileList={fileList}
                    onChange={({ fileList: newFileList }) => setFileList(newFileList)}
                    disabled={props.disabled}
                    ref={uploadRef}
                >
                    {/* <Button type="primary" loading={loading} onClick={editCover}>编辑封面</Button> */}
                </Upload>
            </ImgCrop>
        </div>

    </ImagePreviewPcStyle>
}

export default ImagePreviewPc