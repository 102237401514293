import {
  Menu,
  MenuProps,
  Tooltip,
  Upload,
  UploadProps,
  message,
} from "antd";
import { UserCenterLayoutStyle } from "./style";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { RiArrowRightSLine, RiEditLine } from "react-icons/ri";
import {
  determinRightPannelWidth,
  determineUserBackground,
  mapCurrentPath,
} from "./fn";
import { getFileUrlByCosUpload } from "config/cos";
import { ProgressInfo } from "cos-js-sdk-v5";
import { makeFilePath } from "utils/functions/file";
import { apiUserDetail, postUserDetail } from "api/user/detail";
import { userAllDetail } from "types/user/detail";
import UserCenterProfile from "./user-profile";
import { UserBaseInfo } from "types/user";
import { activeGetLocalUserInfo } from "utils/functions/common";

type MenuType = {
  key: string;
  icon: React.ReactNode;
  label: string;
  children?: MenuType[];
};

function UserCenterLayout() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [stateOpenKeys, setStateOpenKeys] = useState<string[]>([]);
  let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)

  const itemsData: MenuType[] = [
    { key: "/user-center/home/work/list", icon: <></>, label: "中心首页" },
    { key: "/user-center/certified-author", icon: <></>, label: "认证作者" },
    { key: "/user-center/applicant/received", icon: <></>, label: "我的邀约" },
    {
      key: "shop",
      icon: <></>,
      label: "我的店铺",
      children: [
        { key: "/user-center/shop/product-list", icon: <></>, label: "商品管理" },
        { key: "/user-center/shop/order-list", icon: <></>, label: "商品订单" },
        { key: "/user-center/shop/commission/product-list", icon: <></>, label: "约稿管理" },
        { key: "/user-center/shop/commission/order-list", icon: <></>, label: "约稿订单" },
      ],
    },
    {
      key: "assets",
      icon: <></>,
      label: "我的资产",
      children: [
        { key: "/user-center/wallet/index", icon: <></>, label: "我的钱包" },
        { key: "/user-center/redeem", icon: <></>, label: "G分兑换" },
      ],
    },
    { key: "/user-center/order?state=-1", icon: <></>, label: "我的购买" },
    {
      key: "achievement",
      icon: <></>,
      label: "荣誉成就",
      children: [
        { key: "/user-center/badges", icon: <></>, label: "成就徽章" },
        { key: "/user-center/certificates", icon: <></>, label: "荣誉证书" }
      ],
    },
    {
      key: "setting",
      icon: <></>,
      label: "个人资料",
      children: [
        { key: "/user-center/user-detail", icon: <></>, label: "基本信息" },
        { key: "/user-center/authentication/idCard", icon: <></>, label: "实名认证" },
        { key: "/user-center/address", icon: <></>, label: "收货地址" },
        { key: "/user-center/account", icon: <></>, label: "账号设置" },
        { key: "/user-center/notice", icon: <></>, label: "信息搜集" },
      ],
    },
    // { key: "/user-center/user-order", icon: <></>, label: "我的账户" },
  ];

  const [items, setItems] = useState(itemsData)

  const onClick: MenuProps["onClick"] = (item) => {
    setCurrentPath(item.key);
    navigate(item.key);
  };
  let location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [userDetail, setUserDetail] = useState<userAllDetail>();

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);


  useEffect(() => {
    if (!userInfo) {
      return
    }
    const assetsList = items.find(item => item.key === 'assets')
    if (!assetsList?.children) {
      return
    }
    const index = (assetsList?.children || []).findIndex(item => item.key === '/user-center/nft-album')
    if (index > -1) {
      return
    }
    assetsList.children.push({ key: "/user-center/nft-album", icon: <></>, label: "我的图鉴" })
    setItems([...items])

  }, [userInfo, items])

  const props: UploadProps = {
    beforeUpload: (file) => {
      setLoading(true);
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("只允许上传 JPG/PNG 格式的文件!");
        setLoading(false);
        return;
      }
      if (file.size > 5242880) {
        message.error("图片大小不能超过5M");
        setLoading(false);
        return;
      }
      getFileUrlByCosUpload(
        file as File,
        makeFilePath("background", file?.name ?? ""),
        (progressData: ProgressInfo, key: string) => { }
      ).then((res) => {
        saveUserBackground(res.url);
      });
    },
    showUploadList: false,
  };

  const saveUserBackground = (url: string) => {
    postUserDetail({ bgImage: url }).then(() => {
      getUserDetail();
    });
  };

  useEffect(() => {
    getUserDetail();
    activeGetLocalUserInfo();
  }, []);

  const getUserDetail = () => {
    apiUserDetail().then((res) => {
      setUserDetail(res);
    });
  };

  useEffect(() => {
    const path = mapCurrentPath(currentPath)
    items.map(item => {
      if (!!item.children) {
        const subIndex = item.children.findIndex(item => item.key === path)
        if (subIndex >= 0) {
          setStateOpenKeys([item.key, path])
        }
      }
    })
  }, [currentPath])

  interface LevelKeysProps {
    key?: string;
    children?: LevelKeysProps[];
  }

  const getLevelKeys = (items1: LevelKeysProps[]) => {
    const key: Record<string, number> = {};
    const func = (items2: LevelKeysProps[], level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
  };

  const levelKeys = getLevelKeys(items as LevelKeysProps[]);

  const onOpenChange: MenuProps['onOpenChange'] = (openKeys) => {
    const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
      );
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
  };

  return (
    <UserCenterLayoutStyle>
      {/* <div className="layout-padding"></div> */}
      <div
        className="banner"
        style={{
          backgroundImage: `url(${determineUserBackground(
            userDetail?.userInfo?.bgImage ?? ""
          )})`,
        }}
      >
        <div className="actions">
          <Tooltip title="自定义背景，建议比例 1920x300">
            <Upload {...props}>
              <div className="item">
                <RiEditLine className="icon-btn"></RiEditLine>
              </div>
            </Upload>
          </Tooltip>
        </div>

        <div className="to-user-frontpage">
          <div
            className="link"
            onClick={() => navigate(`/user/${userDetail?.id}`)}
          >
            <span>前往我的主页</span>
            <RiArrowRightSLine className="icon"></RiArrowRightSLine>
          </div>
        </div>
      </div>
      <div className="main-container" id="main-container">
        <div className="left-pannel">
          <UserCenterProfile from="user-center" isCanEdit={true} type="user-center"></UserCenterProfile>
          <Menu
            onClick={onClick}
            mode="inline"
            items={items}
            className="menu"
            selectedKeys={[mapCurrentPath(currentPath)]}
            openKeys={stateOpenKeys}
            onOpenChange={onOpenChange}
          />
        </div>
        <div className="right-pannel">
          <div className="wrapper" style={{ width: determinRightPannelWidth(location.pathname) }}>

            <Outlet />
          </div>

        </div>
      </div>
    </UserCenterLayoutStyle>
  );
}

export default UserCenterLayout;
