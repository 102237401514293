import styled from "styled-components";

export const WorkApplicantStyle = styled.div`
.work-detail {
    width: 124px;
    margin-top: 10px;
    .cover {
        width: 100%;
        height: 100px;
        background-size: cover;
        cursor: pointer;
        border-radius: 6px;
    }

    .work-name {
        text-align: center;
        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        width: 100%;
        margin-top: 3px;
    }
}

label {
    width: 80px;
    text-align: right;
}
`