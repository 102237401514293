import { Button, Divider, FloatButton, Spin, message } from "antd";
import { getArticleDetail } from "api/article";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ArticleDetailResponse } from "types/article";
import { ArticleDetailStyle } from "./style";

import Comments from "components/comments";
import { CommentListItem } from "types/comment";
import TextArea from "antd/es/input/TextArea";
import { postCreateComment } from "api/work/comment";
import { ArticleDetailCardInfo } from "../type";
import { ArticleWorkCard } from "../components/article-card";
import GuanggaoBannerTwo from "components/guanggao-banner-two";
import ArticleHeader from "./header";
import Tags from "./tags";
import ArticleIntercation from "./interaction";
import UserCardGlobal from "components/userBlock";
import { RiMessageFill } from "react-icons/ri";
import EmojiPicker from "components/emoji-picker";
import { emojiParse, isMobile } from "utils/functions/common";
import SuperInteraction from "./super-interaction";
import { determineSuperaction } from "utils/functions/user";
import CollaborativeCreators from "views/work/detail/right/collaborativeCreators";

export const ArticleDetail = () => {
  const { id } = useParams();
  const [detail, setDetail] = useState<ArticleDetailResponse>();
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const commentRef = useRef() as React.MutableRefObject<any>;
  const [isCanDeleteComment, setIsCanDeleteComment] = useState(false)
  let userId = JSON.parse(localStorage.getItem('user_info')!)?.id

  // 调用commet创建方法
  const createComment = (val: CommentListItem) => {
    commentRef.current?.addComment(val);
  };

  const [commentCount, setCommentCount] = useState<number>(0);

  const getDetail = () => {
    setLoading(true);
    getArticleDetail(+id!)
      .then((res) => {
        setDetail(res);
        setCommentCount(res.commentCount);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getDetail();
  }, []);

  useEffect(() => {
    if (!detail) {
      return
    }

    if (userId === detail.userId) {
      setIsCanDeleteComment(true)
    }
  }, [detail, userId])

  const updateCommentCount = () => {
    getArticleDetail(Number(id)).then((res) => {
      setCommentCount(res.commentCount);
    });
  };

  useEffect(() => {
    const checkIsMobile = isMobile()
    if (checkIsMobile) {
      window.location.href = `https://m.ggac.com` + window.location.pathname
      return
    }
  }, [])

  const onClickComment = () => {
    if (!comment.trim()) {
      message.warning("请输入内容");
      return;
    }
    setLoading(true);
    postCreateComment({
      content: emojiParse(comment),
      dataId: Number(id),
      dataTable: "article",
    })
      .then((res) => {
        message.success("创建成功");
        createComment(res);
      })
      .finally(() => {
        setComment("");
        setLoading(false);
      });
  };

  const onChangeComment = (event: any) => {
    setComment(event.target.value);
  };

  const appendEmoji = (item: { url: string; title: string }) => {
    setComment((res) => res + item.title);
  };

  const onClickGoWorkDetail = (item: ArticleDetailCardInfo) => {
    window.open(`/work/detail/${item.id}`);
  };

  const toCommentPostion = () => {
    const offsetTop = document.getElementById("input-wrap")?.offsetTop!;
    window.scrollTo({
      top: offsetTop - 50,
      behavior: "smooth",
    });
  };

  return (
    <ArticleDetailStyle>
      <div className="guanggao-banner-wrapper">
        <GuanggaoBannerTwo
          positionCode={20}
          aspectRatio={10 / 1.6}
        ></GuanggaoBannerTwo>
      </div>
      <div className="container">
        <Spin spinning={loading}>
          {detail && (
            <ArticleHeader
              commentCount={commentCount}
              detail={detail}
              onDetailChange={(detail) => setDetail(detail)}
            ></ArticleHeader>
          )}

          <Divider></Divider>
          <div className="section-holder">
            <div className="left">
              <div className="content-wrapper">
                <div
                  className="html-content"
                  dangerouslySetInnerHTML={{ __html: detail?.content! }}
                ></div>
              </div>
            </div>
            <div className="right">
              <div className="section-block">

                {detail && detail.creatorCount === 0 && <UserCardGlobal userId={detail?.userId}></UserCardGlobal>}
                {detail && detail.creatorCount > 0 && <CollaborativeCreators type={2}></CollaborativeCreators>}
              </div>
              {determineSuperaction() && (
                <div className="section-block">
                  {detail && (
                    <SuperInteraction
                      detail={detail}
                      updateDetail={(detail) => setDetail(detail)}
                    ></SuperInteraction>
                  )}
                </div>
              )}

              <div className="section-block">
                {detail && <Tags tagList={detail.tagList}></Tags>}
              </div>
            </div>
          </div>
          {detail && (
            <ArticleIntercation
              detail={detail}
              onDetailChange={(detail) => setDetail(detail)}
              shouldGetDetail={() => getDetail()}
            ></ArticleIntercation>
          )}
          {detail?.workList && detail?.workList?.length > 0 && (
            <div className="works-wrapper">
              <div className="work-list-title">关联作品</div>
              <div className="work-list-wrapper">
                {detail?.workList.map((item) => (
                  <div
                    onClick={() => onClickGoWorkDetail(item)}
                    key={item.id}
                    className="card-item"
                  >
                    <ArticleWorkCard info={item}></ArticleWorkCard>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="comment-wrapper" id="input-wrap">
            <div className="input-wrapper">
              <TextArea
                allowClear
                value={comment}
                onChange={onChangeComment}
                placeholder="请输入你的评论"
              // onPressEnter={onClickComment}
              />
              <div className="input">
                <EmojiPicker appendEmoji={appendEmoji} />
                <Button
                  className="confirm-btn"
                  type="primary"
                  onClick={onClickComment}
                >
                  确认
                </Button>
              </div>
            </div>
            <Comments
              ref={commentRef}
              params={{ commentCount, updateCommentCount, type: "article", canDeleteComment: isCanDeleteComment }}
            ></Comments>
          </div>
          <div style={{ height: "200px" }}></div>

          <div className="float-wrapper">
            <FloatButton.Group shape="circle" style={{ right: 24 }}>
              <FloatButton
                tooltip="前往评论"
                onClick={toCommentPostion}
                icon={<RiMessageFill />}
              />
              <FloatButton.BackTop visibilityHeight={100} />
            </FloatButton.Group>
          </div>
        </Spin>
      </div>
    </ArticleDetailStyle>
  );
};
