import styled from "styled-components";

export const WorkFilterStyle = styled.div`
    padding: ${props => props.theme.space.paddingLG}px ${props => props.theme.space.paddingLG + 6}px;
    display: flex;
    gap: ${props => props.theme.space.paddingLG}px;
    background-color:  ${props => props.theme.colors.colorBlack1Ggac};
    .type-list2 {
        margin-top: ${props => props.theme.space.margin}px;
    }
    .type-list {
        width: 100%;
        &-item {
            display: flex;
            gap:  ${props => props.theme.space.margin}px;
            margin-bottom: ${props => props.theme.space.marginXS}px;
            &:last-child{
                margin-bottom: 0;
            }
            .title {
                font-weight: bold;
                min-width: 115px;
            }
            .fwb {
                span {
                    font-weight: bold;
                }
            }
            .type-wrap {
                display: flex;
                width: 100%;
                justify-content: space-between;
                position: relative;
                .right {
                    color: ${props => props.theme.colors.colorPrimary};
                    cursor: pointer;
                }
            }
            .flex {
                span {
                    font-weight: normal !important;
                }
            }
            .type {
                display: flex;
                gap: ${props => props.theme.space.margin}px;
                
                &-item {
                    cursor: pointer;
                    display: flex;
                    gap: ${props => props.theme.space.margin}px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &.active {
                        span { 
                            color: ${props => props.theme.colors.colorPrimary};
                        }
                    }
                    .second-type {
                        display: flex;
                        gap: ${props => props.theme.space.margin}px;
                    }
                    .second-active {
                        color: ${props => props.theme.colors.colorPrimary};
                    }
                }
                
            }
        }
    }


    .btn {
        width: 6vw;
	    aspect-ratio: 272 / 95;
        background: url("https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-kv-btn.png") center center no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        text-align: center;
	    letter-spacing: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 2px;
	    color: #ffffff;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .tab-text {
        fill: white;
        stroke: #000;
        text-anchor:middle;
        paint-order: stroke;
        stroke-linejoin: round;
        letter-spacing: 1px;
        stroke-width: 4;
    }
`