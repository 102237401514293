import { NavMenuStyle } from "./style";
import { useEffect, useState } from "react";
import { store } from "utils/store";
import NavMenuFilter from "./menu";
import { CarouselListItem, CarouselListRequest } from "types/common/carousel";
import { getCarouselList, sendCarouselLog } from "api/common/carousel";
import { shuffleArray } from "utils/functions/common";
import BusinessContact from "components/business-contact";

function NavMenu() {
  const [headerType, setHeaderType] = useState("default");
  const [banner, setBanner] = useState<CarouselListItem>();
  const [isShowBusiness, setIsShowBusiness] = useState(false);
  const onClose = () => {
    setIsShowBusiness(false)
  }
  const handleHeaderTypeChange = () => {
    setHeaderType(store.getState().headerType);
  };
  useEffect(() => {
    const unsubscribe = store.subscribe(handleHeaderTypeChange);
    return () => {
      unsubscribe();
    };
  }, []);

  const getSlideList = () => {
    const params: CarouselListRequest = {
      lang: 1,
      type: 22,
      status: 1,
    };
    getCarouselList(params).then((res) => {
      setBanner(shuffleArray(res.pageData)?.[0]);
    });
  };

  useEffect(() => {
    getSlideList();
  }, []);

  const jump = (banner:CarouselListItem)=>{
    if(banner.webUrl==='#biz'){
      setIsShowBusiness(true)
      return 
    }
    sendCarouselLog({carouselId:banner.id})
    window.open(banner.webUrl);
  }

  return (
    <NavMenuStyle
      style={{ visibility: headerType === "default" ? "visible" : "hidden" }}
    >
      <div className="container_">
        <div className="wrap">
          <NavMenuFilter count={1}></NavMenuFilter>
          {banner && (
            <div
              className="side-gg-banner"
              onClick={() =>jump(banner)}
            >
              <img src={banner?.displayImage} alt="" className="banner-item" />
            </div>
          )}
        </div>
      </div>
      {/* <BusinessContact onClose={onClose} isShow={isShowBusiness} /> */}
    </NavMenuStyle>
  );
}

export default NavMenu;
