import styled from "styled-components";

export const MediaIconsStyle = styled.div`
    .wrap {
      display: flex;
      height: 20px;
      gap: 4px;
    }
    .video {
      padding-left: 1px;
    }
    .item{
      color:  ${(props) => props.theme.colors.colorTextLightSolid};
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      background-color: rgba(43,46,54,.55);
      border-radius: 50% !important;
      width: 24px;
      height: 24px;
      div {
        display: flex;
        align-items: center;
      }
      &:first-child{
        border-radius: 5px 0 0 0;
      }
      svg {
        color: #fff;
      }
    }
`