import {
  Affix,
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Spin,
  Tooltip,
  message,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { WorkUpdateStyle } from "./style";
import ComboUpload from "../components/comboUpload";
import { useEffect, useState } from "react";
import { SecondSelect } from "components/work-category";
import { TagSelect } from "components/tagSelect";
import { getFileUrlByCosUpload } from "config/cos";
import { getFileName, getLocalUserInfo } from "utils/functions/common";
import { CosDirectoryType } from "types/enums/media-type";
import {
  apiGetWorkDraftDetail,
  createWorkDraft,
  getWorkCategory,
  updateWorkDraft,
} from "api/work";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { apiGetCategoryToProductWorktype, apiGetDictList } from "api/common";
import { CreationWare } from "./creationWare";
import { loginPathWithRedirect } from "utils/functions/user";
import AutoPublish from "./autoPublish";
import Collabration, { collabrationUserSimple } from "./collabration";
import { draftWorkDetailType } from "types/work";
import { CfgCategoryToProductWorktypeResponse } from "types/common";
import { apiGetMallProductListCategory } from "api/shop";
import { apiPostCreateCommdity } from "api/commodity";
import { apiGetUserBaseInfo } from "api/user";
import ShopYuegaoAgreement from "views/user-shop/productManage/create/yuegaoAgreement";
import { isImageUrl } from "utils/functions/file";
import loadAndCropImage from "./cropImage";
import ImagePreviewPc from "./imagePreview/pc"
import { CategoryItem } from "types/user/work";
import ImagePreviewMobile from "./imagePreview/mobile";
import { postCreateComment } from "api/work/comment";

type WorkUpdateForm = {
  title: string;
  tagList: string[];
  categoryIds: number[];
  coverUrl: {
    file?: File;
    imgUrl?: string;
  };
  coverMobileUrl: {
    file?: File;
    imgUrl?: string;
  };
  description: string;
  mediaList: { id: number; url: string, percent: number }[];
  hardwareList?: string[];
  softwareList?: string[];
  mediaCategory: string;
};
type AntSelectOption = {
  label: string;
  value: string | number;
};
function WorkUpdate() {
  const [top] = useState(64);

  const [form] = Form.useForm<WorkUpdateForm>();

  const [coverUrlFile, setCoverUrlFile] = useState<{
    file?: File;
    imgUrl?: string;
  }>();
  const [coverMobileUrlFile, setCoverMobileUrlFile] = useState<{
    file?: File;
    imgUrl?: string;
  }>();

  const [isPublic, setIsPublic] = useState<number>(1);

  const [loading, setLoading] = useState(false);

  const [mediaCategoryOptions, setMediaCategoryOptions] =
    useState<AntSelectOption[]>();

  const mediaCategory = Form.useWatch("mediaCategory", form);
  const initTitle = Form.useWatch("title", form);
  const initDescription = Form.useWatch("description", form);

  const [copyContestWork, setCopyContestWork] = useState<number>(0);

  const [isShowShopYuegaoAgreement, setIsShowShopYuegaoAgreement] = useState(false)

  const [isSelectPcCover, setIsSelectPcCover] = useState(true)

  const [originCoverImage, setOriginCoverImage] = useState("")

  const [isGameDesigner, setIsGameDesigner] = useState(false) // 创作类型是否游戏策划

  let navigate = useNavigate();

  let { id } = useParams();

  const exhibitionTag = new URLSearchParams(useLocation().search).get("tag");
  const workId = new URLSearchParams(useLocation().search).get("workId");
  const type = new URLSearchParams(useLocation().search).get("type");
  const isShowPrivate = Number(
    new URLSearchParams(useLocation().search).get("isShowPrivate") || 1
  );
  const [creatorList, setCreatorList] = useState<collabrationUserSimple[]>([]);
  const [detail, setDetail] = useState<draftWorkDetailType>()
  const [isShowAgreeYuegaoAgreement, setShowIsAgreeYuegaoAgreement] = useState(false)
  const [isAgreeYuegaoAgreement, setIsAgreeYuegaoAgreement] = useState(false)
  const [productWorkTypeList, setProductWorkTypeList] = useState<CfgCategoryToProductWorktypeResponse[]>([])
  const [isCroppingImage, setIsCroppingImage] = useState(false)
  const [formValues, setFormValues] = useState<WorkUpdateForm>();
  const [isSelectRecruit, setIsSelectRecruit] = useState(false)
  const [placeholder, setPlaceholder] = useState('')
  let localUser = getLocalUserInfo();
  const [categoryList, setCategoryList] = useState<CategoryItem[]>([])
  useEffect(() => {
    if (!localUser) {
      navigate(loginPathWithRedirect());
    }
    if (!id) {
      apiGetCategoryToProductWorktype().then(res => {
        setProductWorkTypeList(res)
      })
    }
  }, []);

  useEffect(() => {
    getWorkCategory({ useWorkUpload: 1, }).then(res => {
      setCategoryList(res)
    })
  }, [])

  useEffect(() => {
    if (!isPublic) {
      setIsAgreeYuegaoAgreement(false)
      setShowIsAgreeYuegaoAgreement(false)
      return
    }
    setShowIsAgreeYuegaoAgreement(true)
  }, [isPublic])

  const onChangeIsPcCover = (e: any) => {
    setIsSelectPcCover(e.target.value)
  }


  useEffect(() => {
    initFormData();
  }, [id, form]);

  useEffect(() => {
    if (!exhibitionTag) return;
    form.setFieldValue("tagList", JSON.parse(exhibitionTag || "[]"));
  }, [exhibitionTag]);

  // 判断上传第一张图片时将第一张图片裁剪
  const mediaList = Form.useWatch('mediaList', form);

  useEffect(() => {
    if (!mediaList) {
      return
    }
    const copyMediaList = JSON.parse(JSON.stringify(mediaList))
    const filterMediaList = copyMediaList.filter((item: any) => !!isImageUrl(item.url))
    if (!filterMediaList) {
      return
    }
    if (filterMediaList && filterMediaList.length === 0) {
      setIsCroppingImage(false)
      return
    }
    if (!!isImageUrl(filterMediaList[0].url) && !coverUrlFile?.imgUrl && !isCroppingImage) {
      setOriginCoverImage(filterMediaList[0].url)
      loadAndCropImage(filterMediaList[0].url).then((res: any) => {
        setCoverUrlFile({
          file: undefined,
          imgUrl: res,
        });
        form.setFieldValue("coverUrl", { file: "", imgUrl: res })
        setIsCroppingImage(true)
      })
    }
    if (!!isImageUrl(filterMediaList[0].url) && !coverMobileUrlFile?.imgUrl && !isCroppingImage) {
      setOriginCoverImage(filterMediaList[0].url)
      loadAndCropImage(filterMediaList[0].url, 300 / 400, "mobile").then((res: any) => {
        setCoverMobileUrlFile({
          file: undefined,
          imgUrl: res,
        });
        form.setFieldValue("coverMobileUrl", { file: "", imgUrl: res })
        setIsCroppingImage(true)
      })
    }
  }, [mediaList, isCroppingImage, coverUrlFile, coverMobileUrlFile])

  const initFormData = () => {
    if (!id) {
      form.setFieldsValue({
        title: "",
        tagList: [],
        categoryIds: [],
        coverUrl: {
          file: undefined,
          imgUrl: "",
        },
        description: "",
        mediaCategory: "",
        mediaList: [],
        hardwareList: [],
        softwareList: [],
      });
    } else {
      apiGetWorkDraftDetail(Number(id)).then((res) => {
        setDetail(res)
        setIsCroppingImage(true)
        setIsSelectRecruit(!!res.isRecruit)
        setCoverUrlFile({
          file: undefined,
          imgUrl: res.coverUrl,
        });
        setCoverMobileUrlFile({
          file: undefined,
          imgUrl: res.coverMobileUrl || res.coverUrl,
        });
        setIsPublic(res.isPublic);
        setFormValues({
          title: res.title,
          tagList: res.tagList?.map((item) => item.name),
          categoryIds: res.categoryList?.map((item) => {
            return item.id;
          }),
          mediaCategory: String(res.mediaCategory),
          coverUrl: {
            file: undefined,
            imgUrl: res.coverUrl,
          },
          coverMobileUrl: {
            file: undefined,
            imgUrl: res.coverMobileUrl,
          },
          description: res.description,
          mediaList: res.mediaList?.map((item) => {
            return {
              id: item.id,
              url: item.url,
              key: item.uuid,
              percent: 100,
              coverUrl: item.coverUrl,
              type: item.type,
              caption: item.caption,
              params: {
                hdrImageUrl: item.params?.hdrImageUrl,
                threeDBgUrl: item.params?.threeDBgUrl,
                specialEffectTemplateId: item.params?.specialEffectTemplateId,
                specialEffectVideo: item.params?.specialEffectVideo,
              },
            };
          }),
          hardwareList: res.hardwareList?.map((item) => item.name),
          softwareList: res.softwareList?.map((item) => item.name),
        })
        form.setFieldsValue({
          title: res.title,
          tagList: res.tagList?.map((item) => item.name),
          categoryIds: res.categoryList?.map((item) => {
            return item.id;
          }),
          mediaCategory: String(res.mediaCategory),
          coverUrl: {
            file: undefined,
            imgUrl: res.coverUrl,
          },
          coverMobileUrl: {
            file: undefined,
            imgUrl: res.coverMobileUrl,
          },
          description: res.description,
          mediaList: res.mediaList?.map((item) => {
            return {
              id: item.id,
              url: item.url,
              key: item.uuid,
              percent: 100,
              coverUrl: item.coverUrl,
              type: item.type,
              caption: item.caption,
              params: {
                hdrImageUrl: item.params?.hdrImageUrl,
                threeDBgUrl: item.params?.threeDBgUrl,
                specialEffectTemplateId: item.params?.specialEffectTemplateId,
                specialEffectVideo: item.params?.specialEffectVideo,
              },
            };
          }),
          hardwareList: res.hardwareList?.map((item) => item.name),
          softwareList: res.softwareList?.map((item) => item.name),
        });
      });
    }
  };

  useEffect(() => {
    getMediaCategoryOptions();
  }, []);

  useEffect(() => {
    let obj = mediaCategoryOptions?.find(
      (category) => category.value === mediaCategory
    );
    if (!!obj) {
      if (Number(obj.value) === 10) {
        setIsGameDesigner(Number(obj.value) === 10)
        const des = `输入你的邀约需求：例如
我们正在组队参加2025CUSGA，现招募：游戏美术：负责角色、场景、UI设计，熟练使用PS、Blender等工具。
游戏编程：熟悉Unity/Unreal，负责游戏逻辑与开发。
参赛地点：上海
自由创作空间、团队合作与成长机会、比赛荣誉与行业曝光
一起打造独特游戏，留下我们的名字！`
        setPlaceholder(des)
      } else {
        setIsGameDesigner(false)
        setPlaceholder("在这里介绍你的作品，分享你的故事")
      }
    }
  }, [mediaCategory, initTitle, initDescription, mediaCategoryOptions]);

  const getMediaCategoryOptions = () => {
    apiGetDictList({ type: "mediaCategory" }).then((res) => {
      const options = res.map((item) => {
        return { value: String(item.code), label: item.name };
      });
      setMediaCategoryOptions(options);
      if (!id) {
        form.setFieldValue("mediaCategory", String(res[0].code))
      }
    });
  };

  const onClickSave = (submit: number, status?: string) => {
    if (!coverUrlFile?.imgUrl) {
      message.warning("请上传电脑端封面！");
      return;
    }

    if (loading || coverUrlFile?.imgUrl.includes("data:image") || (!!coverMobileUrlFile?.imgUrl && coverMobileUrlFile?.imgUrl.includes("data:image"))) {
      message.error("有文件正在上传中,请稍后重试！");
      return;
    }

    if (id) {
      form.validateFields().then(async (values) => {
        let {
          title,
          tagList,
          categoryIds,
          description,
          mediaList,
          hardwareList,
          softwareList,
          mediaCategory,
        } = values;
        const isShowUploadSuccess = mediaList.every(item => (!!item.url && item.percent === 100))
        if (!isShowUploadSuccess) {
          message.warning("还有媒体正在上传中，请稍后再试")
          return
        }
        let mediaListParams = mediaList?.map((item) => {
          return item.id;
        });

        let params = {
          id: Number(id),
          coverUrl: coverUrlFile?.imgUrl!,
          coverMobileUrl: coverMobileUrlFile?.imgUrl! || coverUrlFile?.imgUrl!,
          tagList: tagList,
          softwareList: softwareList,
          mediaList: mediaListParams, //展示媒体列表
          description: description, //描述
          title: title, //标题
          categoryId: categoryIds[1], // 类目ID
          hardwareList: hardwareList, // 软硬件标签
          isPublic: isPublic,
          submit: submit,
          mediaCategory: Number(mediaCategory),
          creators: creatorList,
          isRecruit: !isGameDesigner ? 0 : isSelectRecruit ? 1 : 0
        };

        await updateWorkDraft(params);
        if (status === "pre") {
          window.open(`/work/preview/${id}`);
          return;
        }

        if (submit === 1 && isPublic === 1) {
          navigate(`/work/promote-to-sell/${id}`);
        } else {
          navigate("/user-center/home/work/draft/list");
        }

        message.success("操作成功！");
      });
    } else {
      form.validateFields().then(async (values) => {
        let {
          title,
          tagList,
          categoryIds,
          description,
          mediaList,
          hardwareList,
          softwareList,
          mediaCategory,
        } = values;
        const isShowUploadSuccess = mediaList.every(item => (!!item.url && item.percent === 100))
        if (!isShowUploadSuccess) {
          message.warning("还有媒体正在上传中，请稍后再试")
          return
        }
        let mediaListParams = mediaList?.map((item) => {
          return item.id;
        });

        let params = {
          coverUrl: coverUrlFile?.imgUrl!,
          coverMobileUrl: coverMobileUrlFile?.imgUrl! || coverUrlFile?.imgUrl!,
          tagList: tagList,
          softwareList: softwareList?.map((item) => item)!,
          mediaList: mediaListParams, //展示媒体列表
          description: description, //描述
          title: title, //标题
          categoryId: categoryIds[1], // 类目ID
          hardwareList: hardwareList?.map((item) => item)!, // 软硬件标签
          isPublic: isPublic,
          submit: submit,
          mediaCategory: Number(mediaCategory),
          copyContestWork: copyContestWork,
          creators: creatorList,
          isRecruit: !isGameDesigner ? 0 : isSelectRecruit ? 1 : 0
        };
        let draft = await createWorkDraft(params);
        if (status === "pre") {
          navigate(`/work/update/${draft.id}`, { replace: true });
          window.open(`/work/preview/${draft.id}`);
          return;
        }
        if (submit === 0) {
          navigate("/user-center/home/work/draft/list");
          message.success("创建草稿成功！");
          return
        }

        if (submit === 1 && isPublic === 1) {
          // 同步约稿
          if (!!isAgreeYuegaoAgreement) {
            let result
            if (Number(mediaCategory) === 2) {
              result = productWorkTypeList.find(item => item.categoryId === params.categoryId && item.isMediaCategory === 1)
            }
            if (!result) {
              result = productWorkTypeList.find(item => item.categoryId === params.categoryId)
            }
            const categoryResult = await apiGetMallProductListCategory({ code: "yuegao-1", level: 2 })
            if (!!result) {
              const yuegaoProductParams = {
                name: params.title,
                coverUrl: params.coverUrl,
                imageList: mediaList.slice(0, 15).map(item => item.url),
                submit: 1,
                type: 3,
                id: 0,
                description: params.description || params.title,
                manuscriptParams: {
                  workType: result.workType,
                  themeStyle: "",
                  goodAtSoftware: ""
                },
                workTypeList: result.workTypeList,
                categoryId: categoryResult[0].id,
                workDraftId: draft.id,
                skuList: [{
                  coverUrl: params.coverUrl,
                  id: 0,
                  name: params.title,
                  originalPrice: 0,
                  quantity: 9999,
                  rmbPrice: 0,
                  isManuscript: 1,
                  isPrivateInquiry: 1,
                  authorizationMark: 0,
                  attachmentList: [],
                  fileFormat: ""
                }]
              }
              apiPostCreateCommdity(yuegaoProductParams).then(async () => {
                if (!!localUser.shopId) {
                  const userInfo = await apiGetUserBaseInfo()
                  localStorage.setItem("user_info", JSON.stringify(userInfo));
                }
              })
            }
          }

          if (type === 'addPicture') {
            // 跟一张图发布后发送一条评论
            const copyMediaList = JSON.parse(JSON.stringify(mediaList))
            // 过滤掉视频
            const filterMediaList: { url: string, id: number }[] = copyMediaList.filter((item: any) => !!isImageUrl(item.url))
            if (filterMediaList.length > 0) {
              const params = {
                content: title + `[img]${filterMediaList.slice(0, 3).map(item => item.url).join(",")}[/img]`,
                dataId: Number(workId),
                dataTable: "work",
                draftId: draft.id
              }
              await postCreateComment(params)
            }
          }
        }
        navigate(`/work/promote-to-sell/${draft.id}`);
      });
    }
  };

  const upload = async (params: { imgUrl: string; file: File | undefined }) => {
    if (params.file) {
      setLoading(true);
      let urlKey = getFileName(CosDirectoryType.workCover, params.file.name);
      let res = await getFileUrlByCosUpload(params.file!, urlKey);
      setCoverUrlFile({
        file: undefined,
        imgUrl: res.url,
      });
      setLoading(false);
    }
  };

  const uploadMobileImage = async (params: { imgUrl: string; file: File | undefined }) => {
    if (params.file) {
      setLoading(true);
      let urlKey = getFileName(CosDirectoryType.workMobileCover, params.file.name);
      let res = await getFileUrlByCosUpload(params.file!, urlKey);
      setCoverMobileUrlFile({
        file: undefined,
        imgUrl: res.url,
      });
      setLoading(false);
    }
  };

  const onChangeIsPublic = (val: RadioChangeEvent) => {
    setIsPublic(val.target.value);
  };

  const onCopyWorkCheckBoxChange = (flag: number) => {
    setCopyContestWork(flag);
  };

  const canDisplayAutoPublish = () => {
    let obj = mediaCategoryOptions?.find(
      (category) => category.value === mediaCategory
    );
    let flag = false;
    if (obj && ["3D模型", "2D原画", "ANI动画"].includes(obj.label)) {
      flag = true;
    }
    return !id && Boolean(isPublic) && flag;
  };
  return (
    <WorkUpdateStyle>
      <div className="main-container">
        <div className="left">
          <Form
            name="basic"
            // layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            requiredMark={true}
            className="form-area"
            size="large"
            form={form}
            onValuesChange={(changedValues, allValues) => setFormValues(allValues)}
          >
            <div className="form-section">
              <Form.Item
                label={<div>上传作品<span style={{ color: "rgba(0,0,0,.5)", marginLeft: "15px" }}>您上传第一张图片默认生成为封面哦</span></div>}
                name="mediaList"
                rules={[{ required: true, message: "作品媒体为必填项" }]}
                layout="vertical"
                className="upload-form"
              >
                <ComboUpload></ComboUpload>
              </Form.Item>

            </div>
            <div className="form-section">
              <div className="title">作品正文</div>
              <Form.Item
                name="title"
                rules={[{ required: true, message: "" }]}
              >
                <Input placeholder="*请输入标题" count={{ show: true, max: 20 }} maxLength={20} />
              </Form.Item>
              <Form.Item label="" name="description">
                <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} count={{ show: true, max: 1000 }} placeholder={placeholder} maxLength={1000} />
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.categoryIds !== currentValues.categoryIds
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("categoryIds") ? (
                    <div>
                      {/* <div className="title">热门标签</div> */}
                      <Form.Item name="tagList" className="tag">
                        <TagSelect
                          dataTable="work"
                          categoryId={form.getFieldValue("categoryIds")[1]}
                          maxCount={5}
                        ></TagSelect>
                      </Form.Item>
                    </div>
                  ) : null
                }
              </Form.Item>
              <Form.Item
                label="创作类型"
                name="mediaCategory"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  defaultActiveFirstOption
                  options={mediaCategoryOptions}
                ></Select>
              </Form.Item>
              <Form.Item
                label="内容分类"
                name="categoryIds"
                rules={[{ required: true, message: "类目为必填项" }]}
              >
                <SecondSelect categoryList={categoryList} type="work"></SecondSelect>
              </Form.Item>

              <Form.Item>
                <div className="head-wrap">
                  <div className="header">
                    <span>共同创作</span>{" "}
                    <Tooltip title="添加除本人外的共同创作人或导师，最多可添加5人">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </div>
                  <Collabration onListChange={(list) => setCreatorList(list)} creators={detail?.creators}></Collabration>
                  {
                    !!isGameDesigner && <div className="recruit">
                      <Button type="primary">
                        <Checkbox checked={isSelectRecruit} onChange={(e) => setIsSelectRecruit(e.target.checked)}>招募共创成员</Checkbox>
                        <Tooltip title="招募申请可以在个人中心-我的邀约中查看">
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </Button>
                    </div>
                  }
                </div>
              </Form.Item>
              <Form.Item label="软件工具" name="softwareList">
                <CreationWare type={1} selectMode="multiple"></CreationWare>
              </Form.Item>
              <Form.Item label="硬件工具" name="hardwareList">
                <CreationWare type={2} selectMode="multiple"></CreationWare>
              </Form.Item>
              <Form.Item label="发布设置" style={{ marginBottom: 0 }}>
                {!!(isShowPrivate === 1) && (
                  <Radio.Group
                    onChange={onChangeIsPublic}
                    value={isPublic}
                    // optionType="button"
                    buttonStyle="solid"
                  >
                    <Radio value={1}>公开</Radio>
                    <Radio value={0}>私密</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </div>
            <div className="fixed">
              <Space className="block-btn">
                <Button onClick={() => onClickSave(0, "pre")} block>
                  {" "}
                  预览
                </Button>
                <Button onClick={() => onClickSave(0)} block>
                  保存草稿
                </Button>
                <Button type="primary" className="primary" onClick={() => onClickSave(1)} block>
                  立即发布
                </Button>
                {
                  !id && isShowAgreeYuegaoAgreement && <div className="agree-tip">
                    <Checkbox checked={isAgreeYuegaoAgreement} onChange={(e) => setIsAgreeYuegaoAgreement(e.target.checked)}>
                      <span style={{ fontWeight: "bold" }}>同步约稿</span> <span className="red">创作变现很重要!</span><div></div>
                    </Checkbox>
                    <div className="agree">勾选即默认同意<span onClick={() => { setIsShowShopYuegaoAgreement(true) }}>《约稿商品出售协议》</span></div>
                  </div>
                }
              </Space>
            </div>
          </Form>
        </div>
        <div className="right">
          <div className="card">
            <div className="header">
              <div>
                <span style={{ color: "red", marginRight: "5px" }}>*</span>
                封面预览
              </div>
            </div>
            <div className="image-crop-wrapper">
              {
                isSelectPcCover && <Spin spinning={loading}>
                  <ImagePreviewPc value={coverUrlFile}
                    onChange={(params: {
                      imgUrl: string;
                      file: File | undefined;
                    }) => {
                      setCoverUrlFile({ ...params });
                    }}
                    mediaCategoryOptions={mediaCategoryOptions || []}
                    formValues={formValues}
                    categoryList={categoryList}
                    originCoverImage={originCoverImage}
                    uploadImage={upload} />
                </Spin>
              }
              {
                !isSelectPcCover && <Spin spinning={loading}>
                  <ImagePreviewMobile
                    value={coverMobileUrlFile}
                    onChange={(params: {
                      imgUrl: string;
                      file: File | undefined;
                    }) => {
                      setCoverMobileUrlFile({ ...params });
                    }}
                    uploadImage={uploadMobileImage}
                    aspect={300 / 400}
                  />
                </Spin>
              }
            </div>

            <div className="tab-wrap">
              <Radio.Group
                onChange={onChangeIsPcCover}
                value={isSelectPcCover}
                optionType="button"
                buttonStyle="solid"
              >
                <Radio value={true}>电脑端</Radio>
                <Radio value={false}>移动端</Radio>
              </Radio.Group>
            </div>

          </div>

          <Affix offsetTop={top}>
            <div className="card">
              {canDisplayAutoPublish() && (
                <div className="card">
                  <AutoPublish
                    onCheckBoxChange={onCopyWorkCheckBoxChange}
                  ></AutoPublish>
                </div>
              )}
            </div>
          </Affix>

        </div>
      </div>
      <Modal width={700} footer={[<Button type="primary" onClick={() => setIsShowShopYuegaoAgreement(false)}>确定</Button>]} title="约稿商品出售协议" destroyOnClose={true} closable={false} centered={true} open={isShowShopYuegaoAgreement}>
        <ShopYuegaoAgreement />
      </Modal>
    </WorkUpdateStyle>
  );
}

export default WorkUpdate;
