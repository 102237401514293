import { GetMenuRequest, businessContactRequest, MenuItem, SendVerifyCodeRequest, addressItem, countryItem, countryRequest, DictItem, GetDictRequest, chineseAddressItem, getCaptchaType, globalSearchRequest, GlobalSearchPostResponse, SendVerifyLoginCodeRequest, WebSiteInfoResponse, CfgExpressCodeResponse, IdCardResponse, CfgCopyContestWorkSettingResponse, TagHotListRequest, TagHotListItem, JobInvitationCreateRequest, EmailCaptchaSendVerCodeRequest, CfgCategoryToProductWorktypeResponse, WhiteCooperationInvitationCreateRequest, WhiteCooperationInvitationListRequest, WhiteCooperationInvitationListItem, WhiteCooperationInvitationReplyRequest, ApplyCooperationTeamListItem, ApplyCooperationTeamListRequest, ApplyCooperationTeamReplyRequest, ApplyCooperationTeamCreateRequest } from "types/common";
import { get, post } from "api";
import { PaginationResponse } from "types/common/axios";


/** 短信-发送验证码 */
export const apiSendVerifyCode = async (params: SendVerifyCodeRequest) =>
    await get<SendVerifyCodeRequest, any>('/api/sms/send_ver_code', params)

/** 短信登录-发送验证码 */
export const apiLoginSendVerifyCode = async (params: SendVerifyLoginCodeRequest) =>
    await get<SendVerifyLoginCodeRequest, any>('/api/sms/captcha_send_ver_code', params)

/** 邮箱-发送验证码 */
export const apiSendEmail = async (email: string) =>
    await get<{ email: string }, any>('/api/email/send_ver_code', { email })

/** 邮箱-解绑 */
export const postEmailUnbind = async () =>
    await post<{}, any>('/api/email/unbind')

/** 邮箱登录-发送验证码 */
export const apiLoginSendEmail = async (params: EmailCaptchaSendVerCodeRequest) =>
    await get<EmailCaptchaSendVerCodeRequest, any>('/api/email/captcha_send_ver_code', params)


/** 网站菜单读取 */

export const apiGetMenus = async (params: GetMenuRequest) =>
    await get<GetMenuRequest, MenuItem[]>('/api/menu/list', params)

// 获取国家省市区列表 上级下级

export const apiGetCountryList = async (params: countryRequest) =>
    await get<{}, countryItem[]>('/api/cfg/region_list', params)

// 获取省市区列表 
export const apiGetAddressList = async () =>
    await get<{}, addressItem[]>('/api/cfg/dci_place')

// 获取中国所有省市区列表
export const apiGetChineseAddressList = async (parmas?: { types: string }) =>
    await get<{ types: string }, chineseAddressItem[]>('/api/cfg/china_region_list', parmas)

// 配置-作品内容分类对应商品作品类型
export const apiGetCategoryToProductWorktype = async () =>
    await get<{}, CfgCategoryToProductWorktypeResponse[]>('/api/cfg/category_to_product_worktype')

//配置-一键复制比赛作品
export const apiAutoCopyWorksCfg = async () =>
    await get<{}, CfgCopyContestWorkSettingResponse>('/api/cfg/copy_contest_work_setting')

// 获取职业列表
export const apiGetDictList = async (params: GetDictRequest) =>
    await get<GetDictRequest, DictItem[]>('/api/dict/list', params)

// 获取图形验证码
export const apiGetCaptcha = () =>
    get<{}, getCaptchaType>("/api/captcha/get")

// 提交商务合作
export const postBusinessContact = (params: businessContactRequest) =>
    post<businessContactRequest, {}>("/api/biz_contact/submit", params)

// 提交商务合作
export const postJobBusinessContact = (params: JobInvitationCreateRequest) =>
    post<JobInvitationCreateRequest, {}>("/api/job_invitation/create", params)

// 白名单合作邀约-创建
export const postCreateWhiteCooperationInvitation = (params: WhiteCooperationInvitationCreateRequest) =>
    post<WhiteCooperationInvitationCreateRequest, {}>("/api/white_cooperation_invitation/create", params)

// 白名单合作邀约-列表
export const getWhiteCooperationInvitationList = (params: WhiteCooperationInvitationListRequest) =>
    get<WhiteCooperationInvitationListRequest, PaginationResponse<WhiteCooperationInvitationListItem>>("/api/white_cooperation_invitation/list", params)

// 白名单合作邀约-回复
export const postWhiteCooperationInvitationReply = (params: WhiteCooperationInvitationReplyRequest) =>
    post<WhiteCooperationInvitationReplyRequest, {}>("/api/white_cooperation_invitation/reply", params)

// 申请合作组队-列表
export const getApplyCooperationTeamList = (params: ApplyCooperationTeamListRequest) =>
    get<ApplyCooperationTeamListRequest, PaginationResponse<ApplyCooperationTeamListItem>>("/api/apply_cooperation_team/list", params)

// 申请合作组队-回复
export const postApplyCooperationTeamReply= (params: ApplyCooperationTeamReplyRequest) =>
    post<ApplyCooperationTeamReplyRequest, {}>("/api/apply_cooperation_team/reply", params)

// 申请合作组队-创建
export const postApplyCooperationTeamCreate= (params: ApplyCooperationTeamCreateRequest) =>
    post<ApplyCooperationTeamCreateRequest, {}>("/api/apply_cooperation_team/create", params)

// 全文检索-帖子
export const postGlobalSearch = (params: globalSearchRequest) =>
    get<globalSearchRequest, PaginationResponse<GlobalSearchPostResponse>>("/api/global_search/post", params)




// 获取网站配置
export const apiGetWebsiteInfo = () =>
    get<{}, WebSiteInfoResponse>("/api/cfg/website_info")

// 获取快递编码
export const apiGetExpressCodeList = () =>
    get<{}, CfgExpressCodeResponse[]>("/api/cfg/express_code")

// 识别身份证
export const apiGetIdCard = (params: { cardSide: string, imageUrl: string }) =>
    get<{ cardSide: string, imageUrl: string }, IdCardResponse>("/api/ocr/id_card", params)


//全局热门标签
export const apiGetGlobalHotTagRank = (params: TagHotListRequest) => {
    return get<TagHotListRequest, TagHotListItem[]>("/api/tag/hot_list", params)
}