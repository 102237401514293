import styled from "styled-components";

export const ImagePreviewMobileStyle = styled.div`
    /* border: 1px solid #d9d9d9; */
    
    .cover {
        /* aspect-ratio: 300 / 400; */
        border-radius: 6px;
        width: 276.02px;
        height: 326.09px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #d9d9d9;
        img {
            object-fit: contain;
            max-height: 100%;
            max-width: 100%;
        }
        &:hover {
            .btn-wrap {
                display: block;
                cursor: pointer;
            }
        }
        .btn-wrap {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: none;
            button {
                font-size: 14px !important;
            }
        }
    }
    .detail {
            padding: 15px;
            .title {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 5px;
            }
            .category {
                color: rgba(0, 0, 0, 0.45);
                display: flex;
                gap: 5px;
                align-items: center;
                margin-bottom: 5px;
                font-size: 12px;
            }
            .user-info {
                display: flex;
                gap: 10px;
            }
            .left {
                display: flex;
                gap: 8px;
            }
            .user-right {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 5px;
                &-item {
                    color: rgba(0, 0, 0, 0.45);
                    display: flex;
                    align-items: center;
                    gap: 3px;
                }
            }
            .user-cover {
                width: 20px;
                height: 20px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;
                border-radius: 50%;
                position: relative;
                z-index: 2;
            }
            .username {
                font-size: 12px;
                position: relative;
                z-index: 2;
                color: rgb(138, 138, 139);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 115px;
            }
    }
    .word {
        text-align: center;
        margin-top: 10px;
    }
    .ant-upload {
        height: max-content !important;
        width: 100% !important;
        border:none !important;
        background-color: transparent !important;
    }
    .ant-upload-list-item-container {
        display: none !important;
    }
    button {
        display: block;
        margin: 10px auto;
    }
`