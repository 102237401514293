import { useEffect, useState } from 'react'
import { CommentItemStyle } from './style'
import { CommentListItem } from 'types/comment'
import { Avatar, message } from "antd";
import SecondReply from '../second-reply'
import { postWorkLikes } from "api/work";
import { Dropdown } from 'antd';
import { MenuProps, Image } from 'antd';
import { postDeleteComment, postDeleteCommentUpgrade } from 'api/work/comment';
import { Modal } from 'antd';
import { ReportModal } from 'components/report';

import {
    RiThumbUpFill, RiMoreFill
} from "react-icons/ri";
import { UserBaseInfo } from 'types/user';

let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)

type CommentItemType = {
    params: {
        detail: CommentListItem,
        pid: number,
        addSubComment: (detail: CommentListItem, pid: number) => void
        deleteComment: (commentId: number, pid: number) => void,
        type: string,
        likeComment: (commentId: number, pid: number) => void,
        word?: string,
        dataId?: number
        anonymity?: boolean
        canDeleteComment?: boolean
    }
}


const CommentItem = (props: CommentItemType) => {
    const { detail, pid, addSubComment, deleteComment, type, likeComment } = props.params
    const [showReport, setShowReport] = useState(false) // 举报弹窗
    const [subCommentId, setSubCommentId] = useState<number>(0)
    const [uploadImageList, setUploadImageList] = useState<string[]>([])
    const [imageOffset, setImageOffset] = useState(0)
    const checkComment = () => {
        if (subCommentId === detail.id) {
            setSubCommentId(0)
        } else {
            setSubCommentId(detail.id)
        }
    }
    const isClicked = detail.id === subCommentId

    const close = () => {
        setSubCommentId(0)
    }

    useEffect(() => {
        const imageRegex = /\[img\](.*?)\[\/img\]/g;
        // 使用正则表达式匹配图片链接
        detail.content.replace(imageRegex, (match, imageUrl, offset) => {
            if (!!imageUrl) {
                setUploadImageList(imageUrl.split(","))
                setImageOffset(offset)
            }
            return match;
        })
    }, [detail])

    // 删除评论
    const deletes = () => {
        console.log(props.params.detail)
        const level = props.params.detail.level
        Modal.confirm({
            title: '确定删除？',
            content: level === 1 ? `删除该条${props.params.word ? props.params.word : '评论'}后，其回复内容也会删除，将无法找回` : `删除该条${props.params.word ? props.params.word : '评论'}后，将无法找回`,
            okText: "确定",
            cancelText: "取消",
            onCancel() { },
            centered: true,
            onOk() {
                const params = {
                    id: detail.id,
                    dataTable: props.params.type,
                    dataId: Number(props.params.dataId)
                }
                postDeleteCommentUpgrade(params).then(() => {
                    message.success('删除成功')
                    deleteComment(detail.id, pid)
                })
            },
        });
    }

    // 举报评论
    const reportComment = () => {
        setShowReport(true)
    }

    const onCloseReport = () => {
        setShowReport(false)
    }

    const items: MenuProps['items'] = [

        {
            key: '2',
            label: (
                <span onClick={() => reportComment()}>举报</span>
            ),
        },
    ];
    if (detail.isDataCreator || userInfo?.permissions.find(item => item === 'commentDelete') || !!props.params?.canDeleteComment) {
        items.unshift(
            {
                key: '1',
                label: (
                    <span onClick={() => deletes()}>删除</span>
                ),
            }
        )
    }
    // 点赞评论
    const like = async () => {
        postWorkLikes({
            dataId: detail.id,
            dataTable: 'comment',
            flag: detail.isZan === 1 ? 0 : 1
        }).then(() => {
            likeComment(detail.id, pid)
        })
    }
    const sendSubComment = (res: CommentListItem) => {
        addSubComment(res, pid)
    }
    return <CommentItemStyle>
        <div className="avatar">
            {
                !!detail.isAnonymity && <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", width: "30px", height: "30px", color: "rgb(54, 54, 54)", borderRadius: "50%", justifyContent: "center", alignItems: "center", backgroundColor: "rgb(243, 243, 243)", overflow: "hidden" }}>
                        {/* <RiBearSmileLine size={22}></RiBearSmileLine> */}
                        <img src="https://cdn.ggac.com/media/images/user_avatar.jpg" alt="" width={30} height={30} />
                    </div>
                </div>
            }

            {
                !detail.isAnonymity && <a href={`/user/${detail.userInfo.id}/works`} target='_blank'>
                    <Avatar
                        src={detail.userInfo.avatarUrl}
                    />
                </a>
            }

        </div>
        <div className="commentBody">
            {
                (detail.userId === 0 || detail.isAnonymity === 1) && <div className="userName">匿名</div>
            }
            {
                !!detail.userId && detail.isAnonymity === 0 && <div className="userName" onClick={() => window.open(`/user/${detail.userInfo.id}/works`, '_blank')}>{detail.userInfo.username}</div>
            }
            <p className="comment-content">
                {
                    (!!detail.replyUserInfo?.username) && detail.level === 2 && <span className='content-reply'>回复{detail.replyUserInfo.username}：</span>
                }
                {
                    detail.replyUserId === 0 && detail.isAnonymityReply === 1 && detail.level === 2 && <span className='content-reply'>回复匿名：</span>
                }
                <span className='text' dangerouslySetInnerHTML={{ __html: imageOffset ? detail.content.slice(0, imageOffset) : detail.content }}></span>
            </p>
            {
                !!uploadImageList && uploadImageList.length > 0 && <div className="image-wrap">
                    {
                        uploadImageList.map(item => {
                            return <Image key={item} src={item} />
                        })
                    }
                </div>
            }
            {
                !!detail.twinId && <a href={`/work/detail/${detail.twinId}`} className="link" target="_blank">查看跟一张作品详情</a>
            }
            <div className="commentMeta">
                <span className="commentDate">{detail.beautifyTime}</span>
                <div className={`${isClicked ? 'active' : ''} commentActions`} onClick={() => checkComment()}>
                    {
                        detail.level === 1 && <div style={{ display: "flex", alignItems: "center" }} >
                            {/* <RiMessage3Fill className="icon" /> */}
                            {isClicked && <span>关闭回复</span>}
                            {!isClicked && <span>回复 {detail.commentCount || 0}</span>}
                        </div>
                    }
                    {
                        detail.level === 2 && <div style={{ display: "flex", alignItems: "center" }} >
                            {isClicked && <span>关闭回复</span>}
                            {!isClicked && <span>回复</span>}
                        </div>
                    }
                </div>
                <div className={`${detail.isZan === 1 ? 'favor-active' : ''} commentActions`} onClick={() => like()}>
                    <RiThumbUpFill className='icon' />
                    <span>{detail.zanCount || 0}</span>
                </div>
                <Dropdown menu={{ items }} placement="bottom" arrow>
                    <div className="commentActions">
                        <RiMoreFill className="icon" />
                    </div>
                </Dropdown>
            </div>
            <SecondReply params={{ dataId: props.params.dataId, anonymity: props.params.anonymity, visible: isClicked, word: props.params.word, detail: detail, sendSubComment, close, type, pid }} />
        </div>
        {showReport && (
            <ReportModal
                show={showReport}
                close={onCloseReport}
                params={{ dataId: Number(detail?.id) || Number(props.params.dataId), dataTable: "comment" }}
            ></ReportModal>
        )}
    </CommentItemStyle>
}

export default CommentItem