import { useEffect, useState } from "react"
import { InitHomeStyle } from "./style"
import { Modal } from "antd";
import { getCarouselList, sendCarouselLog } from "api/common/carousel";
import { CarouselListItem, CarouselListRequest } from "types/common/carousel";
import { RiCloseLine } from "react-icons/ri";

const InitHome = () => {
    const [showModal, setShowModal] = useState(false)
    const [currentItem, setCurrentItem] = useState<CarouselListItem>()
    const viewPortWidth = window.document.documentElement.clientWidth
    useEffect(() => {
        const firstVisitHome = localStorage.getItem('FIRST_VISIT_HOME');
        const today = new Date().toDateString();
        // 如果今天还没有弹过窗
        if (firstVisitHome !== today) {
            const params: CarouselListRequest = {
                lang: 1,
                type: 29,
                status: 1,
            };
            getCarouselList(params).then(res => {
                const list = res.pageData
                if (list && list.length > 0) {
                    // 生成一个随机索引
                    const index = Math.floor(Math.random() * list.length);
                    setCurrentItem(list[index])
                    // 如果没有记录过，记录当前时间
                    localStorage.setItem('FIRST_VISIT_HOME', today);
                    // 显示弹窗
                    setShowModal(true);
                }
            })
        }
    }, [])

    const toDetail = () => {
        window.open(currentItem?.webUrl)
        if (!currentItem?.id) {
            return
        }
        sendCarouselLog({ carouselId: currentItem?.id })
        setShowModal(false)
    }

    return <InitHomeStyle viewPortWidth={viewPortWidth}>
        <Modal open={showModal} footer={null} onCancel={() => setShowModal(false)} title="" getContainer={false} centered style={{ width: viewPortWidth >= 1800 ? '900px' : "50vw", borderRadius: "20px" }}
            closeIcon={<div style={{ width: "70%", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(0,0,0,.7)", aspectRatio: 1, borderRadius: "50%" }}><RiCloseLine size={18} /></div>}>
            <div className="cover" onClick={toDetail} style={{ backgroundImage: `url(${currentItem?.displayImage})` }} />
        </Modal>
    </InitHomeStyle>
}

export default InitHome