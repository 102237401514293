import styled from "styled-components";

export const Regtulation2024Style = styled.div`
    @font-face {
        font-family: 'AlibabaPuHuiTiR';
        src: url('https://cdn-prd.ggac.com/ggac/font/AlibabaPuHuiTi.ttf');
    }

    @font-face {
        font-family: 'SourceHanSerifCN';
        src: url('https://cdn-prd.ggac.com/ggac/font/SourceHanSerifCN-Regular.otf');
    }

    @font-face {
      font-family: "zyjx-song";
      src: url("https://cdn-prd.ggac.com/ggac/font/xyjx-song.ttf");
    }
    
    font-family: 'AlibabaPuHuiTiR';

    .en-banner {
      .btn-wrap {
        .btn {
          letter-spacing: 1px !important;
        }
      }
      .popup-item {
        font-size: 0.95vw !important;
      }
    }

    .lang {
      .ant-float-btn-icon {
        display: none;
      }
      .ant-float-btn-description {
        font-size: 16px !important;
        font-weight: 500;
      }
    }

    .banner {
        //赛事kv
        .banner_content {
            width: 100%;
            margin: 0 auto;
            position: relative;
            font-family: 'AlibabaPuHuiTiR';
            z-index: 10;
                
            video::-webkit-media-controls-fullscreen-button {
              display: none;
            }
        
            video::-webkit-media-controls-fullscreen-button {
              display: none;
            }
        
            //播放按钮
            video::-webkit-media-controls-play-button {
              display: none;
            }
        
            //进度条
            video::-webkit-media-controls-timeline {
              display: none;
            }
        
            //观看的当前时间
            video::-webkit-media-controls-current-time-display {
              display: none;
            }
        
            //剩余时间
            video::-webkit-media-controls-time-remaining-display {
              display: none;
            }
        
            //音量按钮
            video::-webkit-media-controls-mute-button {
              display: none;
            }
        
            video::-webkit-media-controls-toggle-closed-captions-button {
              display: none;
            }
        
            //音量的控制条
            video::-webkit-media-controls-volume-slider {
              display: none;
            }
        
            //所有控件
            video::-webkit-media-controls-enclosure {
              display: none;
            }
        
            video {
              // height: 925px;
              width: 100%;
              height: 100%;
              bottom: 0;
              left: 0;
              object-fit: cover;
              background: #fff;
              position: absolute;
            }

            .kv-word {
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: -30px;
              width: 677px;
              height: 455px;
            }
        
            .banner_image {
              width: 100%;
              height: 100%;
              object-fit: cover;
              background: #fff;
              position: absolute;
              top: 0;
              left: 0;
            }
        }

        .btn-wrap {
            position: absolute;
            left: 50.3%;
            transform: translateX(-50%);
            bottom: 150px;
            .btn {
                width: 271px;
                height: 73px;
                background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-banner-btn.png") center center no-repeat;
                background-size: 100% 100%;
                margin: 0 auto;
                font-size: 21px;
	              line-height: 73px;
	              letter-spacing: 9px;
	              color: #b2e9ff;
                text-align: center;
                cursor: pointer;
                &:hover {
                  background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-banner-btn-active.png") center center no-repeat;
                  background-size: 100% 100%;
                  color: #00435f;
                }
            }
            .word {
                font-size: 23px;
	              font-weight: normal;
	              font-stretch: normal;
	              letter-spacing: 8px;
	              color: #bfebfe;
                font-family: 'SourceHanSerifCN';
            }
        }

        .tab {
            background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-banner-tab-line2.png") center center no-repeat;
            background-size: 100% 100%;
            height: 84px;
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: -1px;
            position: relative;
            z-index: 11;
            padding: 7px 0;
            &-item:hover,.active {
                background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-banner-tab-active.png") center center no-repeat;
                background-size: cover; 
                background-position: center;
                color: #345770 !important;
            }
            
            &-item {
                width: 9.15vw;
	              height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.15vw;
	              color: #b3d5ff;
                cursor: pointer;
                position: relative;
                &:last-child::after {
                  width: 0;
                }
                &::after{
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 3px;
                    height: 27px;
                    background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-banner-tab-item.png") center center no-repeat;
                    background-size: 100% 100%;
                }

                &:hover {
                  .popup {
                    display: block;
                  }
                }

                .popup {
                  position: absolute;
                  width: 100%;
                  background-color: #fff;
                  top: 70px;
                  left: 0;
                  z-index: 11;
                  display: none;
                  &-item{
                    width: 100%;
	                  height: 70px;
                    text-align: center;
                    font-size: 1vw;
                    background-color: #486da7;
                    color: #d4dce5;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-bottom: 1px solid #26415f;

                    &:hover {
                      background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-banner-tab-active.png") center center no-repeat;
                      background-size: 100% 100%; 
                      color: #00435f !important;
                      background-color: #4566a1;
                    }
                  }
                }
            }
        }

        
    }

    .modal {
        .ant-modal-content {
          width: calc(817px / 1.5);
          height: calc(883px / 1.5) ;
          background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-popup-bg.png") center center no-repeat;
          background-size: 100% 100%;
          box-shadow: none;
        }
    }

    .float {
      position: fixed;
      width: 188px;
      height: 270px;
      background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-popup-float.png") center center no-repeat;
      background-size: 100% 100%;
      right: 0;
      bottom: 200px;
      z-index: 15;
      .float-btn {
        position: absolute;
        bottom: 38px;
        font-family: 'REEJI';
        font-size: 12px;
	      letter-spacing: 1px;
	      color: #ffffff;
        width: 94px;
        height: 31px;
        background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-popup-float-btn.png") center center no-repeat;
        background-size: 100% 100%;
        left: 35px;
        cursor: pointer;
        div {
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, #F0F6FF 0%, #76BAFF 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
`