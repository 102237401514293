import { myWorkListItem } from "types/work";
import { PublishWorkCardStatusStyle } from "./style";
import { Popover } from "antd";
import { RiQuestionFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

function PublishWorkCardStatus({ item }: { item: myWorkListItem }) {
  const navigate = useNavigate();
  const toDraft = () => {
    navigate("/user-center/home/work/draft/list");
  };
  const popoverContent = (item: myWorkListItem) => {
    return (
      <div>
        <p
          onClick={toDraft}
          style={{
            cursor: "pointer",
            color: "rgb(244, 170, 47)",
            textDecoration: "underline",
          }}
        >
          该作品有一个副本
          {item.status === 1
            ? "正在审核中"
            : item.status === 3
              ? "已经被驳回"
              : ""}
        </p>
        <p>在线上存在的作品不受影响</p>
      </div>
    );
  };

  const popoverOffSaleReasonContent = (item: myWorkListItem) => {
    return (
      <div>
        <span style={{ color: "rgb(244, 170, 47)" }}>下架原因</span>
        <p>{item.offSaleReason}</p>
      </div>
    );
  };

  return (
    <PublishWorkCardStatusStyle>
            {item.creatorCount >0 && (
            <div className="subtitle">共创</div>
       )}
      <div className="status-wrap">
        {item.isPublic === 0 && <div className="status">私密</div>}
    
        {item.isContest === 1 && item.publishType === 1  && item.mediaCategory !== 10  && (
          <div className="status">阶段性作品</div>
        )}
        {/* 下架 */}
        {item.onSale === 0 && (
          <div className="status">
            <span>下架</span>
            {item.offSaleReason && (
              <Popover
                placement="bottom"
                content={() => popoverOffSaleReasonContent(item)}
              >
                <RiQuestionFill className="question"></RiQuestionFill>
              </Popover>
            )}
          </div>
        )}
        {/* 审核或者被驳回 */}
        {item.onSale !== 0 && (item.status === 1 || item.status === 3) && (
          <div className="status refuse">
            <Popover placement="bottom" content={() => popoverContent(item)}>
              <RiQuestionFill className="question"></RiQuestionFill>
            </Popover>
          </div>
        )}
      </div>

    </PublishWorkCardStatusStyle>
  );
}

export default PublishWorkCardStatus;
