import { useEffect, useState } from "react"
import { MessageImageStyle } from "./style"
import { Image } from "antd"

const MessageImage = ({ content }: { content: string }) => {
    const [imageList, setImageList] = useState([])
    useEffect(() => {
        if (!content) {
            return
        }
        const imageRegex = /\[img\](.*?)\[\/img\]/g;
        // 使用正则表达式匹配图片链接
        content.replace(imageRegex, (match, imageUrl, offset) => {
            if (!!imageUrl) {
                console.log(imageUrl)
                setImageList(imageUrl.split(","))
            }
            return match;
        })
    }, [content])

    return <MessageImageStyle>
        <div className="image">
            {
                imageList.length > 0 && imageList.map(item => {
                    return <Image src={item} />
                })
            }
        </div>
    </MessageImageStyle>
}

export default MessageImage