import styled from "styled-components";

export const Ycwl3DetailStyle = styled.div`
@font-face {
  font-family: 'AlibabaPuHuiTiR';
  src: url('https://cdn-prd.ggac.com/ggac/font/AlibabaPuHuiTi.ttf');
}
@font-face {
    font-family: 'BebasNeue';
    src: url('https://cdn-prd.ggac.com/ggac/font/BebasNeue-Regular.otf');
}
@font-face {
  font-family: 'ShuHeiTi';
  src: url('https://cdn.ggac.com/fonts/Alimama_ShuHeiTi_Bold.ttf');
}
.detail {
    margin-top: -84px;
    font-family: 'AlibabaPuHuiTiR';
    .title {
        display: flex;
        justify-content: center;
        align-items: center;
	    letter-spacing: 10px;
	    color: #ffffff;
        font-family: 'ShuHeiTi';
        gap: 30px;
	    color: #ffffff;
        margin-bottom: 30px;
        position: relative;
        .word {
            font-size: 55px;
	        letter-spacing: 12px;
            padding-left: 12px;
            color: #fff;
            position: relative;
            z-index: 11;
        }
    }
    .common-title {
        width: 283px;
	    height: 70px;
        background: url("https://cdn.ggac.com/img/ycwl2-pc-date-title.png") center center no-repeat;
        background-size: cover;
        margin: 120px auto 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 34px;
	    letter-spacing: 3px;
	    color: #ffffff;
        font-family: 'ShuHeiTi';
        font-weight: bold;
    }
    .part1 {
        background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-part1-bg.png") center center no-repeat;
        background-size: 100% 100%;
        padding: 200px 0 400px;
        position: relative;
        z-index: 10;
        .word {
            &-item {
                font-size: 20px;
	            line-height: 42px;
	            color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #91f3fa;
                    color: #000;
                    padding: 0 10px;
                    height: 29px;
                    margin: 0 5px;
                }
            }
        }
        .word2 {
            background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-part1-border1.png") center center no-repeat;
            background-size: 100% 100%;
            width: 847px;
            height: 96px;
            margin: 50px auto 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &-item {
                font-size: 18px;
	            line-height: 26px;
	            color: #ffffff;
            }
        }
        .date {
            &-content {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 118px;
                margin-top: 50px;
                .date-line {
                    display: flex;
                    gap: 104px;
                }
                .date-item {
                    .circle {
                        width: 283px;
	                    height: 70px;
                        background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-date-line-circle.png") center center no-repeat;
                        background-size: cover; 
                        width: 141px;
                        height: 141px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 18px;
	                    line-height: 24px;
	                    letter-spacing: 1px;
	                    color: #ffffff;
                    }
                    position: relative;
                    &:first-child {
                        &::before {
                            content: "";
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: -104px;
                            width: 104px;
	                        height: 2px;
	                        background-color: #e9f8fe;
	                        border-radius: 1px;
	                        opacity: 0.2;
                        }
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: -104px;
                        width: 104px;
	                    height: 2px;
	                    background-color: #e9f8fe;
	                    border-radius: 1px;
	                    opacity: 0.2;
                    }
                    .date-word {
                        font-size: 20px;
	                    line-height: 48px;
	                    color: #ffffff;
                        text-align: center;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 140%;
                        text-align: center;
                    }
                }
            }
            
        }
        .group {
            margin-top: 150px;
            &-item {
                background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-part1-border2.png") center center no-repeat;
                background-size: 100% 100%;
                width: 1200px;
                height: 306px;
                margin: 0 auto;
                margin-bottom: 70px;
                padding-top: 50px;
                &:nth-child(3) {
                    margin-bottom: 0;
                }
                .group-title {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;
                    font-size: 28px;
	                letter-spacing: 3px;
	                color: #ffffff;
                    padding-left: 3px;
                    font-family: 'ShuHeiTi';
                    margin-bottom: 20px;
                    img {
                        width: 116px;
                        height: 16px;
                    }
                }
                .wrap {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 150px;
                }
                .border {
                    background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-part1-border2-item.png") center center no-repeat;
                    background-size: 100% 100%;
                    width: 245px;
                    height: 141px;
                    text-align: center;
                    padding-top: 28px;
                    cursor: pointer;
                    &:hover {
                        background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-part1-border2-item-active.png") center center no-repeat !important;
                        background-size: 100% 100% !important;
                    }
                    div {
                        font-size: 18px;
                        line-height: 25px;
	                    color: #ffffff;
                    }
                    .word {
                        margin-top: -2px;
                    }
                }
            }
        }
        .date-tip {
            color: #fff;
            text-align: center;
            margin-top: 50px;
            font-size: 18px;
        }
    }

    .part2 {
        background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-part2-bg.png") center center no-repeat;
        background-size: 100% 100%;
        padding: 200px 0;
        position: relative;
        z-index: 11;
        margin-top: -285px;
        .prize {
            &-item {
                .common-title {
                    margin-bottom: 50px;
                }
                .prize-title {
                    display: flex;
                    align-items: center;
                    gap: 35px;
                    justify-content: center;
                    font-size: 28px;
	                letter-spacing: 3px;
	                color: #91f3fa;
                    font-family: 'ShuHeiTi';
                    margin-bottom: 40px;
                }
                .third-title {
                    background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-price.png") center center no-repeat;
                    background-size: 100% 100%; 
                    width: 346px;
                    height: 97px;
                    margin: 0 auto 50px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: #ffef99;
                    .price-num {
                        font-size: 22px;
                    }
                    margin-top: 50px;
                }
                .money-wrap {
                    color: #386d9e;
                    font-family: 'BebasNeue';
                    color: #ffef99;
                    line-height: 60px;
                    span:first-child {
                        font-size: 39px;
                    }
                    span {
                        display: inline;
                        font-size: 66px;
	                    letter-spacing: 2px;
                    }
                    span:nth-child(3) {
                        font-size: 34px;
                    }
                }
                .prize-content {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 155px;
                    gap: 40px;
                    &-item {
                        display: flex;
                        gap: 4vw;
                        justify-content: center;
                        .border{
                            background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-part2-border1.png") center center no-repeat;
                            background-size: 100% 100%;
                            width: 17.2vw;
                            aspect-ratio: 300 / 179;
                            padding-top: 2.1vw;
                            color: #ffffff;
                            .name {
                                font-size: 1vw;
                                display: flex;
                                gap: 3px;
                                justify-content: center;
                            }
                            .prize-line {
                                background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-part2-price-line.png") center center no-repeat;
                                background-size: 100% 100%; 
                                width: 8.4vw;
                                aspect-ratio: 161 / 5;
                                margin: 11px auto;
                            }
                            .money-wrap {
                                text-align: center;
                                color: #fff !important;
                                margin-top: -10px;
                                span:first-child {
                                    font-size: 32px;
                                }
                                span {
                                    display: inline;
                                    font-size: 48px;
	                                letter-spacing: 2px;
                                }
                                span:nth-child(3) {
                                    font-size: 32px;
                                }
                            }
                        }

                        .border2 {
                            background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-part2-border3.png") center center no-repeat;
                            background-size: 100% 100%;
                            width: 12.5vw;
                            aspect-ratio:240 / 179;
                        }
                    }
                }
            }
        }
    }

    .part3 {
        background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-part3-bg.png") center center no-repeat;
        background-size: 100% 100%;
        padding: 400px 0 100px;
        position: relative;
        z-index: 10;
        margin-top: -295px;
        .judge-wrap {
            margin-bottom: 150px;
            .title {
                margin-bottom: 0;
            }
            .sub-title {
                font-size: 12px;
	            letter-spacing: 1px;
	            color: #ffffff;
                text-align: center;
                margin-bottom: 50px;
            }
            .judge {
                &-title {
                    display: flex;
                    align-items: center;
                    gap: 35px;
                    justify-content: center;
                    font-size: 28px;
	                letter-spacing: 3px;
	                color: #91f3fa;
                    margin: 60px 0;
                    font-family: 'ShuHeiTi';
                }
                &-item {
                    display: flex;
                    justify-content: center;
                    width: 73vw;
                    margin: 0 auto 30px;
                    min-width: 1200px;
                    .border {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        img {
                            margin-bottom: 5px;
                            width: 9.1vw;
                            min-width: 150px;
                        }
                        .name {
                            font-size: 0.95vw;
	                        line-height: 30px;
	                        color: #ffffff;
                        }
                        .desc {
                            font-size: 13px;
	                        line-height: 20px;
	                        color: #ffffff;
                            margin-top: 5px;
                            div {
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }

        .rule {
            background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-part3-rule1.png") center center no-repeat;
            background-size: 100% 100%;
            width: 1200px;
            margin: 0 auto 200px;
            padding: 70px 110px 70px 95px;
            &-item {
                display: flex;
	            color: #ffffff;
                margin-bottom: 10px;
                gap: 5px;
                &:last-child {
                    margin-bottom: 0;
                }
                div {
                    line-height: 30px;
                    font-size: 16px;
                }
            }
        }

        .rule2 {
            background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-part3-rule2.png") center center no-repeat;
            background-size: 100% 100%;
            width: 1200px;
            margin: 0 auto 200px;
            padding: 50px 100px 50px 85px;
            &-item {
                display: flex;
	            color: #ffffff;
                margin-bottom: 15px;
                gap: 5px;
                &:last-child {
                    margin-bottom: 0;
                }
                .label {
                    color: #66e7ff;
                    width: 80px;
                }
                .value {
                    max-width: calc(100% - 85px);
                }
                div {
                    line-height: 30px;
                    font-size: 16px;
                }
            }
        }
        .bottom {
            text-align: center;
        }
    }
}
`