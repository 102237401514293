import { ReactNode } from "react";
import { UniCardLayoutStyle } from "./style";
import { ImageObjectCloudResize } from "utils/functions/file";
import { RiEyeLine, RiFireLine } from "react-icons/ri";
import { prettifyNumber } from "utils/functions/common";

type UiObject = {
  coverUrl: string;
  title: ReactNode;
  subtitle: ReactNode;
  view?: number;
  hot?: number;
  icons?: ReactNode;
  userName?: string;
  avatarUrl?: string;
  workLink?: string;
  userLink?: string;
  centerNode?: ReactNode;
  leftTopNode?: ReactNode;
  leftBottomNode?: ReactNode;
  rightBottomNode?: ReactNode;
  rightTopNode?: ReactNode;
  date?: string;
  bottomNode?: ReactNode;
  isPublish?: boolean
  expired?: number
};
function UniCardLayout({ item }: { item: UiObject }) {
  // const openUser = (event: any): void => {
  //   event.stopPropagation();
  //   window.open(item.userLink);
  // };
  return (
    <UniCardLayoutStyle>
      <div className="card-container">
        <div
          className="up-part cover"
          style={{
            backgroundImage: `url(${ImageObjectCloudResize(
              item.coverUrl,
              500
            )})`,
          }}
        >{
            (!!item.expired && item.expired === 1) && <div className="status">已失效</div>
          }
          {
            !!item.workLink && <a href={item.workLink} target="_blank" className="click-a-tag" rel="noreferrer"></a>
          }
          {
            !!item.leftTopNode && <div className="left-top">{item.leftTopNode}</div>
          }
          
          <div className="left-bottom">{item.leftBottomNode}</div>
          <div className="right-top">{item.rightTopNode}</div>
          <div className="right-bottom">{item.rightBottomNode}</div>
          <div className="center">{item.centerNode}</div>
        </div>

        <div className="bottom-part">
          {
            item.workLink && item.isPublish && <a href={item.workLink} target="_blank" className="click-a-tag" rel="noreferrer"></a>
          }
          <div className="title overflow-hidden">{item.title}</div>
          <div className="meta">
            <div className="subtitle">{item.subtitle}</div>
            <div className="icons">{item.icons}</div>
          </div>
          <div className="bottom">
            {item.userName && (
              <a className="user" href={item.userLink} target="_blank" title={item.userName}>
                <div
                  className="user-avatar"
                  style={{ backgroundImage: `url(${item.avatarUrl})` }}
                ></div>
                <a className="user-name overflow-hidden" rel="noreferrer">
                  {item.userName}
                </a>
              </a>
            )}

            {item.date && (
              <div className="date overflow-hidden" title={item.date.substring(0, 10)}>{item.date.substring(0, 10)}</div>
            )}

            {!(item.userName && item.date) && <>&nbsp;</>}

            {
              <div className="statistics ">

                <div className="item">
                  <RiEyeLine className="icon" />
                  <span>{prettifyNumber(Number(item.view))}</span>
                </div>
                <div className="item">
                  <RiFireLine className="icon" />
                  <span>{prettifyNumber(Number(item.hot))}</span>
                </div>

              </div>
            }
          </div>
          <div className="bottom-card">{item.bottomNode}</div>
        </div>
      </div>
    </UniCardLayoutStyle>
  );
}

export default UniCardLayout;
