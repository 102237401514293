import styled from "styled-components";

export const WorkApplicantListStyle = styled.div`
    .list {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        &-item {
            background-color: #f1f1f1;
            width: 100%;
            padding: 15px 30px;
            border-radius: 6px;
            .tip a {
                color: ${props => props.theme.colors.colorTextcolorPrimary};
            }
            .top {
                display: flex;
                justify-content: space-between;
            }
            .content {
                margin: 10px 0 5px;
                display: flex;
                gap: 20px;
                .work-name {
                    color: ${props=>props.theme.colors.colorTextcolorPrimary};
                    cursor: pointer;
                    max-width: 150px;
                    word-break: break-all;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .desc {
                    width: calc(100% - 170px);
                }
            }
            .date {
                text-align: right;
            }
        }
    }
`