import { get, post } from "api"
import { CreateCommentRequest, CommentListRequest, CommentListItem, CommentDeleteUpgradeRequest } from "types/comment"
import { PaginationResponse } from 'types/common/axios'

// 创建评论
export const postCreateComment = (params: CreateCommentRequest) =>
    post<CreateCommentRequest, CommentListItem>('/api/comment/create', params)

// 获取评论列表
export const getCommentList = (params: CommentListRequest) =>
    get<CommentListRequest, PaginationResponse<CommentListItem>>('/api/comment/list', params)

// 删除评论
export const postDeleteComment = (id: number) =>
    post<{ id: number }, {}>(`/api/comment/delete/${id}`)

// 删除评论
export const postDeleteCommentUpgrade = (params: CommentDeleteUpgradeRequest) =>
    post<CommentDeleteUpgradeRequest, {}>(`/api/comment/delete_upgrade`, params )