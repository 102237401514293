import styled from "styled-components"

export const EmojiPickerStyle = styled.div`
.emoji{
    color: ${props => props.theme.colors.colorPrimary};
    cursor: pointer;
    width: 18px;
    display: flex;
    align-items: center;
}
.popper__arrow {
    border-bottom-color: #fff !important;
    border-top-color: #fff !important;
}
.emoji-switch {
    color: #f4aa2f !important;
    margin-left: 10px;
  
    cursor: pointer;
    ::v-deep.icon-biaoqing {
        &.iconfont {
            font-size: 20px !important;
        }
    }

    &:hover {
        color: #e59231;
    }
}
.popover{
    ::v-deep .ant-popover-inner {
        padding: 0;
    }
}
.emoji_warpper {
    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 344px;
    padding-top: 1px;

    .emoji_warpper_tab {
        display: flex;
        align-items: center;
        height: 36px;
        background-color: #f1f1f1;
        border-radius: 0px 0px 4px 4px;
        div {
            width: 58px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            img {
                width: 24px;
                height: 24px;
            }
            &:first-child {
                border-radius: 0px 0px 0px 4px;
            }
            &:hover,
            &.active {
                background-color: #fff;
            }
        }
    }
    .emoji_title {
        color: #757575;
        padding: 0 ${props => props.theme.space.padding}px;
        margin: ${props => props.theme.space.marginXS}px 0;
    }
    .emoji_warpper_cnt {
        height: 220px;
        overflow: auto;
        border-radius: 4px;
        padding-left:  ${props => props.theme.space.padding}px;
        background: #fff;
        // align-items: center;
        justify-content: center;
        .emoji_warpper_item {
            width: 100%;
            text-align: left;
            display: flex;
            gap: 3px;
            flex-wrap: wrap;
            // text-align: center;
            // flex-wrap: wrap;
            .item {
                border-radius: 4px;
                padding: 3px;
                cursor: pointer;

                &:hover {
                    background-color: #f1f1f1;
                }
                img {
                    object-fit: cover;
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}

.wrapper {
    display: flex;
    align-items: center;
}
`