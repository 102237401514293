import { useEffect, useRef, useState } from "react";
import { ImagePreviewMobileStyle } from "./style"
import ImgCrop from "antd-img-crop";
import { Button, message, Modal, Upload, UploadFile } from "antd";

type ImageUploadProps = {
    limit?: number;
    width?: number;
    height?: number;
    value?: {
        imgUrl?: string;
        file?: Blob;
    };
    aspect?: number;
    isSquare?: boolean;
    onChange?: (params: { imgUrl: string; file: File | undefined }) => void;
    disabled?: boolean;
    uploadImage: (params: { imgUrl: string; file: File | undefined }) => void;
    deleteImage?: () => void;
    noNeedCrop?: boolean;
    limitSize?: number
};

const ImagePreviewMobile = (props: ImageUploadProps) => {
    const [imgUrl, setImageUrl] = useState<string>(props.value?.imgUrl ?? "");
    const [loading, setLoading] = useState<boolean>(false);
    const uploadRef = useRef() as React.MutableRefObject<any>;
    const triggerChange = (changedValue: {
        imgUrl: string;
        file: File | undefined;
    }) => {
        props.onChange?.({
            ...props!.value,
            ...changedValue,
        });
    };

    useEffect(() => {
        setImageUrl(props.value?.imgUrl || '')
    }, [props.value])

    const [fileList, setFileList] = useState<UploadFile<{ url: string }>[]>([]);

    const beforeUpload = (file: File) => {
        setLoading(true);
        const isJpgOrPng =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/gif";
        if (!isJpgOrPng) {
            message.error("只允许上传 JPG/PNG/GIF 格式的文件!");
            setLoading(false);
            return;
        }
        let isLimit = true;

        isLimit = file.size / 1024 / 1024 < (props.limitSize || 10);
        if (!isLimit) {
            message.error("图片大小超出限制");
            setLoading(false);
            return;
        }

        if (isJpgOrPng && isLimit) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                let Img = new Image();
                Img.onload = () => {
                    let isRightWidth = true;
                    let isRightHeight = true;
                    if (props.width) {
                        isRightWidth = props.width === Img.width;
                    }
                    if (props.height) {
                        isRightHeight = props.height === Img.height;
                    }
                    let isSquaewNeed = true;
                    if (!isSquaewNeed) return;
                    if (isRightWidth && isRightHeight) {
                        fileList.length = 0;
                        setFileList([
                            {
                                uid: "1",
                                url: reader.result as string,
                                name: file.name,
                            },
                        ]);
                        triggerChange({
                            imgUrl: reader.result as string,
                            file: file,
                        });

                        setImageUrl(reader.result as string);
                        setLoading(false);
                    } else {
                        message.error("图片尺寸不满足要求");
                    }
                };
                Img.src = reader.result as string;
            });
            props?.uploadImage({
                imgUrl: reader.result as string,
                file: file,
            });
            reader.readAsDataURL(file);
        }
        return false;
    };

    const uploadCover = () => {

        // 手动触发裁剪弹窗
        if (uploadRef.current) {
            uploadRef.current.upload.uploader.fileInput.click(); // 触发裁剪弹窗
        }
    }

    return <ImagePreviewMobileStyle>
        <div className="cover" style={{ backgroundColor: !imgUrl ? '#eee;' : "" }}>
            {
                !!imgUrl && <img src={imgUrl} alt="" />
            }
            <div className="btn-wrap">
                <Button type="primary" loading={loading} onClick={uploadCover}>编辑封面</Button>
            </div>
        </div>

        <div>
            <ImgCrop aspect={props.aspect || 291 / 219} rotationSlider>
                <Upload
                    listType="picture-card"
                    beforeUpload={beforeUpload}
                    fileList={fileList}
                    disabled={props.disabled}
                    ref={uploadRef}
                >
                    {/* <Button type="primary" loading={loading}>{!!imgUrl ? '编辑封面' : '上传封面'}</Button> */}
                </Upload>
            </ImgCrop>
        </div>

    </ImagePreviewMobileStyle>
}

export default ImagePreviewMobile