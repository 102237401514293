import styled from "styled-components";

export const NotificationListStyle = styled.div`
    padding: ${props => props.theme.space.paddingLG}px;
    .right-wrapper{
        .no-data-wrap{
            margin-top: 180px;
        }
    }
    
    .left-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .all-read {
        color: ${props => props.theme.colors.colorPrimary};
        cursor: pointer;
    }
    
    .list-wrapper{
        padding-top:  ${props => props.theme.space.padding}px;
        .no-read {
            .title {
                font-weight: bold;
                color: rgb(0, 0, 0) !important;
            }
            color: rgb(0, 0, 0) !important;
        }
        .message-item{
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            color: rgb(138, 138, 139) !important;
            .hover-link{
                color: ${props => props.theme.colors.colorPrimary};
                cursor: pointer;
            }
            .left{
                display: flex;
                flex-direction: column;
                justify-content: center;
                max-width: calc(100% - 300px);
                .title{
                    width: 100%;
                    word-wrap: break-word;
                }
                .description{
                   padding-top: ${props => props.theme.space.paddingSM}px;
                   span {
                        color: ${props => props.theme.colors.colorTextcolorPrimary};
                        padding: 0 ${props => props.theme.space.paddingXXS}px;
                        cursor: pointer;
                        &:hover{
                         color: ${props => props.theme.colors.colorPrimaryActive};
                        }
                   }
                }
            }
            .right{
               display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-start;
                color:${props => props.theme.colors.colorTextTertiary};
                min-width: 220px;
                .btn{
                    height: 22px;
                    transform: translateY(2px);
                    color:${props => props.theme.colors.colorText};
                    cursor: pointer;
                    &:hover{
                    color: ${props => props.theme.colors.colorPrimaryActive};
                   }
                }
                .sys{
                    transform: translateY(-12px);
                    float: right;
                }
            }
        }
        .shop-msg{
            color: ${props => props.theme.colors.colorText} !important;
            a{
                color: ${props => props.theme.colors.colorPrimary};
               &:hover{
                color: ${props => props.theme.colors.colorPrimaryActive};
               }
            }
        }
    }
    .article {
        display: grid;
        --auto-grid-min-size: 20rem;
        grid-template-columns: repeat( auto-fill, minmax(var(--auto-grid-min-size), 1fr) );
        gap: 1.5rem;
        margin-top: ${props => props.theme.space.marginLG}px;
        &-item {
            /* width: 100%; */
            &:hover{
                box-shadow: ${(props) => props.theme.shadow.boxShadowSecondary};
            }
            border: 1px solid ${(props) => props.theme.colors.colorBorderSecondary};
            border-radius: ${(props) => props.theme.border.borderRadius}px;
            overflow: hidden;
            cursor: pointer;
            .article-cover {
                height: 150px;
                background-size: cover;
                cursor: pointer;
                background-position: center;
                background-repeat: no-repeat;
                
            }
            .article-title {
                font-size: ${props => props.theme.fontSizes.fontSizeLG}px;
                overflow: hidden;
                word-break: break-all;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: 22px;
                margin: ${props => props.theme.space.marginXS}px 0;
                padding: 0 ${props => props.theme.space.paddingXS}px;
            }
            .time {
                color: #94969f;
                padding: 0 ${props => props.theme.space.paddingXS}px;
                padding-bottom:  ${props => props.theme.space.paddingXS}px;
            }
        }
    }
`