import styled from "styled-components";

export const UniCardLayoutStyle = styled.div`
  width: 100%;
  position: relative;
  border-radius: ${(props) => props.theme.border.borderRadius}px;
  border: 1px solid ${(props) => props.theme.colors.colorBorder};
  cursor: pointer;
  overflow: hidden;
  min-width: 205px;
  &:hover {
    box-shadow: ${(props) => props.theme.shadow.boxShadowSecondary};
  }
  @media screen and (max-width: 1500px) {
    .user-name {
      max-width: 40px !important;
    }
    .title {
      max-width: 150px !important;
    }
    
  }
  @media (min-width: 1650px) and (max-width: 1800px) {
    .title {
      span {
        display: inline-block;
        max-width: 170px !important;
      }
    }
    }
  @media (min-width: 1800px) {
    .user-name {
      max-width: 5.2vw !important;
    }
  }
  .mb-sm{
    margin-bottom: ${(props) => props.theme.space.paddingSM}px;
  }
  .card-container {
    width: 100%;
      .up-part {
        width: 100%;
        aspect-ratio: 266/191;
        position: relative;
        &.cover {
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          position: relative;
          &:hover {
            .center {
              display: block;
            }
          }
          .status-wrap {
            bottom: 4px;
            left: 4px;
            //position: absolute;
            display: flex;
            align-items: center;
            gap: 4px;
          }

          .status {
            display: flex;
            align-items: center;
            gap: 4px;
            background-color: #595959;
            color: ${(props) => props.theme.colors.colorTextLightSolid};
            padding: ${(props) => props.theme.space.paddingXXS}px
              ${(props) => props.theme.space.paddingXS}px;
             border-radius: 4px;
            font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
            width: max-content;
          }
        }
        .center {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 90%;
          display: none;
          z-index: 10;
        }
        .left-bottom{
          position: absolute;
          left: 0;
          bottom: 0;
        }
        .left-top {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 2;
        }
        .right-top {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 2;
        }
        .right-bottom {
          position: absolute;
          right: 8px;
          bottom: 8px;
        }
      }

    .click-a-tag{
      background:transparent;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }
    
    .bottom-part {
      padding: 12px
        ${(props) => props.theme.space.paddingMD}px;
        position: relative;
      .title {
        cursor: pointer;
        position: relative;
        z-index: 2;
        width: max-content;
        max-width: 100%;

        span {
          display: inline-block;
        }
        a{
          color: ${(props) => props.theme.colors.colorText} !important;
          &:hover{
            color: ${(props) => props.theme.colors.colorLinkHover} !important;
          }
        }
        
      }
      .meta {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
        height: 30px;
        
        .subtitle {
          color: ${(props) => props.theme.colors.colorTextTertiary};
        }
        .icons {
          display: flex;
          align-items: center;
          position: relative;
          z-index: 2;
          img{
            width: 18px;
            height: 18px;
          }
         
        }
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
       
        .user {
          display: flex;
          align-items: center;
          max-width: 130px;
          cursor: pointer;
          .user-avatar {
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            position: relative;
            border-radius: 50% 50%;
            overflow: hidden;
            position: relative;
            z-index: 2;
          }
          .user-name {
            font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
            padding-left: ${(props) => props.theme.space.paddingXS}px;
            max-width: 4.5vw;
            position: relative;
            z-index: 2;
          }
        }
        .date {
          color: ${(props) => props.theme.colors.colorTextTertiary};
          font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
        }
        .statistics {
          display: flex;
          margin: ${(props) => props.theme.space.marginXXS}px 0;
          .item {
            display: flex;
            align-items: center;
            margin-left: ${(props) => props.theme.space.marginXS}px;
            color: ${(props) => props.theme.colors.colorTextTertiary};
            span {
              font-size: ${(props) => props.theme.fontSizes.fontSizeSM}px;
            }
            .icon {
              margin-right: ${(props) => props.theme.space.marginXXS}px;
            }
          }
        }
      }
    }

    .bottom-card {
      position: relative;
      z-index: 2;
    }
  }
`;
