import styled from "styled-components";

export const FloatChatInputStyle = styled.div`
    width: 100%;
    /* background-color:${(props) => props.theme.colors.colorBlack1Ggac}; */
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: ${(props) => props.theme.border.borderRadius}px;
    z-index: 1000;
    .inputBar{
        display: flex;
        .input{
            flex: 1;
        }
        .btn{
            width: 80px;
            margin-left: ${props => props.theme.space.marginXS}px;
        }
    }

    .cover-wrap {
        display: flex;
        gap: 10px;
        .cover {
            width: 80px;
            aspect-ratio: 1;
            position: relative;
            cursor: pointer;
            background-size: cover;
            background-position: center;
            margin-top: 10px;
            &:hover {
                .mask-wrap {
                    display: block;
                } 
            }
            .mask-wrap {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.5);
                display: none;
                .mask {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 3px;
                    &-item {
                        display: flex;
                        gap: 3px;
                        color: #fff;
                        align-items: center;
                        font-size: 11px;
                        &:hover {
                            color: ${props => props.theme.colors.colorPrimary};
                        }
                    }
                }
            }
        }
    }

    .operate {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-top: 10px;
        svg {
            display: flex;
            align-items: center;
        }
    }
`