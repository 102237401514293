import styled from "styled-components";

export const WorkUpdateStyle = styled.div`
 background-color: ${(props) => props.theme.colors.colorBgLayout};
    .form-section{
        background-color: ${(props) => props.theme.colors.colorBgContainer};
        padding: 0 ${(props) => props.theme.space.paddingLG}px  ${(props) => props.theme.space.paddingSM}px   ${(props) => props.theme.space.paddingLG}px ;
        /* margin-bottom:  ${(props) => props.theme.space.paddingLG}px; */
        border-radius:  ${(props) => props.theme.border.borderRadiusLG}px;
        font-size: 14px;
    }
    .main-container{
        width:  ${(props) => props.theme.space.mainContainer - 160}px;
        margin: 0 auto;
        background-color: ${(props) => props.theme.colors.colorBgLayout};
        padding:  ${(props) => props.theme.space.paddingLG}px 0 90px;
        display: flex;
        .left{
            flex: 1;
            .form-area{
                width: 100%;
            }
            .title {
                color: #2b2e36;
                margin-bottom: 10px;
                font-size: 14px !important;
            }
            .upload-form {
                .ant-row {
                    display: block !important;
                }
                margin-bottom: 0 !important;
            }
            .head-wrap {
                max-width: 300px;
                position: relative;
                .recruit {
                    position: absolute;
                    left: 330px;
                    top: 46px;
                    button {
                        display: flex;
                        align-items: center;
                        gap: 0;
                        svg {
                            font-size: 14px;
                        }
                        .ant-checkbox-checked .ant-checkbox-inner {
                            background-color: #fff !important;
                            &::after {
                                border-color: ${props => props.theme.colors.colorPrimary} !important;
                            }
                        }
                    }
                }
            }
            .header{
                padding: ${(props) => props.theme.space.paddingSM}px 0;
                display: flex;
                gap: 5px;
                align-items: center;
            }
            .ant-space {
                button {
                    width: 96px;
                }
            }
            .agree-tip {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                .red {
                    background-color: red;
                    color: #fff;
                    padding: 4px 6px;
                    border-radius: 20px;
                    font-size: 12px;
                }
                & > div:last-child {
                    color: rgba(0, 0, 0, 0.45);
                }
                .agree {
                    span {
                        color: ${props => props.theme.colors.colorPrimary};
                        cursor: pointer;
                    }
                }
            }
        }
        .ant-tag {
            border: none !important;
        }
        .ant-input-show-count-suffix {
            font-size: 14px !important;
        }
        .ant-select-selector {
            border-color: #e4e4e4 !important;
        }
        .ant-input-data-count {
            font-size: 14px !important;
            bottom: 7px !important;
            right: 13px;
        }
        textarea {
            padding-bottom: 25px;
            font-size: 14px !important;
        }
        input,.ant-select-selection-item {
            font-size: 14px !important;
        }
        .fixed {
            position: fixed;
            display: flex;
            justify-content: center;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: #fff;
            height: 82px;
            align-items: center;
            box-shadow: 0px -2px 4px 0px rgba(138, 136, 169, 0.1);
            .block-btn {
                width:  ${(props) => props.theme.space.mainContainer - 160}px;
                button {
                    height: 40px;
                    border-radius: 8px;
                    font-size: 14px !important;
                    width: 110px;
                }
                .primary {
                    width: 160px !important;
                    border: none !important;
                }
            }
        }
        .right{
            width: 320px;
            background-color: ${(props) => props.theme.colors.colorBgContainer};
            padding: ${(props) => props.theme.space.paddingSM}px ${(props) => props.theme.space.paddingSM}px;
            margin-left:  ${(props) => props.theme.space.paddingLG}px;
            border-radius:  ${(props) => props.theme.border.borderRadiusLG}px;
            .card{
                background-color: ${(props) => props.theme.colors.colorBgContainer};
                margin-bottom:  ${(props) => props.theme.space.paddingSM}px;

                .tab-wrap {
                    display: flex;
                    justify-content: center;
                    margin-top: 10px;
                    .ant-radio-button-wrapper {
                        color: #000;
                    }
                }
                
                .image-crop-wrapper{
                    padding: 10px;
                    
                    .ant-radio-group {
                        span {
                            color: #000 !important;
                        }
                    }
                   .ant-upload-select {
                        width: 100%;
                        height: 216px;
                   }
                   .ant-upload-list-item-container {
                    width: 100%;
                    height: 216px;
                   }
                }
                .cover{
                    
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    position: relative;
                    .delete{
                        cursor: pointer;
                        position: absolute;
                        top: 0;
                        right: 0;
                        padding: ${(props) => props.theme.space.paddingXXS}px  ${(props) => props.theme.space.paddingXXS}px ;
                        color: ${(props) => props.theme.colors.colorError};
                        font-size:  ${(props) => props.theme.fontSizes.fontSizeLG}px;
                    }
                } 
            }
        }
        .custom-title{
            padding-bottom:  ${(props) => props.theme.space.padding}px;
        }
    }
`