import styled from "styled-components"
export const RegulatioinDetailStyle = styled.div`
    @font-face {
        font-family: 'kuaikanshijieti';
        src: url('https://cdn-prd.ggac.com/ggac/font/kuaikanshijieti.ttf');
    }
    @font-face {
        font-family: "DIN";
        src: url("https://cdn.ggac.com/v2/fonts/DINCondensedC-2.e5aeb766.ttf");
    }
    @font-face {
        font-family: 'BebasNeue';
        src: url('https://cdn-prd.ggac.com/ggac/font/BebasNeue-Regular.otf');
    }
    .detail {
        margin-top: -3px;
    }

    .en-detail {
        .title {
            letter-spacing: 0 !important;
        }
        .part1 {
            .date-item {
                font-size: 13px !important;
                letter-spacing: 0 !important;
                text-align: center;
            }
            .date-tip {
                justify-content: center !important;
                padding: 0;
                width: 1300px;
            }
            .tip {
                flex: 1;
                text-align: center;
                transform: translateX(10px);
            }
            .date-tip .tip:first-child {
                transform: translateX(17px);
            }
        }

        .price {
            .list {
                .name {
                    letter-spacing: 0 !important;
                    font-size: 20px !important;
                }
                &-item:last-child {
                    .money {
                        margin-top: 20px;
                    }
                }
            }

            .desc-wrap {
                .desc {
                    padding: 0 10px 0 30px !important;
                }
                &::after {
                    left: 17px !important;
                }
            }

            .btn {
                font-size: 18px;
            }

            .last-border {
                padding: 0 30px 0 50px;
                .border-item {
                    margin-bottom: 5px;
                    div {
                        line-height: 25px;
                    }
                }
            }

            .tip {
                width: 1200px;
                margin: 40px auto 0;
            }
        }
        .about {
            .border {
                background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-about-en.png") center center / 100% 100% no-repeat !important;
                width: 1200px;
                height: max-content;
                padding: 100px 0;
                .content {
                    &-title {
                        text-align: center;
                        font-weight: bold;
                        font-size: 22px;
                    }
                    &-sub-title {
                        font-weight: bold;
                        font-size: 20px;
                        margin-top: 20px !important;
                    }
                    div {
                        margin-top: 5px;
                        line-height: 30px;
                    }
                    ul{
                        list-style-type: disc;
                        padding-left: 40px;
                        li {
                            color: #fff;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .part1 {
        background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-part-one-bg2.png") center center no-repeat;
        background-size: 100% 100%; 
        width: 100%;
        padding: 120px 0 300px;
        position: relative;
        z-index: 10;
        .date {
            display: flex;
            justify-content: center;
            gap: 100px;
            position: relative;
            width: 1250px;
            margin: 0 auto;
            .left {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -130px;
                width: 179px;
            }
            .right {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -130px;
                width: 179px;
            }
            &-item {
                width: 112px;
                height: 95px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-date-item.png") center center no-repeat;
                background-size: 100% 100%; 
                font-size: 18px;
	            line-height: 22px;
	            letter-spacing: 1px;
	            color: #384456;
                padding-right: 16px;
                padding-top: 3px;
                position: relative;
                &:nth-child(7) {
                    background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-date-last-item.png") center center no-repeat !important;
                    background-size: 100% 100% !important; 
                    padding-right: 0 !important;
                    width: 95px;
                    &::after{
                        width: 0;
                    }
                }
                &::after {
                    content: "";
                    position: absolute;
                    right: -108px;
                    top: 50%;
                    transform: translateY(-50%);
                    background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-date-line-center.png") center center no-repeat;
                    background-size: 100% 100%; 
                    width: 113px;
                    height: 2px;
                }
            }
        }
        .date-tip {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
	        letter-spacing: 1px;
	        color: #fdfcfc;
            width: 1190px;
            margin: 0 auto;
            padding: 0 25px;
            .tip:last-child {
                transform: translateX(-2px);
            }
            .tip {
                margin-top: 10px;
            }
            .tip:nth-child(5) {
                position: relative;
                transform: translateX(-13px);
            }
        }

        .part-content {
            margin-top: 100px;
            text-align: center;
            .word {
                margin-top: 30px;
                div {
                    line-height: 40px;
	                letter-spacing: 1px;
                    font-size: 20px;
	                color: #ffffff;
                }
            }

            video {
                width: 1037px;
                height: 594px;
                object-fit: cover;
                margin-top: 50px;
            }
        }
    }

    .title-wrap {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin-top: 40px;
        .num-wrap {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            top: -56px;
            height: 56px;
            overflow-y: hidden;
        }
        .num {
            font-size: 70px;
	        color: rgba(46,94,152,0.6);
            font-family: "DIN";
        }
        .color-num {
            color: rgba(124,165,213,0.6) !important;
        }
        .title {
            background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-title.png") center center no-repeat;
            background-size: 100% 100%; 
            width: 903px;
            height: 83px;
            font-size: 30px;
	        letter-spacing: 12px;
	        color: #ffffff;
            text-align: center;
            font-family: 'kuaikanshijieti';
            line-height: 90px;
            position: relative;
            z-index: 2;
        }
    }

    .price {
        background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-part-two-bg3.png") center center no-repeat;
        background-size: 100% 100%; 
        padding: 350px 0 200px;
        position: relative;
        z-index: 9;
        margin-top: -300px;
        .sub-title {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 26px;
	        color: #2e5e98;
            gap: 25px;
            font-family: 'kuaikanshijieti';
            margin-top: 75px;
            .wenhao {
                cursor: pointer;
                width: 20px;
            }
        }

        .tab-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 15px;
            font-size: 18px;
	        color: #2e5e98;
            span:nth-child(2),span:nth-child(4) {
                color: #0065e2;
                text-decoration: underline;
                text-underline-offset: 5px;
                padding: 0 3px;
                cursor: pointer;
            }
        }

        .tab-wrap2 {
            transform: translateY(1px);
        }

        .tab-list {
            display: flex;
            .tab-item {
                cursor: pointer;
                span:first-child {
                    text-decoration: underline;
                    text-underline-offset: 3px;
                }
            }
        }

        .img-list {
            display: flex;
            justify-content: center;
            gap: 138px;
            row-gap: 50px;
            margin-top: 40px;
            flex-wrap: wrap;
            img {
                width: 632px;
                cursor: pointer;
            }
        }

        .last-border {
            width: 793px;
            height: 404px;
            background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-part-two-border.png") center center no-repeat;
            background-size: 100% 100%; 
            margin: 50px auto 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 164px;
            .border-item {
                font-size: 16px;
	            color: #2e5e98;
                margin-bottom: 10px;
                position: relative;
                &:last-child {
                    margin-bottom: 0;
                }
                &::after {
                    content: "";
                    width: 11px;
                    height: 14px;
                    background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-part-two-border-icon.png") center center no-repeat;
                    background-size: 100% 100%; 
                    position: absolute;
                    left: -15px;
                    top: 4px;
                }
                div {
                    line-height: 20px;
                }
            }
        }

        .third-title {
            background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-third-title.png") center center no-repeat;
            background-size: 100% 100%; 
            width: 314px;
            height: 108px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .price-num {
                font-size: 22px;
	            color: #386d9e;
            }
            margin-top: 50px;
        }
        .money-wrap {
            color: #386d9e;
            font-family: 'BebasNeue';
            margin-top: -10px;
            span:first-child {
                font-size: 39px;
            }
            span {
                display: inline;
                font-size: 66px;
	            letter-spacing: 2px;
            }
            span:nth-child(3) {
                font-size: 34px;
            }
        }

        .list {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 30px;
            margin-top: 95px;
            &-item {
               width: 252px;
               height: 390px;
               background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-price-border-2.png") center center no-repeat;
               background-size: 100% 100%; 
               text-align: center;
               padding-top: 30px;
               color: #ffffff;
               position: relative;
               /* min-width: 230px; */
               &:first-child,&:last-child {
                    position: relative;
                    bottom: 120px;
               }
               &:nth-child(2),&:nth-child(4) {
                    position: relative;
                    bottom: 60px;
               }
               .name {
                    font-size: 22px;
	                letter-spacing: 4px;
                    margin-bottom: 20px;
               }
               .number {
                    margin: 20px 0 15px;
                    font-size: 18px;
               }
               .item {
                    font-size: 14px;
               }
               .white {
                    .money-wrap {
                        color: #fff;
                    }
               }
               .gift {
                    font-size: 30px;
	                letter-spacing: 1px;
                    margin-bottom: 20px;
               }
               .money {
                    /* position: absolute; */
                    bottom: 0;
                    text-align: center;
                    width: 100%;
                    top: 135px;
                    span:first-child {
                        font-size: 30px;
                    }
                    span {
                        display: inline;
                        font-size: 50px;
	                    letter-spacing: 2px;
                    }
                    .money-tip {
                        margin-top: -10px;
                    }
                    span:nth-child(3) {
                        font-size: 26px;
                    }
               }

               .desc-list {
                    margin-top: -5px;
                }

               .desc-wrap {
                    margin-bottom: 5px;
                    position: relative;
                    &::after {
                        position: absolute;
                        left: 30px;
                        top: 6px;
                        content: "";
                        width: 8px;
                        height: 7px;
                        background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-price-border-icon.png") center center no-repeat;
                        background-size: 100% 100%; 
                    }
               }

               .desc {
                    font-size: 12px;
                    text-align: left;
	                color: #ffffff;
                    padding-left: 40px;
               }
            }
        }
        .sub-tip {
            font-size: 14px !important;
	        letter-spacing: 0px !important;
            font-weight: bold;
        }
        .work-btn {
            background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-price-btn2.png") center center no-repeat;
            background-size: 100% 100%; 
            width: 269px;
            height: 54px;
            font-size: 22px;
            line-height: 54px;
            color: rgb(6, 88, 160);
            text-align: center;
            cursor: pointer;
            margin: 60px auto;
        }
        .btn {
            background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-price-btn.png") center center no-repeat;
            background-size: 100% 100%; 
            width: 432px;
            height: 62px;
            cursor: pointer;
            margin: 10px auto 0;
            font-size: 22px;
	        line-height: 62px;
	        color: #0658a0;
            text-align: center;
            cursor: pointer;
        }
        .tip {
            font-size: 18px;
	        letter-spacing: 2px;
	        color: #2e5e98;
            text-align: center;
            margin-top: 60px;
        }
        .img-list1 {
            margin-top: 20px;
        }
    }

    .three-tip {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
	    letter-spacing: 0px;
	    color: #2e5e98;
        margin-top: 60px;
        font-family: kuaikanshijieti;
    }

    .style3d {
        .name {
            letter-spacing: 0 !important;
        }
        .number {
            margin-bottom: 0 !important;
        }
        .gift {
            font-size: 24px !important;
            margin-top: 20px;
            div {
                line-height: 25px;
            }
        }
    }

    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 38px auto 0 !important;
        font-size: 18px;
	    color: #2e5e98;
        width: 339px;
        height: 54px;
        background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-price-btn3.png") center center no-repeat;
        background-size: 100% 100%; 
    }

    .judge {
        padding: 400px 0 230px; 
        position: relative;
        z-index: 8;
        background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-part-four-bg.png") center center no-repeat;
        background-size: 100% 100%; 
        margin-top: -280px;
        .judge-list {
            display: flex;
            flex-wrap: wrap;
            width: 1400px;
            margin: 90px auto 0;
            .judge-item {
                width: calc(100% / 5);
                text-align: center;
                margin-bottom: 20px;
            }
            .image {
                width: 186px;
                height: 187px;
                margin: 0 auto 10px;
            }
            .judge-name {
                font-size: 20px;
	            color: #b2e8fe;
                margin-bottom: 5px;
            }
            .desc {
                font-size: 14px;
	            line-height: 24px;
	            color: #ffffff;
            }
        }
    }
    .about {
        background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-part-three-bg.png") center center no-repeat;
        background-size: 100% 100%; 
        margin-top: -550px;
        padding: 650px 0 300px;
        position: relative;
        z-index: 5;
        
        .border {
            background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-about.png") center center no-repeat;
            background-size: 100% 100%; 
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 1200px;
            height: 779px;
            margin: 70px auto 140px;
            .content {
                padding: 40px 90px 0;
                div {
                    font-size: 18px;
	                line-height: 34px;
	                color: #ffffff;
                }
                &>div {
                    &:first-child {
                        margin-top: 0;
                    }
                    margin-top: 20px;
                }
            }
        }

        .code {
            display: flex;
            margin-top: 75px;
            justify-content: center;
            gap: 60px;
            margin-bottom: 200px;
            img {
                margin-bottom: 10px;
            }
            .desc {
                text-align: center;
                font-size: 16px;
	            color: #0658a0;
            }
        }
        .bottom-logo {
            text-align: center;
            background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-part-five-bg.png") center center no-repeat;
            background-size: 100% 100%; 
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 530px;
            img {
                height: 80px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 60px;
            }
        }
    }
`