import styled from "styled-components";

export const CommentUploadStyle = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    .ant-upload {
        height: max-content !important;
        width: 100% !important;
        border:none !important;
    }
    .ant-upload-list-item-container {
        display: none !important;
    }
`