import {  theme } from 'antd';
const { useToken } = theme;

export const UseDarkColors = () => {
        const { token } = useToken();
        return {
            colorPrimary: token.colorPrimary,
            colorPrimaryText: token.colorPrimaryText,
            colorText: token.colorText,
            colorBlack6Ggac:'#000000',
            colorBlack5Ggac:"#363636",
            colorBlack4Ggac:"#6c6c6c",
            colorBlack3Ggac:"#a2a2a2",
            colorBlack2Ggac:"#d8d8d8",
            colorBlack1Ggac:"#f3f3f3",
            colorTextLightSolid: token.colorTextLightSolid, //#fff
            colorTextSecondary:token.colorTextSecondary,//rgba(0, 0, 0, 0.65)
            colorTextTertiary:token.colorTextTertiary, //rgba(0, 0, 0, 0.45)
            colorTextLightCustom: 'rgba(0, 0, 0, 0.55)', //rgba(0, 0, 0, 0.45)
            colorError:token.colorError, //#ff4d4f
            colorBgContainer:token.colorBgContainer, //#ffffff
            colorBorder:token.colorBorder, //#d9d9d9,
            colorBorderSecondary:token.colorBorderSecondary, //#f0f0f0
            colorBgLayout:token.colorBgLayout, //#f5f5f5
            colorPrimaryActive:token.colorPrimaryActive, //#cf851d
            colorPrimaryBg:token.colorPrimaryBg, //#fffcf0
            colorPrimaryBorder:token.colorPrimaryBorder, //#f0f0f0
            colorFillQuaternary:token.colorFillQuaternary, //#f5f5f5
            colorHomeBgContainer:token.colorBgContainer,
            colorLinkDefault:"#8A8A8B",
            colorLinkHover:"#FF9A16",
            colorTextcolorPrimary:"#FF9A16"
        }
}








   
