import { HomeLayoutStyle } from "./style"
import SlideBanner from "../slide-banner";
import SmallBanner from "../small-banner";
import NavMenu from "../nav-menu";
import ContentCategory from "../content-category";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { FloatButton } from "antd";
import InitHome from "../init-home-enter";

const HomeLayout = () => {
    const location = useLocation()
    const [isHome, setIsHome] = useState(false)
   
    useEffect(() => {
        if (["/home", "/hot", "/recommend", "/follow"].includes(location.pathname)) {
            setIsHome(true)
            return
        }
        setIsHome(false)
    }, [location.pathname])

    return <HomeLayoutStyle>
        <div>
            {/* //主banner */}
            <SlideBanner></SlideBanner>
            {/* //广告位 */}
            <SmallBanner></SmallBanner>
            {/* //导航菜单 */}
            <NavMenu></NavMenu>
            {/* //分类导航 */}
            {
                isHome && <ContentCategory></ContentCategory>
            }

            <div id="work-list">
                <Outlet></Outlet>
            </div>
            <FloatButton.Group shape="circle" className="float-button">
                <FloatButton.BackTop tooltip="回到顶部" target={() => document.getElementById('kindsWorks')!} />
            </FloatButton.Group>

            {/* 用户第一次进入首页弹窗弹窗 */}
            
            <InitHome />

        </div>
    </HomeLayoutStyle>
}

export default HomeLayout