import { RiImageFill } from "react-icons/ri"
import { CommentUploadStyle } from "./style"
import { message, Upload, UploadFile } from "antd"
import { useState } from "react";


type ImageUploadProps = {
    limit?: number;
    width?: number;
    height?: number;
    value?: {
        imgUrl?: string;
        file?: Blob;
    };
    aspect?: number;
    isSquare?: boolean;
    onChange?: (params: { imgUrl: string; file: File | undefined }) => void;
    disabled?: boolean;
    uploadImage: (params: { imgUrl: string; file: File | undefined }) => void;
    deleteImage?: () => void;
    noNeedCrop?: boolean;
    limitSize?: number
    color: string, 
    size: number,
};

const CommentUpload = (props: ImageUploadProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [fileList, setFileList] = useState<UploadFile<{ url: string }>[]>([]);

    const triggerChange = (changedValue: {
        imgUrl: string;
        file: File | undefined;
    }) => {
        props.onChange?.({
            ...props!.value,
            ...changedValue,
        });
    };

    const beforeUpload = (file: File) => {
        setLoading(true);
        const isJpgOrPng =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/gif";
        if (!isJpgOrPng) {
            message.error("只允许上传 JPG/PNG/GIF 格式的文件!");
            setLoading(false);
            return;
        }
        let isLimit = true;

        isLimit = file.size / 1024 / 1024 < (props.limitSize || 10);
        if (!isLimit) {
            message.error("图片大小超出限制");
            setLoading(false);
            return;
        }

        if (isJpgOrPng && isLimit) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                let Img = new Image();
                Img.onload = () => {
                    let isRightWidth = true;
                    let isRightHeight = true;
                    if (props.width) {
                        isRightWidth = props.width === Img.width;
                    }
                    if (props.height) {
                        isRightHeight = props.height === Img.height;
                    }
                    let isSquaewNeed = true;
                    if (!isSquaewNeed) return;
                    if (isRightWidth && isRightHeight) {
                        fileList.length = 0;
                        setFileList([
                            {
                                uid: "1",
                                url: reader.result as string,
                                name: file.name,
                            },
                        ]);
                        triggerChange({
                            imgUrl: reader.result as string,
                            file: file,
                        });

                        setLoading(false);
                    } else {
                        message.error("图片尺寸不满足要求");
                    }
                };
                Img.src = reader.result as string;
            });
            props?.uploadImage({
                imgUrl: reader.result as string,
                file: file,
            });
            reader.readAsDataURL(file);
        }
        return false;
    };

    return <CommentUploadStyle>
        <Upload
            listType="picture-card"
            beforeUpload={beforeUpload}
            fileList={fileList}
            disabled={props.disabled}
        >
            <RiImageFill color={props.color} size={props.size} />
        </Upload>

    </CommentUploadStyle>
}

export default CommentUpload