import { GlobalSearchPostResponse } from "types/common";
import { MediaIconsStyle } from "./style"
import { RiFileTextLine, RiLiveLine } from "react-icons/ri";
import { MediaType } from "types/enums/media-type";
import { FavoriteDataListItem } from "types/favorite";
import { WorkLikeListsItem } from "types/user/work";
import { ReactSVG } from 'react-svg';

function MediaIconsES({ item }: { item: GlobalSearchPostResponse | FavoriteDataListItem | WorkLikeListsItem }) {
  return (
    <MediaIconsStyle>
      <div className={`${item.mediaTypeList?.includes(MediaType.video) || item.dataTable === "article" || item.mediaTypeList?.includes(MediaType.multiImage) ? 'wrap' : ''}`}>
        {/* 文章类型 */}
        {item.dataTable === "article" && (
          <div className="item" title="文章">
            <ReactSVG src="https://cdn-prd.ggac.com/ggac/svg/article-icon.svg" beforeInjection={(svg) => {
              svg.setAttribute('width', '24');
              svg.setAttribute('height', '16');
            }} />
          </div>
        )}

        {/* 视频图标 */}
        {item.mediaTypeList?.includes(MediaType.video) && (
          <div className="item video" title="视频作品">
            <ReactSVG src="https://cdn-prd.ggac.com/ggac/svg/work-video-icon3.svg" beforeInjection={(svg) => {
              svg.setAttribute('width', '16');
              svg.setAttribute('height', '20');
            }} />
            {/* <img src="" alt="" width={17}/> */}
          </div>
        )}
        {/* 多图图标 */}
        {item.mediaTypeList?.includes(MediaType.multiImage) && (
          <div className="item" title="多图作品">
            <ReactSVG src="https://cdn-prd.ggac.com/ggac/svg/work-image-icon3.svg" beforeInjection={(svg) => {
              svg.setAttribute('width', '24');
              svg.setAttribute('height', '18');
            }} />
            {/* <img src="https://cdn-prd.ggac.com/ggac/svg/work-image-icon.svg" alt="" width={17}/> */}
          </div>
        )}
      </div>
    </MediaIconsStyle>
  )
}

export default MediaIconsES