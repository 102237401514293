import { useCallback, useEffect, useMemo, useState } from "react";
import { NotificationListStyle } from "./style";
import { List, Pagination, Spin, message } from "antd";
import { apiGetNotificationCount, apiGetNotificationList, apiGetOfficialArticleList, apiNotificationItemCount, postReadAll, postReadSingle } from "api/notification";
import {
  NotificationItemCount,
  NotificationListItem,
  NotificationListRequest,
  OfficialArticleItem,
} from "types/notification";
import { NotificationTypeArr } from "types/enums/notification-type";
import { NoData } from "components/no-data";
import { useNavigate } from "react-router-dom";
// import { LanguageType } from "types/enums/config-language-type";
import UISegmentedControl from "ui-lib/segmented-control";
import { SegmentedValue } from "antd/es/segmented";
import { descRender } from "views/user-center/notification/fn";
import { store } from "utils/store";
import { changeUerNoticeCount } from "utils/store/reducer/user";
import { CoverImageObjectCloudResize } from "utils/functions/file";
import MessageImage from "./message-image";

const NotificationList = () => {
  const [list, setList] = useState<NotificationListItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSegementValue, setCurrentSegementValue] =
    useState<SegmentedValue>(0);
  const [listTotal, setListTotal] = useState(0);
  // const ConfigObj = useContext(ConfigContext);
  const navigate = useNavigate();
  const pageSize = 10;
  const [officialArticleList, setOficialArticleList] = useState<OfficialArticleItem[]>([])
  const [officialPageNumber, setOfficialPageNumber] = useState(1)
  const [officialTotal, setOfficialTotal] = useState(1)
  const [loading, setLoading] = useState(false)
  const [NotificationCount, setNotificationCount] = useState<NotificationItemCount>({ commentCount: 0, followCount: 0, likeCount: 0, systemCount: 0, mallCount: 0, forumCount: 0 })
  const [NotificationAllCount, setNotificationAllCount] = useState(0)
  const [readLoading, setReadLoading] = useState(false)

  const getList = (pageNumber: number, type?: number) => {
    let params: NotificationListRequest = {
      pageNumber: pageNumber, //页码，默认1
      pageSize: pageSize, //页条数，最大100，默认10
    };
    if (type) {
      params.type = type
    }
    setCurrentPage(pageNumber)
    setOfficialPageNumber(pageNumber)
    setLoading(true)
    apiGetNotificationList(params).then((res) => {
      // console.log(JSON.stringify(params),[
      //   ...res.pageData.map((item) => {
      //     return item;
      //   }),
      // ],res.totalSize)
      setList([
        ...res.pageData.map((item) => {
          return item;
        }),
      ]);
      setListTotal(res.totalSize);
    }).finally(() => {
      setLoading(false)
    })
  };

  useEffect(() => {
    getList(1);
  }, []);

  useEffect(() => {
    apiGetNotificationItemCount()
    apiGetNotificationAllCount()

  }, [])

  const getArticleList = useCallback(() => {
    setLoading(true)
    apiGetOfficialArticleList({ type: 1, pageSize: 12, pageNumber: officialPageNumber }).then(res => {
      setOficialArticleList(res.pageData)
      setOfficialTotal(res.totalSize)
    }).finally(() => {
      setLoading(false)
    })
  }, [officialPageNumber])

  useEffect(() => {
    getArticleList()
  }, [getArticleList])

  const apiGetNotificationItemCount = () => {
    apiNotificationItemCount().then(res => {
      setNotificationCount(res)
    })
  }

  const apiGetNotificationAllCount = () => {
    apiGetNotificationCount().then(res => {
      setNotificationAllCount(res.count)
    })
  }

  const changeOfficialPage = (value: number) => {
    setOfficialPageNumber(value)
  }



  // const onChangeLanguage = (lang: LanguageType) => {
  //   ConfigObj.changeLanguage(lang);
  // };

  const onChangePage = (page: number) => {
    setCurrentPage(page);
    getList(page, Number(currentSegementValue));
    if (Number(currentSegementValue) > 0) {
    } else {
      // getList(page);
    }
  };



  useEffect(() => {
    setOfficialPageNumber(1)
    setCurrentPage(1)
  }, [currentSegementValue])

  const handleTypeChange = (type: SegmentedValue) => {
    setCurrentSegementValue(type);
    if (Number(type) > 0) {
      getList(1, Number(type));
      apiGetNotificationItemCount()
    } else {
      getList(1);
      apiGetNotificationAllCount()
    }
  };

  const toOfficialDetail = (id: number) => {
    window.open(`/official/${id}`)
  }

  const clearMessage = (detail: NotificationListItem) => {
    if (detail.status === 1) {
      return
    }
    postReadSingle(detail.id).then(() => {
      const index = list.findIndex(item => {
        return item.id === detail.id
      })
      list[index].status = 1
      setList([...list])
      apiGetNotificationItemCount()
      apiGetNotificationAllCount()
      if (NotificationAllCount === 0) {
        return
      }
      store.dispatch(changeUerNoticeCount(NotificationAllCount - 1))
    })
  }

  const allReaded = () => {
    setReadLoading(true)
    postReadAll().then(() => {
      getList(1);
      apiGetNotificationItemCount()
      apiGetNotificationAllCount()
      message.success("设置全部已读成功")
      store.dispatch(changeUerNoticeCount(0))
    }).finally(() => {
      setReadLoading(false)
    })
  }

  const NotificationTypeArrNew = useMemo(() => {
    const newArr = NotificationTypeArr()
    newArr[0].label = NotificationAllCount === 0 ? newArr[0].label : newArr[0].label + " " + NotificationAllCount
    newArr[1].label = NotificationCount?.systemCount === 0 ? newArr[1].label : newArr[1].label + " " + NotificationCount?.systemCount
    newArr[2].label = NotificationCount?.likeCount === 0 ? newArr[2].label : newArr[2].label + " " + NotificationCount?.likeCount
    newArr[3].label = NotificationCount?.commentCount === 0 ? newArr[3].label : newArr[3].label + " " + NotificationCount?.commentCount
    newArr[4].label = NotificationCount?.followCount === 0 ? newArr[4].label : newArr[4].label + " " + NotificationCount?.followCount
    newArr[5].label = NotificationCount?.mallCount === 0 ? newArr[5].label : newArr[5].label + " " + NotificationCount?.mallCount
    newArr[6].label = NotificationCount?.forumCount === 0 ? newArr[6].label : newArr[6].label + " " + NotificationCount?.forumCount
    return newArr
  }, [NotificationCount, NotificationAllCount])

  const onClickMsgItem = (item: NotificationListItem) => {
    clearMessage(item)
    if (item.params?.webUrl) {
      window.open(item.params?.webUrl, '_blank')
    }
    if (item.params.template === 8) {
      //结算消息跳转
      window.open('/user-center/wallet', '_blank')
    }

    if (item.params.template === 1) {
      //结算消息跳转
      if (!item.params.orderNumber) {
        return
      }
      window.open(`/user-center/shop/seller-order/detail/${item.params.orderNumber}`)
    }

  }

  return (
    <NotificationListStyle>
      <div className="container">
        <div className="top-wrapper">
          <div className="left-wrapper">
            <UISegmentedControl
              onValueChange={(value) => handleTypeChange(value)}
              options={NotificationTypeArrNew}
              value={currentSegementValue}
            ></UISegmentedControl>
            {
              Number(currentSegementValue) !== 11 && <div onClick={allReaded} className="all-read">全部标记为已读</div>
            }
          </div>
          <Spin spinning={loading}>
            {
              Number(currentSegementValue) !== 11 && <div className="right-wrapper">
                {list.length === 0 && (
                  <div className="no-data-wrap">
                    <NoData></NoData>{" "}
                  </div>
                )}

                {list.length > 0 && (
                  <List
                    className="list-wrapper"
                    size="large"
                    pagination={{
                      position: "bottom",
                      align: "center",
                      current: currentPage,
                      total: listTotal,
                      showSizeChanger: false,
                      pageSize: pageSize,
                      onChange: onChangePage,
                      hideOnSinglePage: true,
                    }}
                    bordered={false}
                    dataSource={list}
                    renderItem={(item) => (
                      <List.Item style={{ cursor: "pointer" }}>
                        <div className={`message-item ${item.status === 0 ? 'no-read' : ''}`} onClick={() => onClickMsgItem(item)}>
                          <div className="left">
                            <div className="title">{item.title}</div>
                            <div className="description">{descRender(item, clearMessage)}</div>
                            <MessageImage content={item.params.content} />
                          </div>
                          <div className="right">
                            {item.type === 1 && (
                              <>
                                <div className="time sys">{item.createTime}</div>
                                {item.params?.webUrl && <div className="btn" >
                                  查看
                                </div>}
                                {!item.params?.webUrl && <div className="btn" >

                                </div>}

                              </>
                            )}
                            {item.type !== 1 && (
                              <>
                                <div className="time sys">{item.createTime}</div>
                                <div className="btn" >

                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </List.Item>
                    )}
                  />
                )}
              </div>
            }
            {
              Number(currentSegementValue) === 11 && <div>
                {
                  officialArticleList.length === 0 && <NoData />
                }
                <div className="article">
                  {
                    officialArticleList.map(item => {
                      return <div className="article-item" onClick={() => toOfficialDetail(item.id)} key={item.id}>
                        <div className="article-cover" style={{ backgroundImage: `url(${CoverImageObjectCloudResize(item.coverUrl, 500)})` }}></div>
                        <div className="article-title">{item.title}</div>
                        <div className="time">{item.createTime?.substring(0, 10)}</div>
                      </div>
                    })
                  }
                </div>
                <Pagination
                  style={{ textAlign: "center", marginTop: "15px" }}
                  hideOnSinglePage={true}
                  current={officialPageNumber}
                  onChange={changeOfficialPage}
                  pageSize={12}
                  showSizeChanger={false}
                  total={officialTotal}
                />
              </div>
            }
          </Spin>
        </div>
        <div className="bottom-wrapper"></div>
      </div>
    </NotificationListStyle>
  );
};

export default NotificationList;
