import CommonTabs from "ui-lib/tab/common-tab"
import { ApplicantLayoutStyle } from "./style"
import { useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"

const ApplicantLayout = () => {

    const [key, setKey] = useState('received')

    const statusTabs = [
        {
            key: 'received',
            title: '我收到的',
        },
        {
            key: 'send',
            title: '我发出的',
        }
    ]

    const navigate = useNavigate()

    useEffect(() => {
        const arr = window.location.pathname.split("/")
        setKey(arr[arr.length - 1] || 'received')
    }, [])

    const checkTab = (detail: { key: number | string; title: string }) => {
        navigate(`/user-center/applicant/${detail.key}`)
        setKey(String(detail.key))
    }

    return <ApplicantLayoutStyle>
        <div className="main-content">
            <CommonTabs
                tabs={statusTabs}
                defaultKey={key}
                onClick={(item) => checkTab(item)}
            />
            <Outlet />
        </div>
    </ApplicantLayoutStyle>
}

export default ApplicantLayout