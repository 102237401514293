import { Suspense, lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import ContestLayout from "views/contest/layout";
import CommonWork from 'views/contest/work'
import Layout from "views/layout";
import { ContestApplication } from "views/contest-form/apply";
import { ContestUpload } from "views/contest-form/upload";
import { YcwlontestUpload } from "views/contest/ycwl2/upload"
import { GContestApplication } from "views/grand-contest-form/apply";
import { GContestUpload } from "views/grand-contest-form/upload";
import Ly3dWorkprize from "views/contest/ly3d/workprize";
import Ycwl2Detail from "views/contest/ycwl2/detail";
import Ycwl2Rule from "views/contest/ycwl2/rule";
import Ycwl2Workprize from "views/contest/ycwl2/workprize";
import CzwjDetail from "views/contest/czwj/detail";
import CzwjRule from "views/contest/czwj/rule";
import CzwjWorkprize from "views/contest/czwj/workprize";
import YdbzDetail from "views/contest/ydbz/detail";
import YdbzShop from "views/contest/ydbz/shop";
import YdbzExhibition from "views/contest/ydbz/exhibition";
import LybzWorkprize from "views/contest/ydbz/workprize";
import YlzyDetail from "views/contest/ylzy/detail";
import YlzyRule from "views/contest/ylzy/rule";
import YlzyWorkPrize from "views/contest/wukong/workprize";
import Ycwl3Detail from "views/contest/ycwl3/detail";
import Ycwl3Rule from "views/contest/ycwl3/rule";
import PuzzleDetail from "views/contest/2025cusga/detail";
import Cusga2025Work from "views/contest/2025cusga/work";

//路由懒加载,重定向的不用再调用懒加载函数
const LazyLoad = (path: string) => {
    const Comp = lazy(() => import('views/' + path));
    return (
        <Suspense fallback={<></>}>
            <Comp />
        </Suspense>
    )
}

export const matchRoutes: RouteObject[] = [
    {
        path: '/contest/wukong',
        element: <Navigate to="/contest/wukong/detail"></Navigate>
    },
    {
        path: '/contest/ty',
        element: <Navigate to="/contest/ty/detail"></Navigate>
    },
    {
        path: '/contest/ylzy',
        element: <Navigate to="/contest/ylzy/detail"></Navigate>
    },
    {
        path: '/contest/czwj',
        element: <Navigate to="/contest/czwj/detail"></Navigate>
    },
    {
        path: '/contest/czwj2',
        element: <Navigate to="/contest/czwj2/detail"></Navigate>
    },
    {
        path: '/contest/ycwl2',
        element: <Navigate to="/contest/ycwl2/detail"></Navigate>
    },
    {
        path: '/contest/ycwl3',
        element: <Navigate to="/contest/ycwl3/detail"></Navigate>
    },
    {
        path: '/contest/2025cusga',
        element: <Navigate to="/contest/2025cusga/detail"></Navigate>
    },
    {
        path: '/contest/longarts',
        element: <Navigate to="/contest/longarts/pro"></Navigate>
    },
    {
        path: '/contest/ydbz',
        element: <Navigate to="/contest/ydbz/detail"></Navigate>
    },
    {
        path: '/contest/ly3d',
        element: <Navigate to="/contest/ly3d/detail"></Navigate>
    },
    {
        path: '/contest/xrtz',
        element: <Navigate to="/contest/xrtz/detail"></Navigate>
    },
    {
        path: '/contest/sims',
        element: <Navigate to="/contest/sims/detail"></Navigate>
    },
    {
        path: '/contest/xy',
        element: <Navigate to="/contest/xy/detail"></Navigate>
    },
    // {
    //     path: '/contest/:code',
    //     element: <ContestTemplate></ContestTemplate>
    // },
    {
        path: '/contest',
        element: <Layout></Layout>,
        children: [
            {
                path: "tshh",
                element: LazyLoad('contest/tshh/detail')
            },
            {
                path: 'xy',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: LazyLoad('contest/xy/detail'),
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='xy' />,
                    },
                    {
                        path: 'about',
                        element: LazyLoad('contest/xy/about'),
                    },
                    {
                        path: 'previous',
                        element: LazyLoad('contest/xy/previous'),
                    },
                ]
            },
            {
                path: 'sims',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: LazyLoad('contest/sims/detail'),
                    },
                    {
                        path: "rule",
                        element: LazyLoad('contest/sims/rule'),
                    },
                    {
                        path: "workprize",
                        element: LazyLoad('contest/sims/workprize'),
                    },
                    {
                        path: "work",
                        element: LazyLoad('contest/sims/work'),
                    },
                ]
            },
            {
                path: 'czwj2',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: LazyLoad('contest/czwj2/detail'),
                    },
                    {
                        path: "arms",
                        element: LazyLoad('contest/czwj2/arms'),
                    },
                    {
                        path: "rule",
                        element: LazyLoad('contest/czwj2/rule'),
                    },
                    {
                        path: "about",
                        element: LazyLoad('contest/czwj2/about'),
                    },
                    {
                        path: "workprize",
                        element: LazyLoad('contest/czwj2/workprize'),
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='czwj2' />,
                    },
                ]
            },
            {
                path: 'xrtz',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: LazyLoad('contest/xrtz/detail'),
                    },
                    {
                        path: "rule",
                        element: LazyLoad('contest/xrtz/rule'),
                    },
                    {
                        path: "about",
                        element: LazyLoad('contest/xrtz/about'),
                    },
                    {
                        path: "workprize",
                        element: LazyLoad('contest/xrtz/workprize'),
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='xrtz' />,
                    },
                ]
            },
            {
                path: 'ly3d',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: LazyLoad('contest/ly3d/detail'),
                    },
                    {
                        path: "rule",
                        element: LazyLoad('contest/ly3d/rule'),
                    },
                    {
                        path: "about",
                        element: LazyLoad('contest/ly3d/about'),
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='ly3d' />,
                    },
                    {
                        path: 'workprize',
                        element: <Ly3dWorkprize />,
                    },
                ]
            },
            {
                path: 'longarts',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: LazyLoad('contest/longarts/detail'),
                    },
                    {
                        path: "pro",
                        element: LazyLoad('contest/longarts/pro'),
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='longarts' />,
                    },
                ]
            },
            {
                path: 'longarts/upload/:id',
                element: LazyLoad("contest/longarts/upload")
            },
            {
                path: 'snakearts/pro',
                element: LazyLoad("contest/snake/index")
            },
            {
                path: 'ycwl2',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: <Ycwl2Detail />,
                    },
                    {
                        path: "rule",
                        element: <Ycwl2Rule />,
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='ycwl2' />,
                    },
                    {
                        path: 'upload/:id',
                        element: <YcwlontestUpload></YcwlontestUpload>
                    },
                    {
                        path: 'workprize',
                        element: <Ycwl2Workprize />
                    },
                    // {
                    //     path: 'notice',
                    //     element: LazyLoad('contest/ycwl2/notice')
                    // }
                ]
            },
            {
                path: 'ycwl3',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: <Ycwl3Detail />,
                    },
                    {
                        path: "rule",
                        element: <Ycwl3Rule />,
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='ycwl3' />,
                    },
                    // {
                    //     path: 'notice',
                    //     element: LazyLoad('contest/ycwl2/notice')
                    // }
                ]
            },
            {
                path: '2025cusga',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: <PuzzleDetail />,
                    },
                    {
                        path: 'work',
                        element: <Cusga2025Work name='2025cusga' />,
                    }
                ]
            },
            {
                path: 'czwj',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: <CzwjDetail />,
                    },
                    {
                        path: "rule",
                        element: <CzwjRule />,
                    },
                    {
                        path: "workprize",
                        element: <CzwjWorkprize />,
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='czwj' />,
                    },
                ]
            },
            {
                path: 'wukong',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: LazyLoad('contest/wukong/detail'),
                    },
                    {
                        path: "rule",
                        element: LazyLoad('contest/wukong/rule'),
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='wukong' />,
                    },
                    {
                        path: 'workprize',
                        element: LazyLoad('contest/wukong/workprize'),
                    },
                ]
            },
            {
                path: 'ty',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: LazyLoad('contest/ty/detail'),
                    },
                    {
                        path: "rule",
                        element: LazyLoad('contest/ty/rule'),
                    },
                    {
                        path: "about",
                        element: LazyLoad('contest/ty/about'),
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='ty' />,
                    },
                    {
                        path: 'workprize',
                        element: LazyLoad('contest/ty/workprize'),
                    },
                ]
            },
            {
                path: 'ylzy',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: <YlzyDetail />,
                    },
                    {
                        path: "rule",
                        element: <YlzyRule />,
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='ylzy' />,
                    },
                    {
                        path: 'workprize',
                        element: <YlzyWorkPrize />,
                    }
                ]
            },
            {
                path: 'ydbz',
                element: <ContestLayout></ContestLayout>,
                children: [
                    {
                        path: "detail",
                        element: <YdbzDetail />,
                    },
                    {
                        path: 'work',
                        element: <CommonWork name='ydbz' />,
                    },
                    {
                        path: 'shop',
                        element: <YdbzShop />,
                    },
                    {
                        path: 'exhibition',
                        element: <YdbzExhibition />,
                    },
                    {
                        path: 'workprize',
                        element: <LybzWorkprize />,
                    }
                ]
            },
            {
                path: 'apply/:id',
                element: <ContestApplication></ContestApplication>
            },
            {
                path: 'upload/:id',
                element: <ContestUpload></ContestUpload>
            }
        ]
    },

    {
        path: '/g-contest',
        element: <Layout></Layout>,
        children: [
            {
                path: 'apply/:id',
                element: <GContestApplication></GContestApplication>
            },
            {
                path: 'upload/:id',
                element: <GContestUpload></GContestUpload>
            }
        ]
    }

]

