import styled from "styled-components";


export const TagSelectStyle = styled.div`
    .container {
        .ant-select-selector{
            padding: 4px !important;
            .ant-tag {
                background-color: #f7f7f7 !important;
            }
        }
        .tag-list-wrapper{
           
            .title{
                margin-top: ${props=> props.theme.space.marginXS}px;
                font-size: ${props=> props.theme.fontSizes.fontSizeSM}px;
                color: ${props=> props.theme.colors.colorTextTertiary};
                display: flex;
                justify-content: space-between;
                align-items: center;
                .alternative{
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    &:hover{
                        color: ${props=> props.theme.colors.colorPrimaryText}
                    }
                    span{
                        margin-left:${props=> props.theme.space.marginXS}px;
                    }
                }
            }
            .list-wrapper{
                margin-top: ${props=> props.theme.space.marginXS}px;
                display: flex;
                flex-wrap: wrap;
                gap: 8px;
                .tag{
                    
                    cursor: pointer;
                    margin: 0 ${props=> props.theme.space.marginXXS}px ${props=> props.theme.space.marginXXS}px 0;
                    margin-right: 0 !important;
                    div {
                        background-color: #f7f7f7;
                        border: none !important;
                        padding: 7px 12px !important;
                        font-size: 14px !important;
                        color: #8c8c8c;
                        border-radius: 6px;
                    }
                }
            }
        }
    }
`