import styled from "styled-components";

export const Ycwl3RuleStyle = styled.div`
@font-face {
  font-family: 'ShuHeiTi';
  src: url('https://cdn.ggac.com/fonts/Alimama_ShuHeiTi_Bold.ttf');
}
@font-face {
  font-family: 'AlibabaPuHuiTiR';
  src: url('https://cdn-prd.ggac.com/ggac/font/AlibabaPuHuiTi.ttf');
}
.rule {
    margin-top: -84px;
    font-family: 'AlibabaPuHuiTiR';
    .title {
        display: flex;
        justify-content: center;
        align-items: center;
	    letter-spacing: 10px;
	    color: #ffffff;
        font-family: 'ShuHeiTi';
        gap: 30px;
	    color: #ffffff;
        margin-bottom: 30px;
        position: relative;
        .word {
            font-size: 55px;
	        letter-spacing: 12px;
            padding-left: 12px;
            color: #fff;
            position: relative;
            z-index: 11;
        }
    }
   
    &-one {
        background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-rule-bg1.png") center center no-repeat;
        background-size: 100% 100%;
        padding: 204px 0 100px;
        position: relative;
        z-index: 10;
        & > .content {
            margin: 141px 0 158px;
            font-size: 20px;
	        line-height: 42px;
	        letter-spacing: 1px;
	        color: #ffffff;
            text-align: center;
            .content-item {
                &:nth-child(3) {
                    margin-bottom: 30px;
                }
                div {
                    height: 30px;
	                background-color: #91f3fa;
                    color: #000;
                    margin: 0 2px 0 0;
                }
            }
            
            div{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .tip {
                display: flex;
                justify-content: center;
                align-items: center;
                background: url("https://cdn.ggac.com/img/ycwl2-pc-part-one-bg.png") center center no-repeat;
                background-size: cover;
                width: 1038px;
	            height: 47px;
                margin: 0 auto;
                flex-direction: column;
                margin-top: 70px;
                color: #91f3fa;
                font-size: 18px;
            }
        }
        .tab {
            display: flex;
            justify-content: center;
            gap: 175px;
            margin-top: 100px;
            &-item {
                width: 260px;
                aspect-ratio: 244 / 61;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                color: #ffffff;
                background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-rule-tab-item.png") center center no-repeat;
                background-size: 100% 100%;
                cursor: pointer;
                padding: 2px 25px 0px;
                line-height: 20px;
                text-align: center;
            }
            .tab-item:hover,.tab-first {
                background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-rule-tab-item-active.png") center center no-repeat !important;
                background-size: 100% 100% !important;
            }
        }
        .list {
            width: 1200px;
            /* min-height: 930px; */
            background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-rule-part1-border.png") center center no-repeat;
            background-size: 100% 100%;
            margin: 30px auto 0;
            padding: 50px 20px 80px;
            .warn {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                font-size: 16px;
                margin-bottom: 30px;
                div {
                    color: ${props => props.theme.colors.colorPrimary};
                    line-height: 28px;
                }
            }
            .warn2 {
                color: ${props => props.theme.colors.colorPrimary};
                font-size: 16px;
                text-align: center;
                margin-bottom: 30px;
                padding: 0 0.3rem;
                a {
                    color: ${props => props.theme.colors.colorPrimary};
                }
            }
        }
        .border-list {
            display:flex;
            justify-content: center;
            gap: 40px;
            .group {
                margin-top: 150px;
                &-item {
                    width: 1041px;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 150px;
                    margin-bottom: 50px;
                    &:nth-child(3) {
                        margin-bottom: 0;
                    }
                    .border {
                        background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-part1-border2-item.png") center center no-repeat;
                        background-size: 100% 100%;
                        width: 245px;
                        height: 141px;
                        text-align: center;
                        padding-top: 28px;
                        cursor: pointer;
                        div {
                            font-size: 18px;
                            line-height: 25px;
	                        color: #ffffff;
                        }
                        .word {
                            margin-top: -2px;
                        }
                    }
                }
            }
            .border:hover,.tab-second {
                background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-part1-border2-item-active.png") center center no-repeat !important;
                background-size: 100% 100% !important;
            }
        }

        .content-tab {
            display: flex;
            justify-content: center;
            color: #fff;
            gap: 10px;
            &-item {
                padding: 0 10px 20px;
                font-size: 16px;
                cursor: pointer;
                &:hover {
                    color: #5fffe3;
                }
            }
            .active {
                color: #5fffe3;
            }
        }
        .content-line {
            background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-rule-border-line.png") center center no-repeat;
            background-size: 100% 100%;
            width: 1041px;
            height: 6px;
            margin: 0 auto 50px;
        }

        .content-wrap {
            padding: 0 50px;
            .link {
                text-align: center;
                a {
                    color: #5fffe3;
                    text-decoration: underline;
                    text-underline-offset: 5px;
                    font-size: 16px;
                }
            }
        }

        .content {
            margin-top: 0;
            /* min-height: 600px; */
            max-height: 690px;
            overflow-y: auto;
            ::-webkit-scrollbar {
              width: 10px; /* 设置滚动条的宽度 */
              background-color: transparent; /* 滚动条的背景色 */
            }
            ::-webkit-scrollbar-track {
              background: #e1e1e1; /* 轨道的背景色 */
              border-radius: 10px; /* 轨道的圆角 */
            }
            /* 自定义滚动条的滑块（thumb） */
            ::-webkit-scrollbar-thumb {
              background-color: #c1c1c1; /* 滑块的背景色 */
              border-radius: 10px; /* 滑块的圆角 */
              border: 2px solid #ffffff; /* 滑块边框 */
            }
            
            /* 滑块在悬停或者点击时的样式 */
            ::-webkit-scrollbar-thumb:hover {
              background: #a8a8a8; /* 悬停时滑块的背景色 */
            }
            
            ::-webkit-scrollbar-thumb:active {
              background-color: #919191; /* 点击时滑块的背景色 */
            }
            .word {
                font-size: 16px;
	            line-height: 30px;
	            color: #f1f1f1;
                text-align: left !important;
                padding: 0 20px;
                margin-bottom: 20px;
            }
            
            .section {
                padding: 0 20px;
                &-item {
                    margin-bottom: 10px;
                    font-size: 16px;
                    .section-title {
                        color: #5fffe3 !important;
                    }
                    .section-sub-title {
                        display: flex;
                        gap: 2px;
                        color: #fff;
                        div {
                            line-height: 30px;
                        }
                    }
                    .sub2 {
                        .sub-item {
                            & > div:first-child {
                                margin-left: 10px;
                            }
                        }
                    }
                    .sub {
                        margin-bottom: 5px;
                        .img {
                            display: flex;
                            margin-bottom: 10px;
                        }
                        &-item {
                            display: flex;
                            margin-left: 28px;
                            div:first-child {
                                margin-right: 5px;
                            }
                            
                        }
                        div{
                            line-height: 30px;
                            color: #fff;
                        }
                        span {
                            color: ${props => props.theme.colors.colorPrimary};
                        }
                    }
                }
            }
        }
        .example {
            width: 802px;
            margin: 0 auto;
            padding: 6px 7px;
            /* background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-rule-part1-border3.png") center center no-repeat;
            background-size: 100% 100%; */
            border: 1px solid rgba(145,243,250,0.6);
            margin-bottom: 30px;
            .top {
                display: flex;
                justify-content: space-between;
                &-item {
                    width: 390px;
	                height: 46px;
                    background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-rule-part1-border2-top.png") center center no-repeat;
                    background-size: 100% 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    color: #fff;
                }
            }
            .expample-wrap {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                row-gap: 10px;
                .example-item {
                    width: 100%;
                    min-height: 150px;
                    background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-rule-part1-border2-bottom.png") center center no-repeat;
                    background-size: 100% 100%;
                    display: flex;
                    position: relative;
                    & > div {
                        flex: 1;
                        height: 100%;
                        padding: 0 10px;
                        .inner {
                            /* width: max-content; */
                            margin: 0 auto;
                            text-align: left;
                            padding: 30px;
                        }
                        div {
                            line-height: 19px;
                            text-align: left;
                        }
                    }
                    .desc {
                        font-size: 14px !important;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        background-color: rgba(145,243,250,0.6);
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: 0;
                        width: 1px;
                        height: calc( 100% - 3px );
                    }
                    &:first-child {
                        &::after {
                            bottom: 0;
                            height: calc( 100% +  44px);
                        }
                        
                        &::before {
                            width: 0;
                        }
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        background-color: rgba(145,243,250,0.6);
                        top: -5px;
                        width: 100%;
                        height: 1px;
                    }
                }
            }
        }
        .bottom {
            padding: 0 60px;
            text-align: center;
        }
        .desc {
            font-size: 16px;
	        line-height: 32px;
	        color: #ffffff;
        }
    }
    .title2 {
        margin-top: 150px;
    }
    .rule2{
        background: url("https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-rule-part2-border.png") center center no-repeat;
        background-size: 100% 100%; 
        width: 1200px;
        height: 1007px;
        margin: 100px auto 200px;
        padding: 80px;
        color: #ffffff;
        font-size: 16px;
        .tip {
            margin-bottom: 30px;
        }
        .list-item {
            margin-bottom: 15px;
            .label {
                display: flex;
                div {
                    color: #5fffe3;
                }
            }
            .num {
                display: flex;
                padding-left: 12px;
                div {
                    margin-right: 4px;
                }
            }
            .content {
                padding-left: 10px;
            }
            div {
                line-height: 30px;
            }
            .content-word {
                display: flex;
            }
        }
    }
}
`