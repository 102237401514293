import styled from "styled-components";

export const MessageImageStyle = styled.div`
    .image {
        display: flex;
        gap: 10px;
        margin-top: 10px;
        img {
            width: 80px;
            height: 80px;
            border-radius: 6px;
            object-fit: cover;
        }
    }
`