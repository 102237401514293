import { WhiteCooperationInvitationListItem } from "types/common"
import { WorkApplicantListStyle } from "./style"
import { NoData } from "components/no-data"
import { Button, Pagination } from "antd"
import { useLocation, useNavigate } from "react-router-dom"
import { useState } from "react"
import { postApplyCooperationTeamReply, postWhiteCooperationInvitationReply } from "api/common"
import { queryUrl } from "utils/functions/common"

const WorkApplicantList = ({ list, count, update, type }: { list: WhiteCooperationInvitationListItem[], count: number, update: () => void, type: string }) => {

    const search = new URLSearchParams(useLocation().search)
    const pageNumber = Number(search.get("pageNumber") || 1);
    const [loading, setLoading] = useState(false)

    const agreeApplicant = (detail: WhiteCooperationInvitationListItem, status: number) => {
        setLoading(true)
        const func = type === 'work' ? postWhiteCooperationInvitationReply : postApplyCooperationTeamReply
        func({ id: detail.id, status })
            .then(() => {
                update()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const statusList = [
        "未回复",
        '已接受',
        "已拒绝"
    ]

    const navigate = useNavigate()

    const changePage = (e: any) => {
        const element = document.getElementById('main-container');
        const distance = element?.offsetTop;
        window.scrollTo({
            top: Number(distance) - 30,
            behavior: "smooth"
        })
        const query = queryUrl({
            pageNumber: e,
        });
        navigate(`?${query}`);
    }


    return <WorkApplicantListStyle>
        <div className="list">
            {
                list.length === 0 && <NoData />
            }

            {
                list.length > 0 && <>
                    {
                        list.map(item => {
                            return <div className="list-item" key={item.id}>
                                <div className="top">
                                    <div className="tip">收到来自 <a href={`/user/${item.inviterId}`} target="_blank">{item.inviterUsername}</a> 的{type === 'work' ? '合作邀约' : '组队申请'}</div>
                                    {
                                        item.status === 0 && <div className="btns">
                                            <Button type="primary" loading={loading} onClick={() => agreeApplicant(item, 1)}>{type === 'work' ? '接受邀约' : '同意'}</Button>
                                            <Button type="primary" loading={loading} onClick={() => agreeApplicant(item, 2)}>{type === 'team' ? '拒绝邀约' : '拒绝'}</Button>
                                        </div>
                                    }
                                    {
                                        item.status !== 0 && <div>当前状态：{statusList[item.status]}</div>
                                    }
                                </div>
                                <div className="content">
                                    {
                                        !!item.title && <a href={`/work/detail/${item.workId}`} target="_blank" className="work-name">{item.title}</a>
                                    }
                                    <span className="desc">{item.content}</span>
                                </div>
                                <div className="date">{item.createTime}</div>
                            </div>
                        })
                    }
                </>
            }
            <Pagination
                total={count}
                showSizeChanger={false}
                hideOnSinglePage={true}
                current={pageNumber}
                pageSize={10}
                onChange={changePage}
                showQuickJumper />
        </div>
    </WorkApplicantListStyle>
}

export default WorkApplicantList