import styled from "styled-components";

type InitHomeStyleProps = {
    viewPortWidth?: number;
};

export const InitHomeStyle = styled.div<InitHomeStyleProps>`
    .ant-modal-content {
        padding: 0 !important;
    }
    .cover {
        aspect-ratio: 900 / 500;
        width: 100%;
        background-color: #fff;
        background-size: cover;
        background-position: center;
        cursor: pointer;
        border-radius: 12px;
    }
    .ant-modal-close {
        color: #fff !important;
    }
    .ant-modal {
        width: ${props => (props.viewPortWidth || 0) >= 1800 ? '900px' : '50vw'} !important;
        min-width: 520px;
        .ant-modal-content {
            border-radius: 12px !important;
        }
    }
`