import styled from "styled-components";

export const SuperInteractionStyle = styled.div`
        margin: ${(props) => props.theme.space.marginLG}px 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .add-picture {
            position: relative;
            &::after {
                content: "+";
                position: absolute;
                left: 36%;
                top: 50%;
                font-size: 20px;
                transform: translateY(-50%);
                height: 100%;
            }
        }
        .ant-btn-primary{
            &:disabled{
                border: 1px solid #D9D9D9;
                color: #202020;
                background-color: #fff;
            }
        }

        .score-tip {
            margin: ${props => props.theme.space.margin}px 0;
        }

        .score {
            display: flex;
            gap: 20px;
            justify-content: center;
            &-item {
                padding: 5px 15px;
                font-size: 16px;
                border: 1px solid ${props => props.theme.colors.colorPrimary};
                border-radius: ${props => props.theme.border.borderRadius}px;
                cursor: pointer;
            }
            .score-active {
                background-color: ${props => props.theme.colors.colorPrimary};
            }
        }

        .btns {
            display: flex;
            justify-content: center;
            margin-top: ${props => props.theme.space.margin}px;
        }

`