import { WorkDetailType } from "types/work"
import { ApplyCreatorsStyle } from "./style"
import { Button, Input, message, Modal } from "antd"
import { useState } from "react"
import { UserBaseInfo } from "types/user"
import { useParams } from "react-router-dom"
import { postApplyCooperationTeamCreate } from "api/common"

const ApplyCreators = ({ detail }: { detail: WorkDetailType }) => {

    const [isShow, setIsShow] = useState(false)

    let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)

    const { id } = useParams()

    const [content, setContent] = useState('')
    const [loading, setLoading] = useState(false)

    const submit = () => {
        const params = {
            inviterId: userInfo.id,
            inviteeId: detail.userId,
            workId: Number(id),
            content: content.trim()
        }
        setLoading(true)
        postApplyCooperationTeamCreate(params).then(() => {
            setIsShow(false)
            message.success("申请成功")
        }).finally(() => {
            setLoading(false)
        })
    }

    return <ApplyCreatorsStyle>
        <div className="apply-tip">共创成员邀约中</div>
        <Button type="primary" onClick={() => setIsShow(true)}>立即申请加入</Button>
        <Modal title='留言' getContainer={false} footer={<></>} width={650} destroyOnClose={true} closable={false} centered={true} open={isShow}>
            <div className="content">
                <Input.TextArea maxLength={200} onChange={(e) => setContent(e.target.value)} autoSize={{ minRows: 5 }} count={{ show: true, max: 200 }} placeholder="推荐一下更容易成功哦"></Input.TextArea>
            </div>
            <div className="btns">
                <Button type="primary" onClick={submit} loading={loading}>提交申请</Button>
                <Button type="primary" onClick={() => setIsShow(false)}>取消</Button>
            </div>
        </Modal>
    </ApplyCreatorsStyle>
}

export default ApplyCreators