import UISegmentedControl from "ui-lib/segmented-control"
import { ApplicantReceivedStyle } from "./style"
import { SegmentedValue } from "antd/es/segmented";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getApplyCooperationTeamList, getWhiteCooperationInvitationList } from "api/common";
import { WhiteCooperationInvitationListItem } from "types/common";
import WorkApplicantList from "./workApplicantList";
import { Spin } from "antd";

const ApplicantReceived = () => {
    const search = new URLSearchParams(useLocation().search)
    const type = search.get("type") || 'team';
    const pageNumber = search.get("pageNumber") || 1;

    const [workApplicant, setWorkApplicant] = useState<WhiteCooperationInvitationListItem[]>([])
    const [workApplicantCount, setWorkApplicantCount] = useState<number>(0)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const tabOption = [
        {
            label: "组队申请",
            value: "team",
        },
        {
            label: "合作邀约",
            value: "work",
        }
    ];

    const getList = useCallback(() => {
        setLoading(true)
        getWhiteCooperationInvitationList({ type: 2, pageNumber: Number(pageNumber), pageSize: 10 }).then(res => {
            setWorkApplicant(res.pageData)
            setWorkApplicantCount(res.totalSize)
        }).finally(() => {
            setLoading(false)
        })
    }, [pageNumber])

    const getTeamList = useCallback(() => {
        setLoading(true)
        getApplyCooperationTeamList({ type: 2, pageNumber: Number(pageNumber), pageSize: 10 }).then(res => {
            setWorkApplicant(res.pageData)
            setWorkApplicantCount(res.totalSize)
        }).finally(() => {
            setLoading(false)
        })
    }, [pageNumber])

    const updateList = () => {
        if (type === 'work') {
            getList()
        } else {
            getTeamList()
        }
    }

    useEffect(() => {
        if (type === 'work') {
            getList()
        } else {
            getTeamList()
        }
    }, [type, getList, getTeamList])

    const changeWorkType = (e: SegmentedValue) => {
        navigate(`/user-center/applicant/received?type=${e}`)
    }

    return <ApplicantReceivedStyle>
        <div className="receive-content">
            <UISegmentedControl onValueChange={changeWorkType} options={tabOption} value={type}></UISegmentedControl>
            <Spin spinning={loading}>
                <WorkApplicantList type={type} update={updateList} list={workApplicant} count={workApplicantCount} />
            </Spin>
        </div>

    </ApplicantReceivedStyle>
}

export default ApplicantReceived