import styled from "styled-components";

export const LeftTopNodeStyle = styled.div`
    .left-top {
        display: flex;
        background-color: ${props => props.theme.colors.colorPrimary};
        margin-top: 8px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        height: 22px;
        line-height: 22px;
        padding: 0 9px 0 6px;
        .item {
            width: max-content;
            font-size: 13px;
            /* color: #2b2e36; */
        }
    }
`