import { isWideScreen, randomNum } from 'utils/functions/common'

export const userHomeTabs = [
  {
    key: 1,
    title: '作品',
    url: '/user-center/home/work/list',
    urlGroup: [
      '/user-center/home/work/list',
      '/user-center/home/work/draft/list',
      '/user-center/home/work/offShelf/list',
    ],
  },
  {
    key: 2,
    title: '作品集',
    url: '/user-center/home/work-album',
    urlGroup: [
      '/user-center/home/work-album',
      '/user-center/home/work-album/detail/',
      '',
    ],
  },
  {
    key: 3,
    title: '赛事 ',
    url: '/user-center/home/contest-work/list',
    urlGroup: ['/user-center/home/contest-work/list'],
  },
  {
    key: 4,
    title: '文章 ',
    url: '/user-center/home/article/list',
    urlGroup: [
      '/user-center/home/article/list',
      '/user-center/home/article/draft/list',
      '/user-center/home/article/offShelf/list',
    ],
  },
  {
    key: 5,
    title: '收藏 ',
    url: '/user-center/home/favorites',
    urlGroup: [
      '/user-center/home/favorites',
      '/user-center/home/favorites/detail/',
    ],
  },
  {
    key: 6,
    title: '赞过 ',
    url: '/user-center/home/likes',
    urlGroup: ['/user-center/home/likes'],
  },
  {
    key: 7,
    title: '足迹 ',
    url: '/user-center/home/history',
    urlGroup: ['/user-center/home/history'],
  },
]

export function mapCurrentPath(path: string) {
  let arr: string[] = []
  for (let i = 0; i < userHomeTabs.length; i++) {
    userHomeTabs[i].urlGroup.forEach((item) => arr.push(item))
  }
  if (arr.includes(path.replace(/\d+/g, ''))) {
    return '/user-center/home/work/list'
  }
  const lastSlashIndex = path.lastIndexOf('/')

  const allPath = window.location.pathname + window.location.search

  if (path.substring(0, lastSlashIndex) === '/user-center/address/edit') {
    path = '/user-center/address/edit'
  }
  if (path.includes('/user-center/shop/product/update')) {
    path = '/user-center/shop/product-list'
  }
  if (path.includes('/user-center/shop/order/detail')) {
    path = '/user-center/order?state=-1'
  }
  if (path.includes('/user-center/shop/seller-order/detail')) {
    path = '/user-center/shop/product-list'
  }
  if (path.includes('tj-')) {
    path = '/user-center/nft-album'
  }
  if (path.includes('/wallet/')) {
    path = '/user-center/wallet/index'
  }
  if (path.includes('/applicant/send')) {
    path = '/user-center/applicant/received'
  }
  if (allPath === '/user-center/shop/product/create?code=yuegao') {
    path = '/user-center/shop/commission/product-list'
  }
  switch (path) {
    case '/user-center/address/create':
      return '/user-center/address'
    case '/user-center/address/edit':
      return '/user-center/address'
    case '/user-center/favorites/detail':
      return '/user-center/favorites'
    case '/user-center/authentication/student':
      return '/user-center/authentication/idCard'
    case '/user-center/fans':
      return '/user-center/home'
    case '/user-center/followee':
      return '/user-center/home'
    case '/user-center/shop/product/create':
      return '/user-center/shop/product-list'
    case '/user-center/order':
      return '/user-center/order?state=-1'
    case '/user-center/tj-publish':
      return '/user-center/nft-album'
    case '/user-center/tj-sold':
      return '/user-center/nft-album'
    default:
      return path
  }
}

const maxBgCount = 32

export function determineUserBackground(url: string | undefined) {
  return (
    url ??
    `https://cdn-prd.ggac.com/ggac/assets/image/user-center/banner/${randomNum(
      1,
      maxBgCount
    )}.png`
  )
}

export function getRandomUserBackground() {
  return `https://cdn-prd.ggac.com/ggac/assets/image/user-center/banner/${randomNum(
    1,
    maxBgCount
  )}.png`
}

export function determinRightPannelWidth(path: string) {
  if (
    path.includes('/home') ||
    path.includes('certified-author') ||
    path.includes('nft-album') ||
    path.includes('tj-publish') ||
    path.includes('tj-sold')
  ) {
    return '100%'
  }

  if (isWideScreen()) {
    return '1500px'
  }

  if (window.innerWidth < 1600) {
    return '1100px'
  }

  return '1200px'
}
