import { PublicProductDetail } from "types/product"
import { ProductCommentStyle } from "./style"
import Comments from "components/comments"
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import FloatChatInput from "views/work/detail/right/floatChatInput";
import { CommentListItem } from "types/comment";

const ProductComment = ({ detail, update }: { detail: PublicProductDetail, update: () => void }) => {
    const commentRef = useRef() as React.MutableRefObject<any>;
    const { productId } = useParams<any>();
    const updateCommentCount = () => {
        update && update()
    }

    // 调用commet创建方法
    const createComment = (val: CommentListItem) => {
        commentRef.current?.addComment(val)
    }

    const [isCanDeleteComment, setIsCanDeleteComment] = useState(false)
    let userId = JSON.parse(localStorage.getItem('user_info')!)?.id

    useEffect(() => {
        if (!detail) {
            return
        }
        if (userId === detail.userId) {
            setIsCanDeleteComment(true)
        }
    }, [detail, userId])

    return <ProductCommentStyle>
        <div className="container">
            <div id="input-wrap">
                <FloatChatInput params={{ createComment, dataTable: "mall_product", subId: Number(productId) }}></FloatChatInput>
            </div>
            <Comments ref={commentRef} params={{ commentCount: detail.commentCount, updateCommentCount, type: 'mall_product', id: Number(productId), canDeleteComment: isCanDeleteComment }}></Comments>

        </div>
    </ProductCommentStyle>
}

export default ProductComment