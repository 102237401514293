import { NavMenuFilterStyle } from './style'
import { useLocation } from "react-router";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, message } from 'antd';
import { clearUserHomepageRedDots, getUserHomepageRedDots } from 'api/user';
import { useSelector } from "react-redux";
import { store } from 'utils/store';
import { updateTabList } from 'utils/store/reducer/user-red-dots';

type tabType = {
  name: string,
  key: string,
  path: string,
  dot: number
}

function NavMenuFilter({ count = 0 }: { count?: number }) {
  const [messageApi, contextHolder] = message.useMessage();

  const tabList = useSelector((state: any) => {
    return state.userRedDots.tabList;
  });

  const lacation = useLocation()
  const navigate = useNavigate()

  const toDetail = (detail: tabType) => {
    document.getElementById('kindsWorks')!.scrollTop = document.body.clientHeight - 145
    navigate(`${detail.path}`)
  }

  const handleClick = async (item: tabType) => {
    if (item.key === "follow") {
      clearUserHomepageRedDots({ type: 1 }).then(() => {
        getRedDots()
      })

    }
    if (item.key === "billboard") {
      await clearUserHomepageRedDots({ type: 2 }).then(() => {
        getRedDots()
      })
    }
    toDetail(item)
  }

  const getRedDots = async () => {
    await getUserHomepageRedDots().then(res => {
      let newArr = JSON.parse(JSON.stringify(tabList));
      newArr = newArr.map((item: any) => {
        if (item.key === "follow") {
          item.dot = res.followUserWork
        }
        if (item.key === "billboard") {
          item.dot = res.ranking
        }
        return item
      })
      store.dispatch(updateTabList(newArr))
    })
  }

  useEffect(() => {
    if(count !== 1) {
      return
    }
    if (window.location.pathname === '/home' || window.location.pathname === '/recommend' || window.location.pathname === '/hot' || window.location.pathname === '/follow' || window.location.pathname === '/billboard/work/week') {
      getRedDots()
    }

  }, [count])



  return (
    <NavMenuFilterStyle>
      {contextHolder}
      <div className='menus' >
        {
          tabList.map((item: any) => {

            return <Badge dot key={item.key} className="badge" offset={[-24, 15]} showZero={false} count={item.dot}><div className={`menu-item ${lacation.pathname.search(item.key) !== -1 ? 'active' : ''}`} onClick={() => handleClick(item)}>{item.name}</div> </Badge>
          })
        }
      </div>

    </NavMenuFilterStyle>
  )
}

export default NavMenuFilter