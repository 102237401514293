import { Button, Form, Input, message, Modal } from "antd"
import { WorkApplicantStyle } from "./style"
import RelatedWork from "./related-work";
import { useState } from "react";
import { postCreateWhiteCooperationInvitation } from "api/common";
import { UserBaseInfo } from "types/user";
import { WhiteCooperationInvitationCreateRequest } from "types/common";

type workType = {
    coverUrl?: string;
    id?: number;
    title?: string;
};

const WorkApplicant = ({ onClose, partnerUser, userInfo }: { onClose: () => void, userInfo: UserBaseInfo, partnerUser: { name?: string, id: number } }) => {
    const [form] = Form.useForm<any>();

    const [isShowRelatedWorkModal, setIsShowRelatedWorkModal] = useState(false)

    const [selectWork, setSelectWork] = useState<workType>()

    const [isLoading, setIsLoading] = useState(false)

    const submit = () => {
        form.validateFields().then(res => {
            const params: WhiteCooperationInvitationCreateRequest = {
                inviteeId: partnerUser.id,
                inviterId: userInfo?.id,
                content: res.content,
            }
            if (selectWork?.id) {
                params.workId = selectWork?.id
            }
            setIsLoading(true)
            postCreateWhiteCooperationInvitation(params).then(() => {
                message.success("提交成功")
                onClose()
            }).finally(() => {
                setIsLoading(false)
            })
        })
    }

    const saveWork = (detail?: workType) => {
        setSelectWork(detail)
    }

    const desc = `输入你的邀约需求：例如
我们正在组队参加2025CUSGA，现招募：游戏美术：负责角色、场景、UI设计，熟练使用PS、Blender等工具。
游戏编程：熟悉Unity/Unreal，负责游戏逻辑与开发。
参赛地点：上海
自由创作空间、团队合作与成长机会、比赛荣誉与行业曝光
一起打造独特游戏，留下我们的名字！`

    return <WorkApplicantStyle>
        <Form form={form} className="form" labelAlign="right">
            <Form.Item label="邀约项目">
                <Button style={{ width: "100px" }} onClick={() => setIsShowRelatedWorkModal(true)} className="applicant-btn" type="primary" block>关联作品</Button>
                {
                    !!selectWork && <div className="work-detail">
                        <div className="cover" style={{ backgroundImage: `url(${selectWork.coverUrl})` }} />
                        <div className="work-name">{selectWork.title}</div>
                    </div>
                }

            </Form.Item>
            <Form.Item name="content" rules={[
                {
                    required: true,
                    message: "邀约描述",
                }
            ]} label="邀约描述">
                <Input.TextArea autoSize={{ minRows: 5 }} count={{ show: true, max: 500 }} placeholder={desc}></Input.TextArea>
            </Form.Item>

            <div className="btn" style={{ textAlign: 'center' }}>
                <Button onClick={submit} loading={isLoading} type="primary" block>提交</Button>
            </div>
        </Form>
        <Modal className='modal' onCancel={() => setIsShowRelatedWorkModal(false)} footer={<></>} title="关联作品" destroyOnClose={true} centered={true} open={isShowRelatedWorkModal} width={640}>
            <RelatedWork isShowRelate={isShowRelatedWorkModal} saveWork={saveWork} onCancel={() => setIsShowRelatedWorkModal(false)} />
        </Modal>
    </WorkApplicantStyle>

}

export default WorkApplicant