import styled from "styled-components";

export const PuzzleDetailStyle = styled.div`

@font-face {
    font-family: 'AlibabaPuHuiTiR';
    src: url('https://cdn-prd.ggac.com/ggac/font/AlibabaPuHuiTi-2-65-Medium.ttf');
}

@font-face {
  font-family: 'ShuHeiTi';
  src: url('https://cdn.ggac.com/fonts/Alimama_ShuHeiTi_Bold.ttf');
}

.detail {
    background: url("https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-bg.png") center center no-repeat;
    background-size: 100% 100%;
    padding: 50px 0 350px;
    margin-top: -8px;
    font-family: 'AlibabaPuHuiTiR';
    .border1 {
        background: url("https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-border1.png") center center no-repeat;
        background-size: 100% 100%;
        width: 1200px;
        aspect-ratio: 1105 / 448;
        margin: 0 auto;
        position: relative;
        .word {
            position: absolute;
            font-size: 25px;
	        line-height: 42px;
	        color: #484848;
            top: 270px;
            padding: 0 80px;
        }
    }

    .text {
      fill: white;
      text-anchor:middle;
      stroke: #000;
      stroke-width: 9;
      paint-order: stroke;
      stroke-linejoin: round;
    }

    .border2 {
        text-align: center;
        position: relative;
        width: max-content;
        margin: 80px auto 0;
        img {
            width: 1200px;
        }
        .icon {
            width: 214px;
            position: absolute;
            right: -80px;
            bottom: 20px;
        }
    }

    .border8 {
        position: relative;
        width: max-content;
        margin: 150px auto 0;
        width: 1200px;
        img {
            width: 100%;
        }
        .icon {
            width: 214px;
            position: absolute;
            right: -80px;
            bottom: 20px;
        }

        .icon2 {
            width: 232px;
            left: 0;
            top: -20px;
            position: absolute;
        }
    }

    .border3 {
        background: url("https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-border3.png") center center no-repeat;
        background-size: 100% 100%;
        width: 1200px;
        aspect-ratio: 1161 / 1138;
        margin: 150px auto 0;
        position: relative;
        .icon {
            width: 190px;
            position: absolute;
            right: -60px;
            bottom: 30px;
        }
        .border3-tip {
            position: absolute;
            top: 150px;
            left: 50%;
            transform: translateX(-50%);
            color: #0576ea;
            font-size: 25px;
        }
        .content {
            width: 100%;
            height: 100%;
            padding: 250px 80px 0;
            display: flex;
            flex-direction: column;
            gap: 10px;
            &-item {
                font-size: 25px;
                line-height: 42px;
                color: #484848;
                display: flex;
                gap: 5px;
                .sub {
                    padding-left: 30px;
                    .sub-title {
                        color: #0576ea;
                    }
                }
            }
        }
    }

    .border4 {
        background: url("https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-border4.png") center center no-repeat;
        background-size: 100% 100%;
        width: 1200px;
        aspect-ratio: 1105 / 872;
        margin: 150px auto 0;
        position: relative;
        .icon {
            left: -50px;
            position: absolute;
            top: -20px;
        }
        .icon2 {
            right: -100px;
            position: absolute;
            bottom: 20px;
        }

        .content {
            padding: 180px 80px 0;
            font-size: 25px;
	        color: #484848;
            display: flex;
            gap: 10px;
            flex-direction: column;
            .sub-title {
                color: #0576ea;
            }
            .sub {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
            .word {
                line-height: 32px;
            }
            .sub-item {
                display: flex;
                gap: 5px;
            }
        }

        .btns {
            margin-top: 20px;
            display: flex;
            justify-content: center;
            gap: 80px;
            .btn {
                background: url("https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-kv-tab-active.png") center center no-repeat;
                background-size: 100% 100%;
                width: 313px;
                aspect-ratio: 313 / 82;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: 'ShuHeiTi';
                color: #ffffff;
                cursor: pointer;
            }
            .btn2 {
                background: url("https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-kv-tab.png") center center no-repeat !important;
                background-size: 100% 100% !important;
                color: #000;
            }
        }
    }

    .border5 {
        background: url("https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-border5.png") center center no-repeat;
        background-size: 100% 100%;
        width: 1200px; 
        margin: 150px auto 0;
        aspect-ratio: 1105 / 2803;
        position: relative;

        .icon {
            left: -80px;
            position: absolute;
            top: 50px;
        }

        .judge {
            display: flex;
            flex-wrap: wrap;
            gap: 50px;
            justify-content: center;
            padding-top: 230px;
            &-item {
                width: max-content;
                position: relative;
            }

            .img-wrap {
                height: 332px;
                display: flex;
                align-items: flex-end;
            }

            .desc {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 75px;
                bottom: 0;
                left: 0;
                font-size: 16px;
                font-family: 'ShuHeiTi';
                line-height: 20px;
                bottom: 15px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
            }
        }
    }

    .border6 {
        background: url("https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-border6.png") center center no-repeat;
        background-size: 100% 100%;
        width: 1200px; 
        margin: 150px auto 0;
        aspect-ratio: 1105 / 575;
        position: relative;
        .icon {
            position: absolute;
            left: -100px;
            top: 50px;
        }
        .icon2 {
            position: absolute;
            right: 20px;
            top: -40px;
        }
        .icon3 {
            position: absolute;
            right: -100px;
            bottom: -120px;
        }
        .content {
            width: 100%;
            height: 100%;
            padding-top: 250px;
            display: flex;
            justify-content: center;
            gap: 90px;
            &-item {
                width: 264px;
                .name {
                    background: url("https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-border6-tab.png") center center no-repeat;
                    background-size: 100% 100%;
                    width: 264px;
                    aspect-ratio: 264 / 99;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: 'ShuHeiTi';
                    font-size: 30px;
                }
                .word {
                    font-size: 25px;
	                line-height: 32px;
	                letter-spacing: 1px;
	                color: #484848;
                    text-align: center;
                    margin-top: 25px;
                }
            }
        }
    }

    .border7 {
        background: url("https://cdn-prd.ggac.com/ggac/submatch/puzzle/puzzle-border7.png") center center no-repeat;
        background-size: 100% 100%;
        width: 1200px; 
        margin: 150px auto 0;
        aspect-ratio: 1105 / 1707;
        position: relative;
        .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -50px;
        }
        .icon2{
            position: absolute;
            bottom: -67px;
            right: -95px;
        }
        .content {
            width: 100%;
            height: 100%;
            padding: 300px 80px 0;
            display: flex;
            flex-direction: column;
            gap: 15px;
            &-item {
                display: flex;
                gap: 3px;
                color: #484848;
                font-size: 25px;
                line-height: 45px;
            }
        }
    }
}
`