import styled from "styled-components";

export const ApplyCreatorsStyle = styled.div`
    margin-top: 15px;
    .apply-tip {
        text-align: center;
        margin-bottom: 5px;
    }
    button {
        width: 100%;
    }
    .ant-modal-header {
        text-align: center;
    }
    .content {
        display: flex;
        .label {
            width: 50px;
        }
    }
    .btns {
        display: flex;
        gap: 10px;
        justify-content: center;
        margin-top: 15px;
        button {
            width: max-content;
        }
    }
`