import UiAvatar from "ui-lib/avatar";
import { UserCenterProfileStyle } from "./style";
import { useEffect, useRef, useState } from "react";
import { PublicUserStatistics, publicUserDetailType } from "types/user/detail";
import { apiPublicUserDetail, apiPublicUserStatistics } from "api/user/detail";
import { RiEdit2Fill, RiStarFill } from "react-icons/ri";
import UpdateUserName from "./updateUserName";

import { Button, Checkbox, Modal, Popover, message } from "antd";
import { getLocalUserInfo } from "utils/functions/common";
import { apiUpdateUserCfg, postFollowUser } from "api/user";
import { useNavigate, useParams } from "react-router-dom";
import { UserBaseInfo } from "types/user";
import { renderTagline } from "./fn";
import BadgeModal from "components/badge/badge-modal";
import UserBusinessContact from "components/user-business-contact";
import WorkApplicant from "components/work-applicant";

function UserCenterProfile({ type, from, setCommentCount, isCanEdit = false }: { type?: string, setCommentCount?: (count: number) => void, isCanEdit?: boolean, from?: string }) {
  const [statistics, setStatistics] = useState<PublicUserStatistics>();
  const [userDetail, setUserDetail] = useState<publicUserDetailType>();
  const [visible, setVisible] = useState(false);
  const [focusLoading, setFocusLoading] = useState(false)
  const params = useParams();
  let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)
  const [isShowBadgeModal, setIsShowBodgeModal] = useState(false)
  const [isShowBusiness, setIsShowBusiness] = useState(false)
  const [isShowWorkApplicant, setIsShowWorkApplicant] = useState(false)
  const [isCheckYuegao, setIsCheckYuegao] = useState(false)
  const [isCheckAuthorization, setIsCheckAuthorization] = useState(false)
  const [isJudge, setIsJudge] = useState(false) // 判断用户是否是大赛评委和评审团

  const onClose = () => {
    setIsShowBusiness(false)
  }

  const openBadgeModal = () => {
    if (statistics?.badgeCount === 0) {
      return
    }
    setIsShowBodgeModal(true)
  }

  useEffect(() => {
    getDetail();
  }, [params.id]);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!userDetail) {
      return
    }
    const result = userDetail.userRoles.filter(item => [5, 6].includes(item.roleId)) || []
    if (!!result && result.length > 0) {
      setIsJudge(true)
    }

  }, [userDetail])


  const navigate = useNavigate()

  const getDetail = () => {
    let userId = undefined;
    if (type === "user-center") {
      userId = getLocalUserInfo().id;
    }
    if (type === "user-frontpage") {
      userId = params.id;
    }
    if (!userId) {
      return;
    }

    apiPublicUserDetail(userId).then((userDetail) => {
      setUserDetail(userDetail);
    });
    apiPublicUserStatistics({ id: Number(userId) }).then((res) => {
      setCommentCount && setCommentCount(res.commentCount)
      setStatistics(res);
    });
  };

  if (!userDetail) {
    return <></>;
  }

  const updateName = () => {
    getDetail()
  }

  const btnContent = () => {
    return (
      <div style={{ width: "200px" }}>
        <div className="btn-content">
          <Button
            type="primary"
            onClick={() => window.open("/work/create", "_blank")}
          >
            上传作品
          </Button>
        </div>
        <div className="btn-content">
          <Button
            type="primary"
            onClick={() => window.open("/article/create", "_blank")}
          >
            上传文章
          </Button>
        </div>
      </div>
    );
  };

  const updateCfgCopyright = (copyight: boolean) => {
    setIsCheckAuthorization(copyight)
    apiUpdateUserCfg({ isCopyright: Number(copyight) }).then(() => {
      getDetail();
    });
  };
  const updateCfgHandOff = (handoff: boolean) => {
    setIsCheckYuegao(handoff)
    apiUpdateUserCfg({ isHandoff: Number(handoff) }).then(() => {
      getDetail();
    });
  };

  const followUser = () => {
    setFocusLoading(true)
    const flag = userDetail?.isFollow === 1 ? 0 : 1
    postFollowUser(
      {
        followee: userDetail?.id!,
        flag
      }
    ).then(() => {
      message.success("操作成功")
      if (userDetail) {
        userDetail.isFollow = flag
      }
      setUserDetail(userDetail)
    }).finally(() => {
      setFocusLoading(false)
    })
  }



  const toDetail = (value: string) => {
    if (userInfo && (userInfo.id === userDetail.id)) {
      if (value === 'myfans') {
        navigate("/user-center/fans")
      } else if (value === 'focus') {
        navigate("/user-center/followee")
      }
    } else {
      if (value === 'myfans') {
        navigate(`/user/${userDetail.id}/fans`)
      } else if (value === 'focus') {
        navigate(`/user/${userDetail.id}/followee`)
      }
    }
  }

  const toEdit = () => {
    navigate("/user-center/user-detail")
  }

  const content = () => {
    return <div>
      <div style={{ marginBottom: "10px" }}>发布约稿商品，更容易被看到哦</div>
      <Button onClick={() => { navigate("/user-center/shop/commission/product/create"); setIsCheckYuegao(false) }} type="primary" size="small" style={{ display: "block", margin: "0 auto", height: "30px", lineHeight: "30px" }}>立即前往</Button>
    </div>
  }
  const authorizationContent = () => {
    return <div>
      <div style={{ marginBottom: "10px" }}>发布授权商品，更容易被看到哦</div>
      <Button onClick={() => { navigate("/user-center/shop/product/create?code=authorization"); setIsCheckAuthorization(false) }} type="primary" size="small" style={{ display: "block", margin: "0 auto", height: "30px", lineHeight: "30px" }}>立即前往</Button>
    </div>
  }

  const clickApplicant = () => {
    if (!userDetail.isCooperateWhite) {
      setIsShowBusiness(true)
    } else {
      setIsShowWorkApplicant(true)
    }
  }

  return (
    <UserCenterProfileStyle>
      <div className="avatar">
        <UiAvatar
          url={userDetail?.avatarUrl}
          username={userDetail?.username}
          certifiedRoleId={userDetail?.certifiedRoleId}
          userRoles={userDetail?.userRoles}
        ></UiAvatar>
        {
          isCanEdit && <div className="block" onClick={toEdit}>
            编辑
          </div>
        }
      </div>

      <div className="user-name">
        <div className="name" title={userDetail.username} ref={ref}>{userDetail.username}</div>
        <div className="edit-box">
          {type === "user-center" && (
            <RiEdit2Fill onClick={() => setVisible(true)} className="edit" />
          )}
          <div className="tag" onClick={() => navigate(`/user-center/quests`)}> Lv.{userDetail.level}</div>
        </div>
        <UpdateUserName
          username={userDetail.username}
          updateVisible={() => {
            setVisible(false)
            window.location.reload()
          }}
          visible={visible}
          updateName={updateName}
        />
      </div>
      <div className="badge-count" onClick={openBadgeModal}>
        <div className="icon">
          <RiStarFill></RiStarFill>
        </div>
        <span>徽章{statistics?.badgeCount}枚</span>
      </div>
      <div className="user-info">
        {renderTagline(userDetail)}
        <div className="user-signature">
          <span>{userDetail.userInfo.signature}</span>
        </div>
      </div>
      {type === "user-center" && (
        <div className="user-config">
          <Popover placement="bottom" trigger="click" onOpenChange={(e) => !e ? setIsCheckYuegao(false) : ''} open={isCheckYuegao} content={content}>
            <Checkbox
              onChange={(e) => updateCfgHandOff(e.target.checked)}
              checked={Boolean(userDetail.userInfo.isHandoff)}
            >
              约稿
            </Checkbox>
          </Popover>
          <Popover placement="bottom" trigger="click" onOpenChange={(e) => !e ? setIsCheckAuthorization(false) : ''} open={isCheckAuthorization} content={authorizationContent}>
            <Checkbox
              onChange={(e) => updateCfgCopyright(e.target.checked)}
              checked={Boolean(userDetail.userInfo.isCopyright)}
            >
              版权
            </Checkbox>
          </Popover>
        </div>
      )}
      {type === "user-center" && (
        <div className="upload-btns">
          <Popover
            placement="bottom"
            content={btnContent}
            getPopupContainer={(trigger) => trigger}
            trigger="click"
            className="content-wrap"
          >
            <Button block className="btn" type="primary">
              <span className="text">上传 </span>
            </Button>
          </Popover>
        </div>
      )}
      {type === "user-frontpage" &&
        !(Number(params.id) === getLocalUserInfo()?.id) && (
          <>
            {!userDetail?.isFollow && (
              <Button block type="primary" onClick={followUser} loading={focusLoading}>
                <span className="text">关注</span>
              </Button>
            )}
            {Boolean(userDetail?.isFollow) && (
              <Button block type="default" onClick={followUser} loading={focusLoading} className="defaultBtn">
                <span className="text">已关注</span>
              </Button>
            )}
            {
              userDetail.isCooperateWhite === 0 && userDetail.userInfo.isCooperation === 1 && <Button block type="default" className="defaultBtn" onClick={clickApplicant}>
                合作邀约
              </Button>
            }
            {
              userDetail.isCooperateWhite === 1 && ((!isJudge) ||
                (!!isJudge && userDetail.userInfo.isCooperation === 1))
              && <Button block type="default" className="defaultBtn" onClick={clickApplicant}>
                合作邀约
              </Button>
            }
          </>
        )}
      <div className="statistics">
        <div className="item">
          <span>浏览量</span>
          <span className="num">{statistics?.viewCount}</span>
        </div>
        <div className="item">
          <span>点赞</span>
          <span className="num">{statistics?.likeCount}</span>
        </div>
        <div className="item pointer" onClick={() => toDetail("focus")}>
          <span>关注</span>
          <span className="num">{statistics?.followCount}</span>
        </div>
        <div className="item pointer" onClick={() => toDetail("myfans")}>
          <span>粉丝</span>
          <span className="num">{statistics?.fansCount}</span>
        </div>
      </div>
      <BadgeModal isShowRodio={from === 'user-center'} closeModal={() => setIsShowBodgeModal(false)} badgeCount={statistics?.badgeCount!} isShow={isShowBadgeModal} userDetail={userDetail} />
      {userDetail && <UserBusinessContact partnerUser={{ name: userDetail?.username, id: userDetail?.id }} onClose={onClose} isShow={isShowBusiness} />}
      {userDetail && <Modal className='modal' onCancel={() => setIsShowWorkApplicant(false)} footer={<></>} title="合作邀约" destroyOnClose={true} centered={true} open={isShowWorkApplicant} width={640}>
        <WorkApplicant userInfo={userInfo} partnerUser={{ name: userDetail?.username, id: userDetail?.id }} onClose={() => setIsShowWorkApplicant(false)} />
      </Modal>
      }
    </UserCenterProfileStyle>
  );
}

export default UserCenterProfile;
