export enum MediaType {
     image =  1,
     video= 2,
     threed= 3,
     audio = 4,
     marmoset= 5,
     multiImage = 10
}

export const ThemeTypeArr  = [
    {name: '图片',  value: MediaType.image },
    {name: '视频',  value: MediaType.video },
    {name: '3D',  value: MediaType.threed },
    {name: '八猴',  value: MediaType.marmoset }

]

export enum CosDirectoryType{
    workImage ='work/image',
    workVideo ='work/video',
    workCover ='work/cover',
    workMobileCover ='work/cover/mobile',
    workShare = 'work/share',
    workModel = 'work/model',
    articleImage ='article/image',
    articleVideo ='article/video',
    articleCover ='article/cover',
    userAvatar ='user/avatar',
    userBackground ='user/background',
    reportImage= 'report/image',
    productImage= 'product/image',
    productVideo= 'product/video',
    productAttatchment = 'product/attachment',
    authenticationIdCard = 'user/authentication/idCard',
    authenticationStudent = 'user/authentication/student',
    forumImage = 'forum/image',
    forumVideo = 'forum/video',
    nftImage= 'nft/image',
    workAudio= 'work/audio',
    forumFile= 'forum/file',
    workShareCrop = 'work/image/share/crop',
    commentImage = 'comment/work/image',
    commentUserImage= 'comment/user/image',
}

