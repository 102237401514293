import {
    Form,
    Input,
    Radio,
    Button,
    message,
    Tooltip,
    Checkbox,
    Modal,
    InputNumber,
    CheckboxProps,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useState, useEffect, useCallback, useRef } from "react";
import { UploadFile } from "antd/es/upload/interface";
import { getFileUrlByCosUpload } from "config/cos";
import {
    bizCommodityCreateParams,
    skuListItem,
} from "types/commodity";
import { apiPostCreateCommdity, apiGetCommodityDetail } from "api/commodity";
import { useParams, useSearchParams } from "react-router-dom";
import { useGRoutes } from "utils/hooks/useGRoute";
import PageHeader from "views/user-shop/layout/pageHeader";
import { MallProductCategoryListItem } from "types/product";
import {
    apiGetWorkDraftDetail,
    importContentFromWork,
} from "api/work";
import TinyEditor from "ui-lib/tinyMCE";
import { apiGetMallProductListCategory, apiGetMallThemeStyleList, apiGetMallWorkTypeList, apiGetShippingFeeTemplateList } from "api/shop";
import { ImageCrop } from "components/crop-img";
import { getFileName } from "utils/functions/common";
import { CosDirectoryType } from "types/enums/media-type";
import { apiGetCategoryToProductWorktype, apiGetDictList } from "api/common";
import { CfgCategoryToProductWorktypeResponse, DictItem } from "types/common";


import 'dayjs/locale/zh-cn'
import dayjs from "dayjs";
import { CreationWare } from "views/work/update/creationWare";
import { CommissionCreateStyle } from "./style";
import { formatMoney } from "../fn";
import TypeSelect from "../typeSelect";
import { ProductPicturesWall } from "../../uploadImageList";
import { ProductTagSelect } from "../tagSelect";
import RelateWork from "../relateWork";
import ShopYuegaoAgreement from "../yuegaoAgreement";

const CheckboxGroup = Checkbox.Group;

const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};

type imageType = {
    url: string;
    key: string;
    percent: number;
};

type submitParams = {
    coverUrl: {
        file?: File;
        imgUrl?: string;
    };
    imageList: imageType[];
    copyrightCertificates: UploadFile[];
    description: { html: string; media: number[] };
    categoryId: number;
    categoryIds: number[]
    type: number;
    name: string;
    quantity: number | string;
    rmbPrice: number | string;
    submit: number;
    skuList: skuListItem[];
    deliveryFee?: number;
    freeDelivery?: number;
    pcd?: number[]; //省市区
    shippingFeeTemplateId?: number
    tagList: string[]
    lastSendTime: Date
    lastSendType: number
    originalPrice: number
    themeStyle: string[]
    workType: string[]
    customWorkType: string
    customThemeStyle: string,
    goodAtSoftware: string[]
};

type workType = {
    coverUrl: string;
    id: number;
    title: string;
};

const CommissionCommodityCreate = () => {
    const [form] = Form.useForm<submitParams>();
    const categoryId = Form.useWatch("categoryId", form);
    const description = Form.useWatch("description", form);
    const type = Form.useWatch("type", form);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const { id } = useParams();
    let [searchParams] = useSearchParams();
    const draftId = searchParams.get("draftId");
    const [skuList, setSkuList] = useState<skuListItem[]>([]);
    const gRouter = useGRoutes();
    const [messageApi, contextHolder] = message.useMessage();
    const [isShowRelate, setIsShowRelate] = useState(false);
    const [relatdWorkList, setRelatdWorkList] = useState<workType[]>([]);
    const [typeList, setTypeList] = useState<DictItem[]>([]);
    const [isAgreeYuegao, setIsAgreeYuegao] = useState(false)
    const [isShowShopYuegaoAgreement, setIsShowShopYuegaoAgreement] = useState(false)
    const [isCustomWorkType, setIsCustomWorkType] = useState(false)
    const [isCustomThemeStyle, setIsCustomThemeStyle] = useState(false)

    const [mallWorkTypeList, setMallWorkTypeList] = useState<{ label: string, value: string }[]>([])
    const [mallThemeStyleList, setMallThemeStyleList] = useState<{ label: string, value: string }[]>([])

    const [isPrivateInquiry, setIsPrivateInquiry] = useState(0);
    const [productWorkTypeList, setProductWorkTypeList] = useState<CfgCategoryToProductWorktypeResponse[]>([])


    const childRef = useRef() as React.MutableRefObject<any>;

    const [importAppendixProps, setImportAppendixProps] = useState({
        visible: false,
        visibleChange: (visible: boolean) => {
            setImportAppendixProps({
                ...Object.assign(importAppendixProps, { visible: visible }),
            });
        },
    });

    const breadcrumbs = [
        {
            title: "约稿商品列表",
        },
        {
            title: "创建约稿商品",
        },
    ];

    const isChoosePrivateInquiry: CheckboxProps['onChange'] = (e) => {
        setIsPrivateInquiry(e.target.checked == true ? 1 : 0);
    };

    const getDetail = useCallback(async () => {
        let result;
        if (mallWorkTypeList.length === 0) {
            return
        }

        if (mallThemeStyleList.length === 0) {
            return
        }
        if (id) {
            result = await apiGetCommodityDetail(+id!);
            if (result?.skuList.length > 0) {
                result?.skuList.forEach((item) => {
                    item.rmbPrice = Number((item.rmbPrice / 100).toFixed(2));
                    item.originalPrice = Number((item.originalPrice / 100).toFixed(2));
                });
            }
            setSkuList(result?.skuList);
        }
        const imageList = result?.imageList;
        const copyrightCertificates = result?.copyrightCertificates;
        let pcd = undefined;
        if (result?.deliveryProvinceId && result?.deliveryCityId) {
            pcd = [result?.deliveryProvinceId, result?.deliveryCityId];
        }
        const arr: imageType[] = [];
        imageList?.forEach((item, index) => {
            arr.push({
                key: String(index),
                url: item,
                percent: 100,
            });
        });

        const arr1: UploadFile[] = [];
        copyrightCertificates?.forEach((item, index) => {
            arr1.push({
                uid: String(index),
                url: item,
                name: item,
            });
        });
        form.setFieldsValue({
            coverUrl: {
                file: undefined,
                imgUrl: result?.coverUrl,
            },
            imageList: arr,
            description: {
                html: result?.description,
                media: [],
            },
            name: result?.name,
            categoryId: result?.categoryList[1].id,
            submit: 1,
            skuList: result?.skuList,
            copyrightCertificates: arr1,
            deliveryFee: formatMoney(result?.deliveryFee),
            freeDelivery: result?.freeDelivery,
            pcd: pcd,
            shippingFeeTemplateId: result?.shippingFeeTemplateId,
            tagList: result?.tagList?.map(item => item.name),
            lastSendType: result?.lastSendType === undefined ? 1 : result?.lastSendType,
            lastSendTime: result?.lastSendTime ? dayjs(result?.lastSendTime) : '',
            workType: result?.workTypeList ? result?.workTypeList.map(item => item = String(item)) : [],
            themeStyle: result?.themeStyleList ? result?.themeStyleList.map(item => item = String(item)) : [],
        });

        setIsPrivateInquiry(result?.skuList[0].isPrivateInquiry || 0)

        const option = mallWorkTypeList.find(item => item.label === '其他')
        if (!!option && result?.workTypeList.includes(Number(option.value))) {
            setIsCustomWorkType(true)
        }
        if (!result?.manuscriptParams) {
            return
        }
        const manuscriptParams = JSON.parse(String(result?.manuscriptParams))
        const parseWorkType: string[] = manuscriptParams.workType.split(",")
        // 过滤不在YueGaoConfig?.workTypes中的字符串
        const FindWorkType = parseWorkType.filter(item => {
            if (item === '其他') {
                return true
            }
            const index = mallWorkTypeList.findIndex(sub => sub.label === item)
            return !(index >= 0)
        })

        if (FindWorkType && FindWorkType.length > 0) {
            form.setFieldValue("customWorkType", FindWorkType.join(","))
        }
        const parseThemeStyle: string[] = !!manuscriptParams.themeStyle ? manuscriptParams.themeStyle.split(",") : []
        const FindThemeStyle = parseThemeStyle.filter(item => {
            if (item === '其他') {
                return true
            }
            const index = mallThemeStyleList.findIndex(sub => sub.label === item)
            return !(index >= 0)
        })

        if (FindThemeStyle && FindThemeStyle.length > 0) {
            setIsCustomThemeStyle(true)
            form.setFieldValue("customThemeStyle", FindThemeStyle.join(","))
        }

        form.setFieldValue("originalPrice", result?.skuList[0].originalPrice)
        if (manuscriptParams.goodAtSoftware) {
            form.setFieldValue("goodAtSoftware", manuscriptParams.goodAtSoftware.split(","))
        }

        if (result && result?.workList.length > 0) {
            const obj = result.workList.map((item) => {
                return {
                    id: item.id,
                    coverUrl: item.coverUrl,
                    title: item.title,
                };
            });
            setRelatdWorkList(obj);
        }
    }, [form, id, mallWorkTypeList, mallThemeStyleList]);

    useEffect(() => {
        getTypeList();
    }, []);

    const [newType, setNewType] = useState(0)
    const [oldType, setOldType] = useState(0)

    useEffect(() => {
        const type = form.getFieldValue("type")
        setNewType(type)
        setOldType(newType)
    }, [form.getFieldValue("type")])

    useEffect(() => {
        if ((newType === 2 && oldType === 1) || (newType === 1 && oldType === 2)) {
            if (childRef.current) {
                childRef.current.updateSkuList([]);
            }
        }
    }, [newType, oldType]); // 确保当data或oldData变化时执行

    useEffect(() => {
        if (type !== 2) {
            form.setFieldValue("lastSendType", 1)
        }
    }, [type])

    const getTypeList = () => {
        apiGetDictList({ type: "mallProductType" }).then((res) => {
            setTypeList(res);
        });
        apiGetMallWorkTypeList().then(res => {
            const option: { label: string, value: string }[] = []
            res.forEach(item => {
                option.push({ label: item.name, value: String(item.id) })
            })
            setMallWorkTypeList(option);
        })

        apiGetMallThemeStyleList().then(res => {
            const option: { label: string, value: string }[] = []
            res.forEach(item => {
                option.push({ label: item.name, value: String(item.id) })
            })
            setMallThemeStyleList(option);
        })
    };

    const saveWork = (value: workType[]) => {
        setRelatdWorkList(value);
    };

    useEffect(() => {
        apiGetMallProductListCategory({ code: "yuegao-1", level: 2 }).then((res) => {
            form.setFieldValue("categoryIds", [res[0].pid, res[0].id])
            form.setFieldValue("type", res[0].productType);
        });
        apiGetCategoryToProductWorktype().then(res => {
            setProductWorkTypeList(res)
        })
    }, []);

    useEffect(() => {
        setConfirmLoading(false);
        getDetail();
    }, [getDetail]);

    const changeWorkType = (detail: string[]) => {
        const option = mallWorkTypeList.find(item => item.label === '其他')
        if (!!option && detail.includes(String(option.value))) {
            setIsCustomWorkType(true)
        } else {
            setIsCustomWorkType(false)
        }
    }

    const changeThemeStyle = (detail: string[]) => {
        const option = mallThemeStyleList.find(item => item.label === '其他')
        if (!!option && detail.includes(String(option.value))) {
            setIsCustomThemeStyle(true)
        } else {
            setIsCustomThemeStyle(false)
        }
    }

    // 保存
    const save = () => {
        if (!isAgreeYuegao) {
            message.warning("请勾选且阅读《约稿商品出售协议》")
            return
        }
        form.validateFields().then(async (res) => {
            const {
                coverUrl,
                description,
                name,
                submit,
                type,
                imageList,
                tagList,
                categoryIds,
                originalPrice,
                themeStyle,
                workType,
                customWorkType,
                customThemeStyle,
                goodAtSoftware
            } = res;

            if (!description.html) {
                message.error("商品描述为空");
                return;
            }

            if (!coverUrl.file && !coverUrl.imgUrl) {
                message.error("商品封面不能为空");
                return;
            }

            setConfirmLoading(true);

            const copySkuList: skuListItem[] = JSON.parse(JSON.stringify(skuList));

            if (copySkuList.length > 0) {
                copySkuList.forEach((item) => {
                    item.originalPrice = Number(item.originalPrice * 100);
                    if (item.rmbPrice === undefined) {
                        item.rmbPrice = item.originalPrice;
                    } else {
                        item.rmbPrice = Number(item.rmbPrice * 100);
                    }
                });
            }
            const params: bizCommodityCreateParams = {
                coverUrl: coverUrl.imgUrl,
                description: description.html,
                name,
                type: Number(type),
                categoryId: categoryIds[1],
                submit,
                id: Number(id) ? Number(id) : 0,
                workIdList: relatdWorkList
                    .filter((item) => item.id !== 0)
                    .map((item) => item.id),
                imageList: imageList.map((item) => item.url),
                deliveryFee: 0,
                workDraftId: draftId ? Number(draftId) : undefined,
                tagList: tagList
            };

            params.workTypeList = workType.map(item => Number(item))
            params.themeStyleList = themeStyle.map(item => Number(item))
            params.skuList = [
                {
                    name,
                    originalPrice: originalPrice || 0,
                    rmbPrice: originalPrice || 0,
                    coverUrl: String(coverUrl?.imgUrl),
                    quantity: 9999999,
                    attachmentList: [],
                    fileFormat: "",
                    authorizationMark: 0,
                    isPrivateInquiry: !!isPrivateInquiry ? 1 : 0,
                    isManuscript: 1,
                    id: 0
                }
            ]
            params.skuList[0].originalPrice = Number(originalPrice * 100) || 0;
            params.skuList[0].rmbPrice = params.skuList[0].originalPrice || 0

            const workTypeLabelArr = []

            const mallWorkTypeIndex = mallWorkTypeList.findIndex(item => item.label === '其他')


            mallWorkTypeList.forEach(item => {
                if (workType.includes(item.value)) {
                    if (!(mallWorkTypeIndex >= 0 && item.value === mallWorkTypeList[mallWorkTypeIndex].value)) {
                        workTypeLabelArr.push(item.label)
                    }
                }
            })

            if (!!customWorkType) {
                workTypeLabelArr.push(customWorkType)
            }
            const copyList: { label: string, value: string }[] = JSON.parse(JSON.stringify(mallThemeStyleList))
            const themeStyleIndex = copyList.findIndex(item => item.label === '其他')
            if (themeStyleIndex >= 0) {
                copyList.splice(themeStyleIndex, 1)
            }

            const themeStyleLabelArr = copyList.filter(item => themeStyle.includes(item.value)).map(item => item.label)
            if (!!customThemeStyle) {
                themeStyleLabelArr.push(customThemeStyle)
            }

            params.manuscriptParams = {
                workType: workTypeLabelArr.join(","),
                themeStyle: themeStyleLabelArr.join(","),
                goodAtSoftware: goodAtSoftware && goodAtSoftware.length > 0 ? goodAtSoftware.join(",") : ''
            }

            if (id) {
                params.id = Number(id);
            }
            apiPostCreateCommdity(params)
                .then(() => {
                    if (res.submit === 0) {
                        gRouter(
                            `/user-center/shop/commission/product-list?onSale=0&status=0&tabKey=4`,
                            { replace: true }
                        );
                    } else {
                        gRouter(
                            `/user-center/shop/commission/product-list?onSale=0&status=1,3&tabKey=2`,
                            { replace: true }
                        );
                    }
                    messageApi.open({
                        type: "success",
                        content: id ? "修改成功" : "创建成功",
                    });
                })
                .finally(() => {
                    setConfirmLoading(false);
                });
        });
    };

    const upload = async (params: { imgUrl: string; file: File | undefined }) => {
        if (params.file) {
            let urlKey = getFileName(CosDirectoryType.productImage, params.file.name);
            let res = await getFileUrlByCosUpload(params.file!, urlKey);
            form.setFieldValue("coverUrl", {
                file: undefined,
                imgUrl: res.url,
            });
        }
    };

    const insertHtmlToDesc = (value: string) => {
        let a = (description.html ?? "") + value;
        form.setFieldValue("description", {
            html: a,
            media: [],
        });
        message.success("已成功复制到商品描述！");
    };

    useEffect(() => {
        if (!!draftId && !id && productWorkTypeList.length > 0) {
            setFormWithWorkInfo();
        }
    }, [draftId, productWorkTypeList]);

    const setFormWithWorkInfo = () => {
        if (draftId) {
            apiGetWorkDraftDetail(Number(draftId)).then((res) => {
                const result = productWorkTypeList.find(item => item.categoryId === res.categoryList[1].id)
                const imageList = res.mediaList
                    .filter((item) => {
                        return item.type === 1;
                    })
                    .map((item, index) => {
                        return {
                            key: String(index),
                            url: item.resizedUrl,
                            percent: 100,
                        };
                    });
                form.setFieldsValue({
                    coverUrl: {
                        file: undefined,
                        imgUrl: res?.coverUrl,
                    },
                    imageList: imageList ?? [],
                    name: res?.title,
                    workType: result?.workTypeList.map(item => String(item))
                });
                setRelatdWorkList([
                    {
                        id: 0,
                        coverUrl: res?.coverUrl,
                        title: res?.title,
                    },
                ]);
                importContentFromWork({ id: Number(draftId) }).then((res) => {
                    form.setFieldValue("description", {
                        html: res,
                        media: [],
                    });
                });
            });
        }
    }

    const openAgreeYuegao = (e: any) => {
        e.stopPropagation()
        setIsShowShopYuegaoAgreement(true)
    }

    return (
        <CommissionCreateStyle>
            <PageHeader
                breadcrumbs={breadcrumbs}
                link={() => (window.location.href = "/user-center/shop/commission/product-list")}
            ></PageHeader>
            <div className="content">
                {contextHolder}
                <Form
                    {...formItemLayout}
                    className="create"
                    style={{ maxWidth: 700 }}
                    labelAlign="left"
                    form={form}
                >
                    <Form.Item
                        name="categoryIds"
                        label="商品类目"
                        rules={[{ required: true, message: "请选择商品类目" }]}
                    >
                        <TypeSelect isYuegao={true} disabled={true} />
                    </Form.Item>

                    <Form.Item
                        name="type"
                        label="商品类型"
                        rules={[{ required: true, message: "商品类型" }]}
                    >
                        <Radio.Group disabled>
                            {typeList.map((item, index) => {
                                return (
                                    <Radio value={item.code} key={index}>
                                        {item.name}
                                    </Radio>
                                );
                            })}
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="name"
                        label="商品名称"
                        rules={[{ required: true, message: "请选择商品名称" }]}
                    >
                        <Input type="text" maxLength={30} placeholder="请输入商品名称" />
                    </Form.Item>
                    <Form.Item
                        name="coverUrl"
                        label="商品封面图"
                        rules={[{ required: true, message: "请上传商品封面图" }]}
                        extra="建议尺寸700*500"
                    >
                        {/* <ImageUpload /> */}
                        <ImageCrop uploadImage={upload} />
                    </Form.Item>

                    <Form.Item
                        name="imageList"
                        label="商品图片"
                        rules={[{ required: true, message: "请上传商品图片" }]}
                        extra="请上传约稿商品样图，建议尺寸800*450，至少上传3张图片，可以更好的展示您的商品"
                    >
                        <ProductPicturesWall maxLength={15} />
                    </Form.Item>

                    <div className="originalPrice">
                        <Form.Item
                            name="originalPrice"
                            label="价格"
                            rules={[{ required: isPrivateInquiry == 1 ? false : true, message: "请输入商品价格" }, {
                                validator: (rule, val, callback) => {
                                    if (val <= 100000 || !val) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject('最大价格不能超过100000')
                                    }
                                }
                            }]}
                        >
                            <InputNumber
                                min={0}
                                size="middle"
                                placeholder="请输入商品价格"
                                disabled={isPrivateInquiry == 1}
                            />
                        </Form.Item>
                        <div className="text">起</div>
                        <div className="checkout">
                            <Checkbox checked={isPrivateInquiry == 1 ? true : false} onChange={isChoosePrivateInquiry}>私聊询价</Checkbox>
                        </div>
                    </div>
                    <Form.Item label="稿件参数" className="yuegao"></Form.Item>
                    <Form.Item
                        name="workType"
                        label="作品类型"
                        rules={[{ required: true, message: "请选择作品类型" }]}
                    >
                        <CheckboxGroup onChange={changeWorkType} options={mallWorkTypeList.map(item => { return { label: item.label, value: String(item.value) } })} />
                    </Form.Item>
                    {
                        isCustomWorkType && <Form.Item
                            name="customWorkType"
                            label="其他作品类型"
                            rules={[{ required: true, message: "请选择其他作品类型" }]}
                        >
                            <Input type="text" maxLength={20} placeholder={`多个其他作品类型请用"，"隔开`} />
                        </Form.Item>
                    }
                    <Form.Item
                        name="themeStyle"
                        label="题材风格"
                        rules={[{ required: true, message: "请选择题材风格" }]}
                    >
                        <CheckboxGroup onChange={changeThemeStyle} options={mallThemeStyleList.map(item => { return { label: item.label, value: String(item.value) } })} />
                    </Form.Item>
                    {
                        isCustomThemeStyle && <Form.Item
                            name="customThemeStyle"
                            label="其他题材风格"
                            rules={[{ required: true, message: "请选择其他题材风格" }]}
                        >
                            <Input type="text" maxLength={20} placeholder={`多个其他题材风格请用"，"隔开`} />
                        </Form.Item>
                    }
                    <Form.Item label="擅长软件" name="goodAtSoftware">
                        <CreationWare type={1} selectMode="multiple"></CreationWare>
                    </Form.Item>

                    <Form.Item label="关联G站作品">
                        <Button type="primary" onClick={() => setIsShowRelate(true)}>
                            关联G站作品
                        </Button>
                        <span className="tips" style={{ paddingTop: 8 }}>
                            关联G站作品可一键复制作品内容到商品描述（作品描述、作品图片及视频）
                        </span>
                        <div className="work-wrap">
                            {relatdWorkList?.map((item) => {
                                return (
                                    <div key={item.id} className="work-item">
                                        <div
                                            className="cover"
                                            style={{ backgroundImage: `url(${item.coverUrl})` }}
                                        >
                                            {item.id === 0 && (
                                                <Tooltip title="作品上架后展示关联关系">
                                                    <div className="warning">
                                                        <InfoCircleOutlined />
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </div>
                                        <div className="name">{item.title}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label={
                            <>
                                商品描述{" "}
                                <span className="tips" style={{ paddingLeft: 8 }}>
                                    据小G观察好好描述您的商品，可以增加大大提高销量哦
                                </span>
                            </>
                        }
                        rules={[{ required: true, message: "请输入商品描述" }]}
                        className="description"
                        labelCol={{ span: 24, offset: 24 }}
                    >
                        <TinyEditor width={800} cosImagePath={CosDirectoryType.productImage} cosVideoPath={CosDirectoryType.productVideo}></TinyEditor>
                    </Form.Item>
                    <Form.Item label="标签" name="tagList" labelCol={{ span: 24, offset: 24 }} wrapperCol={{ span: 24 }}>
                        <ProductTagSelect dataTable="mall_product" categoryId={categoryId} maxCount={5}></ProductTagSelect>
                    </Form.Item>
                    <Form.Item name="submit" label="是否提交审核">
                        <Radio.Group defaultValue={1}>
                            <Radio value={0}>保存草稿</Radio>
                            <Radio value={1}>提交审核，审核通过后自动上架</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <div className="product-tip">
                        <div className="flex">
                            <div className="bold">重要提醒：</div>
                            <div className="red">非官方验证的站外交易都是诈骗!</div>
                        </div>
                        <div className="flex">
                            <div className="bold margin20">遇可疑行为→速联官方或110!</div>
                            <div>官方客服微信号:GGAC2025</div>
                        </div>
                    </div>
                    <div className="agreement-tip">
                        <Checkbox checked={isAgreeYuegao} onChange={e => { setIsAgreeYuegao(e.target.checked) }}>已阅读并同意《<h6 onClick={(e) => openAgreeYuegao(e)}>约稿商品出售协议</h6>》</Checkbox>
                    </div>
                    <div className="button-wrap">
                        <Button
                            type="primary"
                            loading={confirmLoading}
                            onClick={() => save()}
                        >
                            {id ? "修改约稿商品" : "创建约稿商品"}
                        </Button>
                    </div>
                </Form>

                <RelateWork
                    relatdWorkList={relatdWorkList}
                    saveWork={saveWork}
                    isShowRelate={isShowRelate}
                    onCancel={() => setIsShowRelate(false)}
                    onImportContent={insertHtmlToDesc}
                ></RelateWork>
            </div>
            <Modal width={700} footer={[<Button type="primary" onClick={() => setIsShowShopYuegaoAgreement(false)}>
                确定
            </Button>]} title="约稿商品出售协议" destroyOnClose={true} closable={false} centered={true} open={isShowShopYuegaoAgreement}>
                <ShopYuegaoAgreement />
            </Modal>
        </CommissionCreateStyle>
    );
};

export default CommissionCommodityCreate;
