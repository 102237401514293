import styled from "styled-components";

export const RelatedWorkStyle = styled.div`
    .wrap {
        gap: ${props => props.theme.space.marginXS}px;
        min-height: 100px;
        .active {
            border: 3px solid ${props => props.theme.colors.colorPrimary} !important;
            border-radius: ${props => props.theme.border.borderRadius}px;
            overflow: hidden;
        }

        .work-wrap {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            .work-item {
                width: calc((100% - 30px) / 4);
            }
        }
        .work {
            width: 100%;
            border: 3px solid transparent;
            border-radius: ${props => props.theme.border.borderRadiusSM}px;
            overflow: hidden;
            .cover {
                width: 100%;
                height: 100px;
                background-size: cover;
                cursor: pointer;
            }
            .name {
                text-align: center;
                word-break: break-all;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-clamp: 1;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                padding: 4px 2px;
                width: 100%;
            }
            
        }
    }

    .ant-pagination {
        margin-bottom: 15px !important;
    }

    .btns {
        display: flex;
        justify-content: center;
        gap: 30px;
    }
`