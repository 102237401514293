import styled from "styled-components"

export const YdbzDetailStyle = styled.div`
    @font-face {
        font-family: 'AlibabaPuHuiTiR';
        src: url('https://cdn-prd.ggac.com/ggac/font/AlibabaPuHuiTi.ttf');
    }
    @font-face {
      font-family: 'ShuHeiTi';
      src: url('https://cdn.ggac.com/fonts/Alimama_ShuHeiTi_Bold.ttf');
    }
    margin-top: -6px;
    position: relative;
    min-width: 1300px;
    font-family: 'AlibabaPuHuiTiR';

    .content {
        width: 100%;
        background: url("https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-bg1.jpg") center center no-repeat;
        background-size: 100% 100%;
        padding-top: 50px;
        overflow: hidden;
        &>div{
            width: 100%;
        }
    }
    .part1-content {
        background: url("https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-part1-desc.png") center center no-repeat;
        background-size: cover;
        width: 1134px;
        height: 384px;
        margin: 0 auto 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 24px;
	    line-height: 45px;
	    letter-spacing: 3px;
	    color: #ffffff;
        span {
            color: #68f0ff;
            font-style: italic;
        }
    }
    .title {
        font-family: 'ShuHeiTi';
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 79px;
        position: relative;
        &-word {
            display: flex;
            align-items: center;
            font-size: 64px;
	        font-weight: normal;
	        font-stretch: normal;
	        letter-spacing: 13px;
	        color: #15435e;
            margin: 0 40px 0 50px;
            /* text-shadow: 0px 2px 3px rgba(0,45,107,0.95); */
            background: linear-gradient(0deg, #48DAFF 0%, #F7FEFF 50%);
            background: -webkit-linear-gradient(90deg, #48DAFF 0%, #F7FEFF 50%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            img {
                width: 17px;
                margin-right: 13px;
                margin-top: 10px;
            }
            
        }
        .arrow {
            width: 152px;
            margin-top: 10px;
        }

        .top {
            position: absolute;
            top: 25px;
            left: 50%;
            transform: translateX(-50%);
            span {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                font-size: 36px;
	            letter-spacing: 5px;
	            background: linear-gradient(0deg, #F7FEFF 0%, #8EE8FF 100%);
                opacity: 0.5;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            img {
                width: 142px;
            }
        }
    }
    .time {
        display: flex;
        justify-content: center;
        position: relative;
        .line {
            position: absolute;
            width: 100%;
            left: 0;
            top: 120px;
            z-index: 0;
        }
        &-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 1;
            .text-wrap {
                width: 165px;
                height: 257px;
                background: url("https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-date.png") center center no-repeat;
                background-size: cover;
                .text {
                    font-size: 26px;
	                line-height: 35px;
	                letter-spacing: 3px;
	                color: #f1f8ff;
                    width: 58px;
                    margin: 45px auto 0;
                    text-align: center;
                }
            }
            .date {
                font-size: 20px;
	            letter-spacing: 1px;
	            color: #ffffff;
                margin-top: -20px;
                text-align: center;
            }
            &:first-child {
                margin-right: 180px;
                margin-top: 80px;
            }
            &:nth-child(2) {
                margin-right: 130px;
            }
            &:nth-child(3) {
                margin-top: 80px;
            }
        }
    }
    
    .part2 {
        margin-top: 400px;
        .list2 {
            .list-item {
                width: 284px !important;
                height: 426px !important;
                background: url("https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-part2-bottom.png") center center no-repeat !important;
                background-size: cover !important;
                .text3 {
                    margin-top: 30px;
                    padding-left: 20px;
                }
            }
        }
        .list {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 160px;
            color: #fff;
            margin-top: 50px;
            &-item {
                display: flex;
                flex-direction: column;
                padding-top: 65px;
                width: 354px;
                height: 570px;
                background: url("https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-part2-top.png") center center no-repeat;
                background-size: cover;
                align-items: center;

                .img1 {
                    height: 17px;
                    width: 223px;
                }

                .wrap {
                    text-align: center;
                }

                .num {
                    margin-bottom: 10px;
                    font-size: 24px;
                }

                .sub-title {
                    font-size: 24px;
	                line-height: 40px;
	                letter-spacing: 1px;
	                color: #ffffff;
                    text-align: center;
                }
                
                .text2 {
                    font-size: 18px;
                    margin: 20px 0;
                }

                .text3 {
                    text-align: left !important;
                    font-size: 18px;
                    line-height: 30px;
                    div {
                        position: relative;
                        padding-left: 20px;
                    }
                    div::after {
                        content: "";
                        position: absolute;
                        background: url("https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-part2-icon.png") center center no-repeat;
                        background-size: cover;
                        width: 9px;
                        height: 12px;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                    }
                }
            }
        }
    }

    .part3 {
        margin-top: 450px;
        .border2 {
            background: url("https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-part3-border2.png") center center no-repeat !important;
            background-size: 100% 100% !important;
            height: 743px !important;
        }
        .border {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 1200px;
            height: 633px;
            background: url("https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-part3-border.png") center center no-repeat;
            background-size: cover;
            margin: 70px auto 0;
            padding-top: 80px;
            position: relative;
            .img1 {
                position: absolute;
                right: -350px;
                top: 0;
            }
            .img2 {
                position: absolute;
                left: -360px;
                bottom: -95px;
                width: 505px;
            }
            .sub-title {
                font-size: 24px;
	            line-height: 40px;
	            letter-spacing: 1px;
	            color: #68f0ff;
            }
            .text {
                font-size: 18px;
	            line-height: 40px;
	            letter-spacing: 1px;
	            color: #ffffff;
                margin-bottom: 20px;
            }
            .text2 {
                font-size: 16px;
	            line-height: 26px;
                text-align: center;
	            color: #ffffff;
                span {
                    font-style: italic;
                    color: #68f0ff;
                }
            }

            .btn {
                background: url("https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-part3-btn.png") center center no-repeat;
                background-size: cover;
                width: 204px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
	            font-weight: bold;
	            color: #114178;
            }

            .btns {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 50px 0;
                gap: 80px;
                cursor: pointer;
                
            }

            .text3 {
                font-size: 14px;
	            line-height: 24px;
	            letter-spacing: 0px;
	            color: #cfddff;
                text-align: center;
                position: relative;
            }
            .part2-btn {
                margin: 40px 0 20px;
                cursor: pointer;
            }

            .list {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 90px;
                margin-top: 35px;
                
                &-item {
                    width: 237px;
                    height: 187px;
                    background: url("https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-part3-border3.png") center center no-repeat;
                    background-size: cover;
                    position: relative;
                    &:first-child,&:nth-child(2) {
                        .tip{
                            cursor: pointer;
                        }
                    }
                    .tip {
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        top: 5px;
                        font-size: 16px;
	                    color: #114178;
                        font-weight: bold;
                        width: 130px;
                        text-align: center;
                    }
                    .text4{
                        width: 100%;
                        height: 100%;
                        font-size: 14px;
	                    line-height: 24px;
	                    color: #ffffff;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }

    .part4 {
        margin-top: 100px;
        padding-bottom: 450px;
        position: relative;
        .logo {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 80px;
            width: 271px;
        }
        .text {
            font-size: 16px;
	        color: #ffffff;
            line-height: 40px;
            width: 1100px;
            margin: 50px auto 0;
        }
    }
`