import {
  Form,
  Input,
  Radio,
  Button,
  message,
  Select,
  Tooltip,
  Row,
  Col,
  Checkbox,
  Modal,
  DatePicker,
  InputNumber,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useState, useEffect, useCallback, useRef } from "react";
import { CreateStyle } from "./createStyle";
import { UploadFile } from "antd/es/upload/interface";
import { getFileUrlByCosUpload } from "config/cos";
import {
  bizCommodityCreateParams,
  bizCommodityType,
  skuListItem,
} from "types/commodity";
import { apiPostCreateCommdity, apiGetCommodityDetail } from "api/commodity";
import { useParams, useSearchParams } from "react-router-dom";
import { useGRoutes } from "utils/hooks/useGRoute";
import PageHeader from "views/user-shop/layout/pageHeader";
import { MallProductCategoryListItem } from "types/product";
import {
  apiGetWorkDraftDetail,
  importContentFromWork,
} from "api/work";
import Specification from "./specifications";
import TinyEditor from "ui-lib/tinyMCE";
import { apiGetMallProductListCategory, apiGetMallThemeStyleList, apiGetMallWorkTypeList, apiGetShippingFeeTemplateList } from "api/shop";
import RelateWork from "./relateWork";
import { FilesWall } from "../uploadFileList";
import { ProductPicturesWall } from "../uploadImageList";
import { ImageCrop } from "components/crop-img";
import { getFileName } from "utils/functions/common";
import { CosDirectoryType } from "types/enums/media-type";
import { apiGetDictList } from "api/common";
import { DictItem } from "types/common";
import { formatMoney, formatShippingFeeTemplate } from "./fn";
import AddressSelect from "components/address-select";
import { ProductTagSelect } from "./tagSelect";
import TypeSelect from "./typeSelect";
import { RiCheckFill, RiCloseLine } from "react-icons/ri";
import ShopAgreement from "./agreement";


import 'dayjs/locale/zh-cn'
import dayjs from "dayjs";
import ShopYuegaoAgreement from "./yuegaoAgreement";

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

type imageType = {
  url: string;
  key: string;
  percent: number;
};

type submitParams = {
  coverUrl: {
    file?: File;
    imgUrl?: string;
  };
  imageList: imageType[];
  copyrightCertificates: UploadFile[];
  description: { html: string; media: number[] };
  categoryId: number;
  categoryIds: number[]
  type: number;
  name: string;
  quantity: number | string;
  rmbPrice: number | string;
  submit: number;
  skuList: skuListItem[];
  deliveryFee?: number;
  freeDelivery?: number;
  pcd?: number[]; //省市区
  shippingFeeTemplateId?: number
  tagList: string[]
  lastSendTime: Date
  lastSendType: number
  originalPrice: number
  themeStyle: string[]
  workType: string[]
  customWorkType: string
  customThemeStyle: string,
  goodAtSoftware: string[]
};

type workType = {
  coverUrl: string;
  id: number;
  title: string;
};

const CommodityCreate = () => {
  const [form] = Form.useForm<submitParams>();
  const categoryId = Form.useWatch("categoryId", form);
  const description = Form.useWatch("description", form);
  const lastSendType = Form.useWatch("lastSendType", form);
  const type = Form.useWatch("type", form);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [detail, setDetail] = useState<bizCommodityType>();
  const { id } = useParams();
  let [searchParams] = useSearchParams();
  const draftId = searchParams.get("draftId");
  const [skuList, setSkuList] = useState<skuListItem[]>([]);
  const gRouter = useGRoutes();
  const [messageApi, contextHolder] = message.useMessage();
  const [categoryList, setCategoryList] = useState<MallProductCategoryListItem[]>([]);
  const [subCategoryId, setSubCategoryId] = useState<number>();
  const [isShowRelate, setIsShowRelate] = useState(false);
  const [relatdWorkList, setRelatdWorkList] = useState<workType[]>([]);
  const [isRequired, setIsRequired] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [typeList, setTypeList] = useState<DictItem[]>([]);
  const [isAgressment, setIsAgreement] = useState(false)
  const [isShowShopAgreement, setIsShowShopAgreement] = useState(false)
  const [isShowShopYuegaoAgreement, setIsShowShopYuegaoAgreement] = useState(false)
  let code = new URLSearchParams(window.location.search).get("code");
  const [shippingFeeTemplateList, setShippingFeeTemplateList] = useState<
    { value: string | number; label?: React.ReactNode }[]
  >([]);

  const [mallWorkTypeList, setMallWorkTypeList] = useState<{ label: string, value: string }[]>([])
  const [mallThemeStyleList, setMallThemeStyleList] = useState<{ label: string, value: string }[]>([])

  const childRef = useRef() as React.MutableRefObject<any>;

  const updateSkuList = (list: skuListItem[]) => {
    form.setFieldValue("skuList", list);
    setSkuList(list);
  };

  const getShippingFeeTemplateList = () => {
    apiGetShippingFeeTemplateList().then((res) => {
      setShippingFeeTemplateList(formatShippingFeeTemplate(res));
    });
  };

  const [importAppendixProps, setImportAppendixProps] = useState({
    visible: false,
    visibleChange: (visible: boolean, needRefesh?: boolean) => {
      setImportAppendixProps({
        ...Object.assign(importAppendixProps, { visible: visible }),
      });
    },
  });

  const breadcrumbs = [
    {
      title: "商品列表",
    },
    {
      title: "创建商品",
    },
  ];

  const getDetail = useCallback(async () => {
    let result;
    if (mallWorkTypeList.length === 0) {
      return
    }

    if (mallThemeStyleList.length === 0) {
      return
    }
    if (id) {
      result = await apiGetCommodityDetail(+id!);
      setDetail(result);
      if (result?.skuList.length > 0) {
        result?.skuList.forEach((item) => {
          item.rmbPrice = Number((item.rmbPrice / 100).toFixed(2));
          item.originalPrice = Number((item.originalPrice / 100).toFixed(2));
        });
      }
      setSkuList(result?.skuList);
    }
    const imageList = result?.imageList;
    const copyrightCertificates = result?.copyrightCertificates;
    let pcd = undefined;
    if (result?.deliveryProvinceId && result?.deliveryCityId) {
      pcd = [result?.deliveryProvinceId, result?.deliveryCityId];
    }
    const arr: imageType[] = [];
    imageList?.forEach((item, index) => {
      arr.push({
        key: String(index),
        url: item,
        percent: 100,
      });
    });

    const arr1: UploadFile[] = [];
    copyrightCertificates?.forEach((item, index) => {
      arr1.push({
        uid: String(index),
        url: item,
        name: item,
      });
    });
    form.resetFields();
    form.setFieldsValue({
      coverUrl: {
        file: undefined,
        imgUrl: result?.coverUrl,
      },
      imageList: arr,
      description: {
        html: result?.description,
        media: [],
      },
      name: result?.name,
      categoryIds: (result?.categoryList[0].id && result?.categoryList[1].id) ? [Number(result?.categoryList[0].id), Number(result?.categoryList[1].id)] : [],
      categoryId: result?.categoryList[1].id,
      type: result?.type,
      submit: 1,
      skuList: result?.skuList,
      copyrightCertificates: arr1,
      deliveryFee: formatMoney(result?.deliveryFee),
      freeDelivery: result?.freeDelivery,
      pcd: pcd,
      shippingFeeTemplateId: result?.shippingFeeTemplateId,
      tagList: result?.tagList?.map(item => item.name),
      lastSendType: result?.lastSendType === undefined ? 1 : result?.lastSendType,
      lastSendTime: result?.lastSendTime ? dayjs(result?.lastSendTime) : '',
      workType: result?.workTypeList ? result?.workTypeList.map(item => item = String(item)) : [],
      themeStyle: result?.themeStyleList ? result?.themeStyleList.map(item => item = String(item)) : [],
    });

    if (!id && code === "ydbz") {
      form.setFieldValue("tagList", ["2024毕业集市"])
    }

    setSubCategoryId(Number(result?.categoryList[1].id));
    if (result && result?.workList.length > 0) {
      const obj = result.workList.map((item) => {
        return {
          id: item.id,
          coverUrl: item.coverUrl,
          title: item.title,
        };
      });
      setRelatdWorkList(obj);
    }
  }, [form, id, mallWorkTypeList, mallThemeStyleList]);

  useEffect(() => {
    if (!id) {
      setFormWithWorkInfo();
    }
  }, [draftId]);

  useEffect(() => {
    if (!subCategoryId) {
      return;
    }
    const categoryIds = form.getFieldValue("categoryIds")
    if (categoryIds.lenght === 0) {
      return
    }
    getCategoryList(categoryIds[1]);
  }, [form.getFieldValue("categoryIds"), subCategoryId]);

  const onFormChange = (changedValues: any) => {
    const categoryIds: number[] = changedValues["categoryIds"]
    if (categoryIds) {
      getCategoryList(categoryIds[1])
    }
  }

  useEffect(() => {
    getTypeList();
    getShippingFeeTemplateList();
  }, []);

  const [newType, setNewType] = useState(0)
  const [oldType, setOldType] = useState(0)

  useEffect(() => {
    const type = form.getFieldValue("type")
    setNewType(type)
    setOldType(newType)
  }, [form.getFieldValue("type")])

  useEffect(() => {
    if ((newType === 2 && oldType === 1) || (newType === 1 && oldType === 2)) {
      if (childRef.current) {
        childRef.current.updateSkuList([]);
      }
    }
  }, [newType, oldType]); // 确保当data或oldData变化时执行

  useEffect(() => {
    if (!categoryList) {
      return
    }

    if (!code) {
      return
    }
    if (code === 'authorization') {
      const result = categoryList.find(item => {
        return item.code.startsWith(String(code))
      })
      if (!!result) {
        form.setFieldValue("type", result.productType);
        setIsRequired(result.mustAttachment === 1);
        setIsAuthorized(result.code.startsWith("authorization"));
        form.setFieldValue("categoryIds", [result.pid, result.id])
      }
    }

  }, [categoryList, code])

  const getCategoryList = (id: number) => {
    const obj = categoryList.find((item) => item.id === id);
    if (obj) {
      form.setFieldValue("type", obj.productType);
      setIsRequired(obj.mustAttachment === 1);
      setIsAuthorized(obj.code.startsWith("authorization"));
    }
    // apiGetProductType(obj?.code).then((reset) => {
    //   form.setFieldValue("type", reset.type);
    //   setIsRequired(reset.isRequired === 1);
    // });
  };

  useEffect(() => {
    if (type !== 2) {
      form.setFieldValue("lastSendType", 1)
    }
  }, [type])

  const getTypeList = () => {
    apiGetDictList({ type: "mallProductType" }).then((res) => {
      setTypeList(res);
    });
    apiGetMallWorkTypeList().then(res => {
      const option: { label: string, value: string }[] = []
      res.forEach(item => {
        option.push({ label: item.name, value: String(item.id) })
      })
      setMallWorkTypeList(option);
    })

    apiGetMallThemeStyleList().then(res => {
      const option: { label: string, value: string }[] = []
      res.forEach(item => {
        option.push({ label: item.name, value: String(item.id) })
      })
      setMallThemeStyleList(option);
    })
  };

  const saveWork = (value: workType[]) => {
    setRelatdWorkList(value);
  };

  useEffect(() => {
    apiGetMallProductListCategory({ level: 2, type: 3 }).then((res) => {
      setCategoryList(res);
    });
  }, [id]);

  useEffect(() => {
    setConfirmLoading(false);
    getDetail();
  }, [getDetail]);

  // 保存
  const save = () => {
    if (!isAgressment && !!isAuthorized) {
      message.warning("请勾选且阅读《授权商品出售协议》")
      return
    }
    form.validateFields().then(async (res) => {
      const {
        coverUrl,
        description,
        name,
        copyrightCertificates,
        submit,
        type,
        categoryId,
        imageList,
        deliveryFee,
        freeDelivery,
        pcd,
        shippingFeeTemplateId,
        tagList,
        categoryIds,
        lastSendType,
        lastSendTime,
        originalPrice,
        themeStyle,
        workType,
        customWorkType,
        customThemeStyle,
        goodAtSoftware
      } = res;

      if (!description.html) {
        message.error("商品描述为空");
        return;
      }

      if (!coverUrl.file && !coverUrl.imgUrl) {
        message.error("商品封面不能为空");
        return;
      }
      // if (imageList.length < 3) {
      //   message.error("至少上传3张商品图片");
      //   return;
      // }

      setConfirmLoading(true);

      const copySkuList: skuListItem[] = JSON.parse(JSON.stringify(skuList));

      if (copySkuList.length > 0) {
        copySkuList.forEach((item) => {
          item.originalPrice = Number(item.originalPrice * 100);
          if (item.rmbPrice === undefined) {
            item.rmbPrice = item.originalPrice;
          } else {
            item.rmbPrice = Number(item.rmbPrice * 100);
          }
        });
      }
      //算一下运费
      let calDeliveryFee;
      if (freeDelivery === 1) {
        calDeliveryFee = 0;
      } else {
        calDeliveryFee = deliveryFee ? deliveryFee * 100 : undefined;
      }
      const params: bizCommodityCreateParams = {
        coverUrl: coverUrl.imgUrl,
        description: description.html,
        name,
        type: Number(type),
        categoryId: categoryIds[1],
        submit,
        id: Number(id) ? Number(id) : 0,
        workIdList: relatdWorkList
          .filter((item) => item.id !== 0)
          .map((item) => item.id),
        imageList: imageList.map((item) => item.url),
        deliveryFee: calDeliveryFee,
        workDraftId: draftId ? Number(draftId) : undefined,
        deliveryProvinceId: pcd && pcd[0],
        deliveryCityId: pcd && pcd[1],
        shippingFeeTemplateId: shippingFeeTemplateId,
        tagList: tagList
      };

      params.skuList = copySkuList

      if (type === 2) {
        params.lastSendType = lastSendType
        if (lastSendType === 3) {
          params.lastSendTime = dayjs(lastSendTime).format("YYYY-MM-DD HH:mm:ss")
        } else {
          delete params.lastSendTime
        }
      }

      if (copyrightCertificates && copyrightCertificates.length > 0) {
        params.copyrightCertificates = copyrightCertificates.map(
          (item) => item.url || ""
        );
      }

      if (id) {
        params.id = Number(id);
      }
      apiPostCreateCommdity(params)
        .then(() => {
          if (res.submit === 0) {
            gRouter(
              `/user-center/shop/product-list?onSale=0&status=0&tabKey=4`,
              { replace: true }
            );
          } else {
            gRouter(
              `/user-center/shop/product-list?onSale=0&status=1,3&tabKey=2`,
              { replace: true }
            );
          }
          messageApi.open({
            type: "success",
            content: id ? "修改成功" : "创建成功",
          });
        })
        .finally(() => {
          setConfirmLoading(false);
        });
    });
  };

  const upload = async (params: { imgUrl: string; file: File | undefined }) => {
    if (params.file) {
      let urlKey = getFileName(CosDirectoryType.productImage, params.file.name);
      let res = await getFileUrlByCosUpload(params.file!, urlKey);
      form.setFieldValue("coverUrl", {
        file: undefined,
        imgUrl: res.url,
      });
    }
  };

  const insertHtmlToDesc = (value: string) => {
    let a = (description.html ?? "") + value;
    form.setFieldValue("description", {
      html: a,
      media: [],
    });
    message.success("已成功复制到商品描述！");
  };

  const setFormWithWorkInfo = () => {
    if (draftId) {
      apiGetWorkDraftDetail(Number(draftId)).then((res) => {
        const imageList = res.mediaList
          .filter((item) => {
            return item.type === 1;
          })
          .map((item, index) => {
            return {
              key: String(index),
              url: item.resizedUrl,
              percent: 100,
            };
          });
        form.setFieldsValue({
          coverUrl: {
            file: undefined,
            imgUrl: res?.coverUrl,
          },
          imageList: imageList ?? [],
          name: res?.title,
        });
        setRelatdWorkList([
          {
            id: 0,
            coverUrl: res?.coverUrl,
            title: res?.title,
          },
        ]);
        importContentFromWork({ id: Number(draftId) }).then((res) => {
          form.setFieldValue("description", {
            html: res,
            media: [],
          });
        });
      });
    }
  };

  const list = [
    { name: "个人社交(非商用)", type: true, type2: true, type3: true, id: 1 },
    { name: "教学学习", type: true, type2: true, type3: true, id: 2 },
    { name: "个人娱乐", type: true, type2: true, type3: true, id: 3 },
    { name: "盈利性网络媒体", type: false, type2: true, type3: true, id: 4 },
    { name: "线下印刷", type: false, type2: true, type3: true, id: 5 },
    { name: "商业项目", type: false, type2: true, type3: true, id: 6 },
    { name: "二次加工", type: false, type2: true, type3: true, id: 7 },
    { name: "出租转售", type: false, type2: false, type3: true, id: 8 },
    { name: "免费公开", type: false, type2: false, type3: true, id: 9 },
    { name: "署名权", type: false, type2: false, type3: false, id: 10 }
  ]

  const openAgreement = (e: any) => {
    e.stopPropagation()
    setIsShowShopAgreement(true)
  }

  const openAgreeYuegao = (e: any) => {
    e.stopPropagation()
    setIsShowShopYuegaoAgreement(true)
  }

  // 设置今天之前的时间都不能被选择
  const disabledDate = (current: any) => {
    const today = new Date().setHours(0, 0, 0, 0);
    // 获取当前日期之前的日期
    const currentDay = current.startOf('day');
    // 判断当前日期是否在今天之前
    return currentDay < today;
  };

  return (
    <CreateStyle>
      <PageHeader
        breadcrumbs={breadcrumbs}
        link={() => (window.location.href = "/user-center/shop/product-list")}
      ></PageHeader>
      <div className="content">
        {contextHolder}
        <Form
          {...formItemLayout}
          className="create"
          style={{ maxWidth: 700 }}
          labelAlign="left"
          onValuesChange={onFormChange}
          form={form}
        >
          <Form.Item
            name="categoryIds"
            label="商品类目"
            rules={[{ required: true, message: "请选择商品类目" }]}
          >
            <TypeSelect />
          </Form.Item>

          <Form.Item
            name="type"
            label="商品类型"
            rules={[{ required: true, message: "商品类型" }]}
          >
            <Radio.Group disabled>
              {typeList.map((item, index) => {
                return (
                  <Radio value={item.code} key={index}>
                    {item.name}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="name"
            label="商品名称"
            rules={[{ required: true, message: "请选择商品名称" }]}
          >
            <Input type="text" maxLength={30} placeholder="请输入商品名称" />
          </Form.Item>
          <Form.Item
            name="coverUrl"
            label="商品封面图"
            rules={[{ required: true, message: "请上传商品封面图" }]}
            extra="建议尺寸700*500"
          >
            {/* <ImageUpload /> */}
            <ImageCrop uploadImage={upload} />
          </Form.Item>

          <Form.Item
            name="imageList"
            label="商品图片"
            rules={[{ required: true, message: "请上传商品图片" }]}
            extra="建议尺寸800*450，至少3张图片，上传更多图片，可以更好展示您的商品，比如商品全景图、多视角图、细节图、过程稿、实拍图等。"
          >
            <ProductPicturesWall maxLength={15} />
          </Form.Item>
          <Form.Item
            name="skuList"
            label="商品属性"
            rules={[{ required: true, message: "请创建商品属性" }]}
            tooltip="商品属性指一个商品中可添加的多个关联的子商品，如不同规格、尺寸、型号的子商品。实际销售以添加的具体子商品属性信息为准。"
          >
            <Specification
              isRequired={isRequired}
              isAuthorized={isAuthorized}
              list={skuList}
              updateSkuList={updateSkuList}
              ref={childRef}
              categoryList={categoryList}
            />
          </Form.Item>
          {type === 2 &&
            <>
              <Form.Item
                label="设置发货地"
                name="pcd"
                rules={[
                  { required: true, message: "请选择省市区" }

                ]}
              >
                {/* 只查省市 */}
                <AddressSelect types={'2,3'}></AddressSelect>
              </Form.Item>
              <Form.Item
                label="最晚发货时间"
                name="lastSendType"
                rules={[
                  { required: true, message: "请选择最晚发货时间" }
                ]}
              >
                <Radio.Group className="lastSendType">
                  <Radio value={0}>48小时发货</Radio>
                  <Radio value={1}>7天内发货</Radio>
                  <Radio value={2}>15天内发货</Radio>
                  <Radio value={3}>预售</Radio>
                  {
                    lastSendType === 3 && <Form.Item
                      className="last-date"
                      label=""
                      name="lastSendTime"
                      rules={[
                        { required: true, message: "请选择最晚发货时间" }
                      ]}
                    >
                      <DatePicker
                        format="YYYY-MM-DD HH:mm:ss"
                        showTime
                        minDate={dayjs(dayjs().subtract(0, 'day'))}
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                  }

                </Radio.Group>
              </Form.Item>
              <Row>
                <Col span={16}>
                  <Form.Item
                    label="运费设置"
                    name="shippingFeeTemplateId"
                    rules={[{ required: true, message: "请选择运费" }]}
                  >
                    <Select
                      options={shippingFeeTemplateList}
                      style={{ marginLeft: 46 }}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <a
                    href="/user-center/shop/shipping-template-list"
                    rel="noreferrer"
                    target={"_blank"}
                    style={{ marginTop: 8, display: "block" }}
                  >
                    添加运费模版
                  </a>
                </Col>
              </Row>
            </>
          }
          <Form.Item
            name="copyrightCertificates"
            label="上传版权登记证书"
            extra="如产品已进行版权登记或认证，可上传相关证书"
          >
            <FilesWall listType="picture-card" remark="" />
          </Form.Item>

          <Form.Item label="关联G站作品">
            <Button type="primary" onClick={() => setIsShowRelate(true)}>
              关联G站作品
            </Button>
            <span className="tips" style={{ paddingTop: 8 }}>
              关联G站作品可一键复制作品内容到商品描述（作品描述、作品图片及视频）
            </span>
            <div className="work-wrap">
              {relatdWorkList?.map((item) => {
                return (
                  <div key={item.id} className="work-item">
                    <div
                      className="cover"
                      style={{ backgroundImage: `url(${item.coverUrl})` }}
                    >
                      {item.id === 0 && (
                        <Tooltip title="作品上架后展示关联关系">
                          <div className="warning">
                            <InfoCircleOutlined />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                    <div className="name">{item.title}</div>
                  </div>
                );
              })}
            </div>
          </Form.Item>
          <Form.Item
            name="description"
            label={
              <>
                商品描述{" "}
                <span className="tips" style={{ paddingLeft: 8 }}>
                  据小G观察好好描述您的商品，可以增加大大提高销量哦
                </span>
              </>
            }
            rules={[{ required: true, message: "请输入商品描述" }]}
            className="description"
            labelCol={{ span: 24, offset: 24 }}
          >
            <TinyEditor width={800} cosImagePath={CosDirectoryType.productImage} cosVideoPath={CosDirectoryType.productVideo}></TinyEditor>
          </Form.Item>
          <Form.Item label="标签" name="tagList" labelCol={{ span: 24, offset: 24 }} wrapperCol={{ span: 24 }}>
            <ProductTagSelect dataTable="mall_product" categoryId={categoryId} maxCount={5}></ProductTagSelect>
          </Form.Item>
          <Form.Item name="submit" label="是否提交审核">
            <Radio.Group defaultValue={1}>
              <Radio value={0}>保存草稿</Radio>
              <Radio value={1}>提交审核，审核通过后自动上架</Radio>
            </Radio.Group>
          </Form.Item>
          <div className="product-tip">
            <div className="flex">
              <div className="bold">重要提醒：</div>
              <div className="red">非官方验证的站外交易都是诈骗!</div>
            </div>
            <div className="flex">
              <div className="bold margin20">遇可疑行为→速联官方或110!</div>
              <div>官方客服微信号:GGAC2025</div>
            </div>
          </div>
          {
            isAuthorized && <div className="agreement-tip">
              <Checkbox checked={isAgressment} onChange={e => { setIsAgreement(e.target.checked) }}>已阅读并同意《<h6 onClick={(e) => openAgreement(e)}>授权商品出售协议</h6>》</Checkbox>
            </div>
          }
          <div className="button-wrap">
            <Button
              type="primary"
              loading={confirmLoading}
              onClick={() => save()}
            >
              {id ? "修改商品" : "创建商品"}
            </Button>
          </div>
        </Form>
        {
          isAuthorized && <div className="border">
            <div className="title">授权发布说明</div>
            <div>1、您须保证拥有授权商品的完整版权及著作权，且商品内容遵守GGAC用户协议。</div>
            <div>2、若当前授权模版无法满足您的授权需求，您可在商品说明中撰写其他授权内容，并自行与买家联络签署的授权协议。</div>
            <div>3、GGAC将提供信息发布、沟通渠道、纠纷调解等服务。请通过GGAC官方渠道进行交易与沟通，GGAC无法对非经官方渠道的交易、约定提供服务。也不对双方交易内容（经济利益、权利转让等）承担任何义务或法律责任。</div>
            <div className="title">授权适用范围说明</div>
            <div className="table">
              <div className="table-item">
                <div>授权适用范围</div>
                <div>个人使用授权</div>
                <div>商业使用授权</div>
                <div>商业买断授权</div>
              </div>
              {
                list.map(item => {
                  return <div className="table-item" key={item.id}>
                    <div>{item.name}</div>
                    <div>{item.type ? <RiCheckFill /> : <RiCloseLine />}</div>
                    <div>{item.type2 ? <RiCheckFill /> : <RiCloseLine />}</div>
                    <div>{item.type3 ? <RiCheckFill /> : <RiCloseLine />}</div>
                  </div>
                })
              }
            </div>
          </div>
        }

        <RelateWork
          relatdWorkList={relatdWorkList}
          saveWork={saveWork}
          isShowRelate={isShowRelate}
          onCancel={() => setIsShowRelate(false)}
          onImportContent={insertHtmlToDesc}
        ></RelateWork>
      </div>
      <Modal width={700} footer={[<Button type="primary" onClick={() => setIsShowShopAgreement(false)}>
        确定
      </Button>]} title="授权商品出售协议" destroyOnClose={true} closable={false} centered={true} open={isShowShopAgreement}>
        <ShopAgreement />
      </Modal>
    </CreateStyle>
  );
};

export default CommodityCreate;
