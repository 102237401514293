import { useEffect, useState } from "react"
import { RelatedWorkStyle } from "./style"
import { WorkListItem } from "types/work"
import { apiMyWorkList } from "api/user/work"
import { Button, Pagination, Spin } from "antd";
import { NoData } from "components/no-data";


type workType = {
    coverUrl?: string;
    id?: number;
    title?: string;
};

const RelatedWork = ({ onCancel, isShowRelate, relatdWork, saveWork }: { onCancel: () => void, isShowRelate: boolean, relatdWork?: workType, saveWork: (value?: workType) => void }) => {

    const [workList, setWorkList] = useState<WorkListItem[]>([])
    const [selectWork, setSelectWork] = useState<workType>()
    const [totalSize, setTotalSize] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)
    const pageSize = 12
    const [isLoading, setIsLoading] = useState(false)

    const submit = () => {
        onCancel()
        saveWork(selectWork)
    }

    const select = (detail: WorkListItem) => {
        if (selectWork?.id === detail.id) {
            setSelectWork({})
        } else {
            setSelectWork({ coverUrl: detail.coverUrl, id: detail.id, title: detail.title })
        }
    }

    useEffect(() => {
        if (!isShowRelate) {
            return
        }
        setPageNumber(1)
        setSelectWork(relatdWork)
    }, [isShowRelate, relatdWork])

    useEffect(() => {
        setIsLoading(true)
        apiMyWorkList({ onSale: 1, isPublic: 1, pageSize, pageNumber }).then(res => {
            setWorkList(res.pageData)
            setTotalSize(res.totalSize)
        }).finally(() => {
            setIsLoading(false)
        })
    }, [pageNumber])

    const changePage = (e: number) => {
        setPageNumber(e)
    }

    return <RelatedWorkStyle>
        <div className="wrap">
            <Spin spinning={isLoading}>
                {
                    workList.length === 0 && <NoData />
                }
                <div className="work-wrap">
                    {
                        workList?.map(item => {
                            return <div className="work-item" key={item.id} >
                                <div onClick={() => select(item)} className={`work ${!!selectWork && selectWork?.id === item.id ? 'active' : ''}`}>
                                    <div className="cover" style={{ backgroundImage: `url(${item.coverUrl})` }} />
                                    <div className="name" title={item.title}>{item.title}</div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </Spin>
        </div>
        <Pagination
            style={{ textAlign: "center", margin: "12px 0 42px 0" }}
            hideOnSinglePage={true}
            current={Number(pageNumber)}
            pageSize={pageSize}
            onChange={changePage}
            total={totalSize}
            showSizeChanger={false}
        />
        <div className="btns">
            <Button type="primary" onClick={submit} loading={isLoading}>确定</Button>
            <Button type="primary" ghost onClick={() => onCancel()}>取消</Button>
        </div>
    </RelatedWorkStyle>
}

export default RelatedWork