import { useEffect, useState } from "react"
import { Ycwl3RuleStyle } from "./style"
import { useNavigate, useOutletContext } from "react-router-dom"
import { queryUrl } from "utils/functions/common"
import { ContestDetailResponse } from "types/contest"
const Ycwl3Rule = () => {

    const [currentObj, setCurrentObj] = useState<dataType>()
    const [subCurrentObj, setSubCurrentObj] = useState<subDataType>()
    const id = Number(new URLSearchParams(window.location.search).get("id") || 1)
    const subId = Number(new URLSearchParams(window.location.search).get("subId") || 1)
    const [currentItem, setCurrentItem] = useState<subContentType>()
    const [currentId, setCurrentId] = useState<number>(1)

    type subDataType = {
        id: number
        icon: string
        word: string
        tip: string
        content: subContentType[]
        warn?: string
        warn2?: string[]
    }

    type subContentType = {
        id: number
        word: string[]
        link?: string
        name?: string
        exampleList?: {
            label: string[]
            value: string[]
        }[]
        list: {
            label: string
            value: {
                name: string
                sub: string[]
                subList?: string[]
                sub2: string[]
                subList2?: string[]
            }[]
        }[]
    }

    type dataType = {
        name: string
        id: number
        list: subDataType[]
    }


    const { detail } = useOutletContext<{ detail: ContestDetailResponse }>()
    const contestJSON = JSON.parse(detail?.pageCfg || "{}")
    const dataList: {
        word: string[]
        word2: string
        rule: dataType[]
        rule2: { tip: string, list: { id: number, label: string, content: string[] }[] }
    } = contestJSON?.rule

    const navigate = useNavigate()

    useEffect(() => {
        const result = dataList?.rule.find(item => item.id === id)
        setCurrentObj(result!)
    }, [detail, id])
    useEffect(() => {
        if (!currentObj) {
            return
        }
        const result = currentObj.list.find(item => item.id === subId)
        setSubCurrentObj(result!)
    }, [subId, currentObj])

    useEffect(() => {
        if (!subCurrentObj) {
            return
        }
        const result = subCurrentObj.content.find(item => item.id === currentId)
        setCurrentItem(result)
    }, [subId, subCurrentObj, currentId])

    const selectId = (value: dataType) => {
        setCurrentId(1)
        const query = queryUrl({ id: value.id, subId: 1 })
        navigate(`?${query}`)
    }

    const selectSubId = (sId: number) => {
        setCurrentId(1)
        const query = queryUrl({ subId: sId })
        navigate(`?${query}`)
    }

    const toDetail = () => {
        window.open("http://ycwl.cheeringtech.cn:8000/main/home")
    }

    return <Ycwl3RuleStyle>
        {
            !!detail && <div className="rule">
                <div className="rule-one">
                    <div className="content">
                        {
                            dataList?.word.map(item => {
                                return <div className="content-item" dangerouslySetInnerHTML={{ __html: item }} key={item} />
                            })
                        }
                        {
                            dataList.word2 && <div className="tip">{dataList.word2}</div>
                        }
                    </div>
                    <div className="title">
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-title-left.png" alt="" />
                        <div className="word">参赛规则</div>
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-title-right.png" alt="" />
                    </div>
                    <div className="tab" id="tab">
                        {
                            dataList?.rule.map(item => {
                                return <div onClick={() => selectId(item)} className={`tab-item ${item.id === id ? 'tab-first' : ''}`} key={item.id}>{item.name}</div>
                            })
                        }
                    </div>
                    <div className="list">
                        {
                            !!subCurrentObj?.warn2 && <div className="warn">
                                {
                                    subCurrentObj?.warn2.map(item =>
                                        <div key={item} dangerouslySetInnerHTML={{ __html: item }} />
                                    )
                                }
                            </div>
                        }
                        <div className="border-list">
                            <div className="group-item">
                                {
                                    currentObj?.list.map(sub => {
                                        return <div key={sub.id} onClick={() => selectSubId(sub.id)} className={`border ${currentObj.id === id && sub.id === subId ? 'tab-second' : ''}`}>
                                            <img src={sub.icon} alt="" />
                                            <div className="word">{sub.word}</div>
                                            <div className="tip">{sub.tip}</div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>

                        {
                            !!subCurrentObj?.warn && <div className="warn2" dangerouslySetInnerHTML={{ __html: subCurrentObj?.warn }}></div>
                        }

                        {
                            (subCurrentObj?.content.length || 1) > 1 && <div className="content-tab">
                                {
                                    subCurrentObj?.content.map(item => {
                                        return <div onClick={() => setCurrentId(item.id)} className={`content-tab-item ${currentId === item.id ? 'active' : ""}`} key={item.id}>{item.name}</div>
                                    })
                                }
                            </div>
                        }
                        <div className="content-line" />
                        {
                            currentItem && <div className="content-wrap">
                                <div className="content">
                                    {
                                        currentItem && currentItem.word.map(item => {
                                            return <div className="word" key={item}>{item}</div>
                                        })
                                    }

                                    {
                                        currentItem?.link && <div className="link" dangerouslySetInnerHTML={{ __html: currentItem?.link }} />
                                    }

                                    {
                                        !!currentItem?.exampleList && <div className="example">
                                            <div className="top">
                                                <div className="top-item">选题(示例)</div>
                                                <div className="top-item">选题意义</div>
                                            </div>
                                            <div className="expample-wrap">
                                                {
                                                    currentItem.exampleList.map((sub, index) => {
                                                        return <div className="example-item" key={index}>
                                                            <div className="label">
                                                                <div className="inner">
                                                                    {/* {
                                                                        sub.label.map(word => {
                                                                            return <div className="desc" key={word}>{word}</div>
                                                                        })
                                                                    } */}
                                                                    <div className="desc">
                                                                        {
                                                                            sub.label.join("")
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="value">
                                                                <div className="inner">
                                                                    {/* {
                                                                        sub.value.map(word => {
                                                                            return <div className="desc" key={word}>{word}</div>
                                                                        })
                                                                    } */}
                                                                    <div className="desc">
                                                                        {
                                                                            sub.value.join("")
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                    <div className="section">
                                        {
                                            currentItem.list.map((sub, index) => {
                                                return <div key={index} className="section-item">
                                                    {
                                                        !!sub.label && <div className="section-title">{sub.label}</div>
                                                    }

                                                    <div className="section-content">
                                                        {
                                                            sub.value.map((word, sIndex) => {
                                                                return <div key={sIndex}>
                                                                    {
                                                                        !!word.name && <div className={`section-sub-title ${!sub.label ? 'section-title' : ""}`}>
                                                                            <div>{sIndex + 1}、</div><div>{word.name}</div>
                                                                        </div>
                                                                    }
                                                                    <div className="sub">
                                                                        {
                                                                            word.sub && word.sub.map((third, index) => {
                                                                                return <div className="sub-item" key={third}>
                                                                                    {
                                                                                        (!(id === 1 && subId === 3 && index > 2)) && <div>({index + 1})</div>
                                                                                    }

                                                                                    {
                                                                                        ((id === 1 && subId === 3 && index > 2 && sIndex === 1)) && <div style={{ marginLeft: "25px" }}>({index - 2})</div>
                                                                                    }

                                                                                    <div dangerouslySetInnerHTML={{ __html: third }} />
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                    {
                                                                        <div className="sub sub2">
                                                                            {
                                                                                !!word.subList && word.subList.map((third) => {
                                                                                    return <div className="sub-item" key={third}>
                                                                                        <div>{third}</div>
                                                                                    </div>
                                                                                })
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {
                                                                        <div className="sub">
                                                                            {
                                                                                !!word.sub2 && word.sub2.map((third) => {
                                                                                    return <div className="sub-item" key={third}>
                                                                                        <div>(2)</div>
                                                                                        <div>{third}</div>
                                                                                    </div>
                                                                                })
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {
                                                                        <div className="sub sub2">
                                                                            {
                                                                                !!word.subList2 && word.subList2.map((third) => {
                                                                                    return <div className="sub-item" key={third}>
                                                                                        <div>{third}</div>
                                                                                    </div>
                                                                                })
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="title title2">
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-title-left.png" alt="" />
                        <div className="word">版权声明</div>
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-title-right.png" alt="" />
                    </div>
                    <div className="rule2">
                        <div className="tip">{dataList?.rule2.tip}</div>
                        {
                            dataList?.rule2?.list.map(item => {
                                return <div className="list-item" key={item.id}>
                                    {!!item.label && <>
                                        <div className="label">
                                            {item.id}、<div>{item.label}</div>
                                        </div>
                                        <div className="content">
                                            {
                                                item.content.map((name, index) => {
                                                    return <div className="num" key={index}>
                                                        {
                                                            item.content.length > 1 && <div>({index + 1})</div>
                                                        }
                                                        {name}
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </>}
                                    {
                                        !item.label && <div className="content-word"><div>{item.id}、</div>{item.content.map(name => <div key={name}>{name}</div>)}</div>
                                    }
                                </div>
                            })
                        }
                    </div>
                    <div className="bottom">
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-part3-bottom.png" alt="" />
                    </div>
                </div>
            </div>
        }
    </Ycwl3RuleStyle >
}

export default Ycwl3Rule