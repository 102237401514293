import { ReactSVG } from "react-svg";
import { MediaIconsStyle } from "./style"
import { WorkContestListItem } from "types/contest";
import { MediaType } from "types/enums/media-type";
import { WorkDraftItem, WorkListItem } from "types/work";

function MediaIconsWork({ item }: { item: WorkListItem | WorkDraftItem | WorkContestListItem }) {
  return (
    <MediaIconsStyle>
      <div className={`${item.mediaTypeList?.includes(MediaType.video) || item.mediaTypeList?.includes(MediaType.multiImage) ? 'wrap' : ''}`}>
        {/* 视频图标 */}
        {item.mediaTypeList?.includes(MediaType.video) && (
          <div className="item" title="视频作品">
            <ReactSVG src="https://cdn-prd.ggac.com/ggac/svg/work-video-icon3.svg" beforeInjection={(svg) => {
              svg.setAttribute('width', '24');
              svg.setAttribute('height', '16');
            }} />
          </div>
        )}
        {/* 多图图标 */}
        {item.mediaTypeList?.includes(MediaType.multiImage) && (
          <div className="item" title="多图作品">
            <ReactSVG src="https://cdn-prd.ggac.com/ggac/svg/work-image-icon3.svg" beforeInjection={(svg) => {
              svg.setAttribute('width', '28');
              svg.setAttribute('height', '21');
            }} />
          </div>
        )}
      </div>

    </MediaIconsStyle>
  )
}

export default MediaIconsWork