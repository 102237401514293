import { Form, Tabs, TabsProps } from "antd";
import { SignupField } from "types/sub/application"
import { CategorySecondSelect } from "../category-second-select";
import { SubChildrenSelect } from "../children-select";
import { MediaCategorySecondSelect } from "../media-category-second-select";
import { SingleSelect } from "../single-select";
import { GrandContestSelectStyle } from "./style";
import { useEffect, useState } from "react";
import { draftWorkDetailType } from "types/work";
import { ContestDetailResponse } from "types/contest";

type Props = {
  fieldList: SignupField[]
  detail: draftWorkDetailType | undefined,
  isGameDesigner?: boolean
}
function GrandContestSelect({ fieldList, detail, isGameDesigner }: Props) {
  const [filteredList, setFilteredList] = useState<SignupField[]>(fieldList)
  const [tabs, setTabs] = useState<TabsProps["items"]>([])
  // const [modal, contextHolder] = Modal.useModal();
  const [activeTab, setActiveTab] = useState('theme')

  const getDefaultKey = () => {
    // console.log("getDefaultKey",detail?.contestWork.theme,detail?.contestWork.themeA)
    if (detail?.contestWork.theme) {
      setActiveTab('theme')
    } else if (detail?.contestWork.themeA) {
      setActiveTab('themeA')
    }
  }

  useEffect(() => {
    getDefaultKey()
  }, [detail])

  useEffect(() => {
    const filtered = fieldList.filter(item => {
      let arr = (detail && detail.contestWork.contestCode === 'ggac6') ? ["theme", "themeA"] : ["theme", "themeA", "xrtz", "ly3d"]
      if (arr.includes(item.code)) {
        return item
      }
    })
    setFilteredList(filtered)

    let currentItem_: SignupField | undefined
    if (detail) {
      currentItem_ = filtered.find(item => { return item.code === (detail?.contestWork.themeA ? 'themeA' : 'theme') })
      setTabs(filtered.map((item) => {
        return {
          key: item.code,
          label: item.name,
          children: item.code === (detail?.contestWork.themeA ? 'themeA' : 'theme') && currentItem_ ? formItemRender(currentItem_) : undefined
        }
      }))
    } else {
      currentItem_ = filtered.find(item => { return item.code === 'theme' })
      setTabs(filtered.map((item) => {
        return {
          key: item.code,
          label: item.name,
          children: item.code === 'theme' && currentItem_ ? formItemRender(currentItem_) : undefined
        }
      }))
    }


  }, [fieldList, detail])



  const formItemRender = (item: SignupField) => {

    if (item.hasCategory) {
      return (
        <Form.Item
          key={item.code}
          label={''}
          name={item.code}
          rules={[{ required: !!item.isRequired }]}
        >
          <CategorySecondSelect data={item.options}></CategorySecondSelect>
        </Form.Item>
      );
    } else if (item.hasSecondOption) {
      return (
        <Form.Item
          key={item.code}
          label={''}
          name={item.code}
          rules={[{ required: !!item.isRequired }]}
        >
          <SubChildrenSelect
            data={item.options}
            multiple={false}
          ></SubChildrenSelect>
        </Form.Item>
      );
    } else if (item.hasMediaCategory) {
      return (
        <Form.Item
          key={item.code}
          label={''}
          name={item.code}
          rules={[{ required: !!item.isRequired }]}
        >
          <MediaCategorySecondSelect data={item.options}></MediaCategorySecondSelect>
        </Form.Item>
      );
    } else {
      return (
        <Form.Item
          key={item.code}
          label=''
          name={item.code}
          rules={[{ required: !!item.isRequired }]}
        >
          <SingleSelect data={item.options} multiple={false}></SingleSelect>
        </Form.Item>
      );
    }
  }

  const config = {
    title: '您正在切换参赛类型',
    content: (
      <p>一个作品同时只能选择一种参赛类型</p>
    ),
  };

  const onChange = async (key: string) => {

    const currentItem_ = filteredList.find(item => { return item.code === key })
    const themeItem = filteredList.find(item => { return item.code === 'theme' })
    console.log(themeItem);
    setActiveTab(key)
    if (key === 'xrtz' || key === 'ly3d') {
      window.open(`/g-contest/upload/${currentItem_?.remark}`)
      setActiveTab('theme')
      tabs && setTabs(tabs.map((item) => {
        return {
          key: item.key,
          label: item.label,
          children: themeItem ? formItemRender(themeItem) : undefined
        }
      }))
      return
    }

    tabs && setTabs(tabs.map((item) => {
      return {
        key: item.key,
        label: item.label,
        children: item.key === key && currentItem_ ? formItemRender(currentItem_) : undefined
      }
    }))

  };


  return (
    <GrandContestSelectStyle className="GrandContestSelectStyle">
      {
        !isGameDesigner && <>
          {tabs && tabs?.length > 0 && <label className="title" title="参赛类型">参赛类型</label>}
        </>
      }

      {tabs && tabs?.length > 0 && <Tabs activeKey={activeTab} items={tabs} onChange={onChange} />}
      {/* {contextHolder} */}
    </GrandContestSelectStyle>
  )
}

export default GrandContestSelect