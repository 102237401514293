import styled from "styled-components";

export const CollaborativeCreatorsStyle = styled.div`
  .header {
    margin: ${(props) => props.theme.space.marginXS}px 0;
  }
  .list {
    display: flex;
    row-gap: 15px;
    .list-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      width: calc(100% / 3);
      .avatar {
        position: relative;
        .role {
          position: absolute;
          /* bottom: 13px; */
          width: 14px;
          height: 14px;
          font-size: 12px;
          background-color: ${(props) => props.theme.colors.colorPrimary};
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          border-radius: 50%;
          right: 0;
          bottom:2px;
          cursor: pointer;
          font-weight: bold;
          &.yellow {
            background-color: ${(props) => props.theme.colors.colorPrimary};
          }
          &.green {
            background-color: #52c41a;
          }
          &.purple {
            background-color: #722ed1;
          }
          &.blue {
            background-color: #1677ff;
          }
          &.hide {
            display: none;
          }
        }
        .verified {
          position: absolute;
          bottom: 8px;
          right: 10px;
          background-color: ${(props) => props.theme.colors.colorPrimary};
          color: ${(props) => props.theme.colors.colorTextLightSolid};
          width: 16px;
          height: 16px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid ${(props) => props.theme.colors.colorTextLightSolid};
          font-weight: ${(props) => props.theme.fontSizes.fontWeightHeading4};
        }
      }
      .username {
        max-width: 100px;
        padding-top: ${(props) => props.theme.space.paddingXS}px;
      }
      .usertype {
        color: ${(props) => props.theme.colors.colorTextSecondary};
      }
      .btn{
        margin-top: ${(props) => props.theme.space.marginXS}px;
      }
      .active {
                background-color: transparent;
                border-color: ${(props) => props.theme.colors.colorPrimary};
                color:${(props) => props.theme.colors.colorPrimary};
        }
    }
  }
`;
