import { Button, message, Modal, Space } from "antd";
import { SuperInteractionStyle } from "./style";
import { UserBaseInfo } from "types/user";
import { WorkDetailType } from "types/work";
import { useCallback, useEffect, useState } from "react";
import { postToRecommendWork } from "api/work";
import ScoreModal from "components/uni-card/self-cards/scoreWorkCard/score-modal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { apiGetWorkScoreDetail, apiPostNewScore } from "api/contest";
import { ScoreFlowDetail } from "types/contest";
import { hasToken, queryUrl } from "utils/functions/common";
import TextArea from "antd/es/input/TextArea";
import { loginPathWithRedirect } from "utils/functions/user";


type Props = {
  detail: WorkDetailType;
  updateDetail: (detail: WorkDetailType) => void;
};
function SuperInteraction({ detail, updateDetail }: Props) {
  const [recommendLoading, setRecommedLoading] = useState(false);
  const [openScoreModal, setOpenScoreModal] = useState(false);
  const [scoreDetail, setScoreDetail] = useState<ScoreFlowDetail>();
  const [scoreLoading, setScoreLoading] = useState(false);
  const [searchParams] = useSearchParams()
  const [currentScore, setCurrentScore] = useState("")
  const { id } = useParams()
  const [scoreList, setScoreList] = useState<{ name: string, level: string, sortScore: number, basicScore: number }[]>([])
  let userBaseInfo: UserBaseInfo = JSON.parse(
    localStorage.getItem("user_info")!
  );

  const [scoreListIds, setScoreListIds] = useState<number[]>([])
  const navigate = useNavigate()
  const [isShowRemarkModal, setIsShowRemarkModal] = useState(false)
  const [remark, setRemark] = useState("")
  const [hasPrevBtn, setHasPrevBtn] = useState(false)
  const [hasNextBtn, setHasNextBtn] = useState(false)

  const toRecommend = () => {
    setRecommedLoading(true);
    postToRecommendWork({
      dataId: detail.id,
      dataTable: "work",
      userId: userBaseInfo.id,
    })
      .then(() => {
        detail.isRecommend = 1;
        updateDetail(detail);
        message.success("推荐成功");
      })
      .finally(() => {
        setRecommedLoading(false);
      });
  };

  const onScored = () => {
    getScoreDetail()
    setOpenScoreModal(false)
  }

  const getScoreDetail = useCallback(() => {
    setScoreLoading(true)
    searchParams.get('scoreTaskId') && apiGetWorkScoreDetail({ taskId: Number(searchParams.get('scoreTaskId')), workId: detail.id }).then((res) => {
      setScoreDetail(res)
      setScoreList(JSON.parse(res.scoreRules))
      setCurrentScore(res.scoreLevel || 'D')
      setRemark(res.remark)
    }).finally(() => {
      setScoreLoading(false)
    })
  }, [searchParams, detail])

  useEffect(() => {
    getScoreDetail()
  }, [getScoreDetail])

  const score = (value: string) => {
    if (!scoreDetail) {
      return
    }
    const { remark, taskId } = scoreDetail
    apiPostNewScore({
      remark: remark,
      scoreLevel: value,
      taskId,
      workId: detail.id
    }).then(() => {
      setCurrentScore(value)
      message.success("分档成功")
    })
  }

  useEffect(() => {
    const scoreListIds: number[] = JSON.parse(localStorage.getItem("scoreListIds") || "[]")
    setScoreListIds(scoreListIds)
    const index = scoreListIds.findIndex(item => item === Number(id))
    setHasPrevBtn(index !== 0)
    setHasNextBtn(index !== (scoreListIds.length - 1))
  }, [id])

  const toNewScore = (value: string) => {
    const index = scoreListIds.findIndex(item => item === Number(id))
    const query = queryUrl({})
    if (value === 'prev') {
      if (index === 0) {
        navigate(`/work/detail/${scoreListIds[scoreListIds.length - 1]}?${query}`)
        return
      }
      navigate(`/work/detail/${scoreListIds[index - 1]}?${query}`)
    } else {
      if (index === scoreListIds.length - 1) {
        navigate(`/work/detail/${scoreListIds[0]}?${query}`)
        return
      }
      navigate(`/work/detail/${scoreListIds[index + 1]}?${query}`)
    }
  }

  const addRemark = () => {
    if (!scoreDetail) {
      return
    }
    const { taskId } = scoreDetail
    apiPostNewScore({
      remark: remark,
      scoreLevel: currentScore,
      taskId,
      workId: detail.id
    }).then(() => {
      getScoreDetail()
      setIsShowRemarkModal(false)
      message.success("添加成功")
    })
  }

  const token = hasToken()

  const toAddPicture = () => {
    if(!token) {
      navigate(loginPathWithRedirect())
      return
    }
    const tagName = detail?.tagList.map(item => item.name)
    window.open(`/work/create?workId=${detail?.id}&type=addPicture${tagName.length > 0 ? `&tag=${JSON.stringify(tagName)}` : ''}`)
  }

  return (
    <SuperInteractionStyle>
      <Button
        style={{ width: '100%' }}
        onClick={toAddPicture}
        type="primary"
        className="add-picture"
      >
        跟一张图
      </Button>

      {userBaseInfo && userBaseInfo.permissions.includes("workRecommend") && (
        <Button
          disabled={detail.isRecommend === 1}
          loading={recommendLoading}
          style={{ width: '100%' }}
          onClick={toRecommend}
          type="primary"
        >
          {detail && detail.isRecommend === 1 ? "已推荐" : "推荐"}
        </Button>
      )}

      {userBaseInfo && userBaseInfo.permissions.includes("workScore") && searchParams.get('scoreTaskId') && scoreDetail?.type === 1 && (
        <>
          <ScoreModal workCoverUrl={detail.coverUrl} workTitle={detail.title} taskId={Number(searchParams.get('scoreTaskId'))} workId={detail.id} openModal={openScoreModal} handleOk={onScored} handleCancel={onScored} mediaCategory={Number(searchParams.get('taskMediaCategory'))}></ScoreModal>
          <Button
            disabled={scoreDetail?.isSubmit === 1}
            style={{ width: '100%', marginTop: '24px' }}
            onClick={() => setOpenScoreModal(true)}
            type="primary"
            loading={scoreLoading}
          >
            {scoreDetail && scoreDetail?.averageScore > 0 ? `修改打分(${scoreDetail?.averageScore})` : '打分'}
          </Button>
        </>
      )}
      {
        userBaseInfo && userBaseInfo.permissions.includes("workScore") && searchParams.get('scoreTaskId') && scoreDetail?.type === 2 && (
          <>
            <div className="score-tip">分档</div>
            <div className="score">
              {
                scoreList.map(item => {
                  return <div className={`score-item ${currentScore === item.level ? 'score-active' : 'f'}`} onClick={() => score(item.level)} key={item.name}>{item.level}</div>
                })
              }
            </div>
            <div className="btns">
              <Space>
                {
                  scoreListIds.length > 1 && <>
                    {
                      hasPrevBtn && <Button type="primary" onClick={() => toNewScore("prev")}>上一幅</Button>
                    }
                    {
                      hasNextBtn && <Button type="primary" onClick={() => toNewScore("next")}>下一幅</Button>
                    }
                  </>
                }
                <Button type="primary" onClick={() => setIsShowRemarkModal(true)}>添加评语</Button>
              </Space>
            </div>
            <Modal title="评委寄语"
              open={isShowRemarkModal}
              onOk={addRemark}
              centered
              onCancel={() => setIsShowRemarkModal(false)}
              width={800}
              okText="保存"
              destroyOnClose //关闭时销毁
            >
              <TextArea style={{ resize: "none" }} rows={5} placeholder="请输入评语" value={remark} onChange={(e) => setRemark(e.target.value)} />
            </Modal>
          </>
        )
      }
    </SuperInteractionStyle>
  );
}

export default SuperInteraction;
