import { Button, Result } from "antd";
import { PromoteTosellStyle } from "./style";
import { activeGetLocalUserInfo } from "utils/functions/common";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

function PromoteToSell() {
  const [remainingSeconds, setRemainingSeconds] = useState(10);
  const [hasShop, setHasShop] = useState(false)
  const [searchParams] = useSearchParams()
  const isContest = Number(searchParams.get('isContest'))
  useEffect(() => {
    if (remainingSeconds <= 0) {
      getBack();
      return;
    }

    const interval = setInterval(() => {
      setRemainingSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [remainingSeconds]);

  useEffect(() => {
    activeGetLocalUserInfo().then((res) => {
      setHasShop(!!res.shopId)
    })
  }, []);
  const { id } = useParams();
  const getBack = () => {
    window.location.href = `/user-center/home/work/draft/list`;
  };
  const extraContent = () => {
    return (
      <PromoteTosellStyle className="PromoteTosellStyle">
        <div className="container">
          <div className="info-box">
            <div className="wrapper">
              <h3>您还可以</h3>
              {
                !!isContest && <>
                  <div className="title">
                    <p>
                      {`创建衍生【商品】-> 资源/笔刷/周边等`}
                      {!hasShop && (
                        <Button type="primary" onClick={() => {
                          window.open(
                            `/user-center/shop/product-list`
                          );
                        }}>
                          立即开店
                        </Button>
                      )}
                      <Button
                        type="primary"
                        onClick={() => {
                          window.open(
                            `/user-center/shop/product/create`
                          );
                        }}
                      >
                        创建商品
                      </Button>
                    </p>
                  </div>
                </>
              }
              {
                !isContest && <>
                  <div className="title">
                    <div className="fjs">
                      <div className="btn">
                      </div>
                    </div>
                    <p>
                      {`开启作品【授权】-> 赚取授权金额`}
                      <Button
                        type="primary"
                        onClick={() => {
                          window.open(
                            `/user-center/shop/product/create/?draftId=${id}&code=authorization`
                          );
                        }}
                      >
                        创建授权商品
                      </Button>
                    </p>
                    <p>
                      {`创建衍生【商品】-> 资源/笔刷/周边等`}
                      <Button
                        type="primary"
                        onClick={() => {
                          window.open(
                            `/user-center/shop/product/create/?draftId=${id}`
                          );
                        }}
                      >
                        创建其他商品
                      </Button>
                    </p>
                  </div>
                </>
              }

            </div>
          </div>

          <div className="back">
            <Button key="buy" onClick={() => getBack()}> 返回个人中心</Button>
            <div className="countdown">{remainingSeconds}s后自动跳转</div>
          </div>
        </div>
      </PromoteTosellStyle>
    );
  };
  return (
    <Result
      status="success"
      title={`作品已提交，很快为你审核哦！`}
      subTitle="分享这幅独特的作品，让更多人看到你的光芒！"
      extra={extraContent()}
    />
  );
}

export default PromoteToSell;
