import { useNavigate, useOutletContext } from "react-router-dom"
import { Ycwl3DetailStyle } from "./style"
import { ContestDetailResponse } from "types/contest"
import { useEffect } from "react"
const Ycwl3Detail = () => {
    const { detail } = useOutletContext<{ detail: ContestDetailResponse }>()
    const contestJSON = JSON.parse(detail?.pageCfg || "{}")
    const dataList: {
        word: string[],
        word2: string[],
        group: { name: string, list: { id: number, icon: string, word: string, tip: string }[] },
        group2: { name: string, list: { id: number, icon: string, word: string, tip: string }[] },
        dateList: { word: string[], date: string, id: number }[],
        prize: { name: string, id: number, title?: string, prize?: { name: string, money: number }, list: { name: string, num: number, money: number, money2?: string, id: number }[][] }[],
        judge: { id: number, title?: string, list: { name: string, cover: string, desc: string[], workId?: string, id: number }[][] }[],
        rule1: string[]
        rule2: { label: string, value: string }[]
    } = contestJSON?.detail

    const navigate = useNavigate()

    const toRule = (id: number, sId: number) => {
        window.scrollTo({
            top: 1600,
            behavior: "smooth"
        })
        navigate(`/contest/ycwl3/rule?id=${id}&subId=${sId}`)
    }

    useEffect(() => {
        if (!detail) {
            return
        }
        if (!dataList.group) {
            return
        }
        console.log(dataList.group)
    }, [detail, dataList])

    return <Ycwl3DetailStyle>
        {
            !!detail && <div className="detail">
                <div className="part part1">
                    <div className="title">
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-title-left.png" alt="" />
                        <div className="word">赛事简介</div>
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-title-right.png" alt="" />
                    </div>
                    <div className="word">
                        {
                            dataList?.word.map(item => {
                                return <div className="word-item" key={item} dangerouslySetInnerHTML={{ __html: item }} />
                            })
                        }
                    </div>
                    <div className="word2">
                        {
                            dataList?.word2.map(item => {
                                return <div className="word2-item" key={item}>{item}</div>
                            })
                        }
                    </div>
                    <div className="date">
                        <div className="date-title common-title">赛事赛程</div>
                        <div className="date-content">
                            <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-date-line-left.png" alt="" />
                            <div className="date-line">
                                {
                                    dataList?.dateList.map(item => {
                                        return <div className="date-item" key={item.id}>
                                            <div className="circle">
                                                {
                                                    item.word.map(word => {
                                                        return <div className="word" key={word}>{word}</div>
                                                    })
                                                }
                                            </div>
                                            <div className="date-word">{item.date}</div>
                                        </div>
                                    })
                                }
                            </div>
                            <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-date-line-right.png" alt="" />
                        </div>
                    </div>
                    <div className="group">
                        <div className="title">
                            <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-title-left.png" alt="" />
                            <div className="word">赛事组别</div>
                            <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-title-right.png" alt="" />
                        </div>
                        <div className="group-item">
                            <div className="group-title">
                                <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-part1-border2-title-left.png" alt="" />
                                {dataList?.group.name}
                                <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-part1-border2-title-right.png" alt="" />
                            </div>
                            <div className="wrap">
                                {
                                    dataList?.group.list.map((item) => {
                                        return <div key={item.id} onClick={() => toRule(1, item.id)} className="border">
                                            <img src={item.icon} alt="" />
                                            <div className="word">{item.word}</div>
                                            <div className="tip">{item.tip}</div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className="group-item">
                            <div className="group-title">
                                <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-part1-border2-title-left.png" alt="" />
                                {dataList?.group2.name}
                                <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-part1-border2-title-right.png" alt="" />
                            </div>
                            <div className="wrap">
                                {
                                    dataList?.group2.list.map(item => {
                                        return <div key={item.id} onClick={() => toRule(2, item.id)} className="border">
                                            <img src={item.icon} alt="" />
                                            <div className="word">{item.word}</div>
                                        </div>
                                    })
                                }
                            </div>

                        </div>
                    </div>
                </div>

                <div className="part part2">
                    <div className="title">
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-title-left.png" alt="" />
                        <div className="word">赛事奖金</div>
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-title-right.png" alt="" />
                    </div>
                    <div className="prize">
                        {
                            dataList?.prize.map(item => {
                                return <div className="prize-item" key={item.id}>
                                    {
                                        !!item.title && <div className="title common-title">{item.title}</div>
                                    }
                                    <div className="prize-title ">
                                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-price-title-left.png" alt="" />
                                        {item.name}
                                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-price-title-right.png" alt="" />
                                    </div>
                                    {
                                        !!item.prize && <div className="third-title">
                                            <div className="price-num">{item.prize.name}</div>
                                            <div className="money-wrap">
                                                <span>¥</span>
                                                <span>{item.prize.money}</span>
                                                <span>,</span>
                                                <span>000</span>
                                            </div>
                                        </div>
                                    }
                                    <div className="prize-content">
                                        {
                                            item.list.map((sub, index) => {
                                                return <div className="prize-content-item" key={index}>
                                                    {
                                                        sub.map((prize => {
                                                            return <div className={`border ${sub.length === 5 ? 'border2' : ""}`} key={prize.id}>
                                                                <div className="name">
                                                                    <span>{prize.name}</span>
                                                                    *
                                                                    <span>{prize.num}</span>
                                                                </div>
                                                                <div className="prize-line" />
                                                                <div className="money-wrap">
                                                                    <span>¥</span>
                                                                    <span>{prize.money}</span>
                                                                    <span>,</span>
                                                                    <span>{prize.money2 ? prize.money2 : "000"}</span>
                                                                </div>
                                                            </div>
                                                        }))
                                                    }
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>

                <div className="part part3">
                    <div className="judge-wrap">
                        <div className="title">
                            <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-title-left.png" alt="" />
                            <div className="word">赛事评委</div>
                            <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-title-right.png" alt="" />
                        </div>
                        <div className="sub-title">
                            <div>*评委顺序不分先后</div>
                            <div>*信息如有调整，将另行公告</div>
                        </div>
                        {
                            dataList.judge.map(item => {
                                return <div className="judge" key={item.id}>
                                    <div className="judge-title">
                                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-price-title-left.png" alt="" />
                                        {item.title}
                                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-price-title-right.png" alt="" />
                                    </div>
                                    {
                                        item.list.map((sub, index) => {
                                            return <div className="judge-item" key={index}>
                                                {
                                                    sub.map((judge => {
                                                        return <div className="border" key={judge.id}>
                                                            <img src={judge.cover} alt="" />
                                                            <div className="name">{judge.name}</div>
                                                            <div className="desc">
                                                                {
                                                                    judge.desc.map(desc => {
                                                                        return <div key={desc}>{desc}</div>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    }))
                                                }
                                            </div>
                                        })
                                    }
                                </div>
                            })
                        }
                    </div>
                    <div className="title">
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-title-left.png" alt="" />
                        <div className="word">赛事须知</div>
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-title-right.png" alt="" />
                    </div>
                    <div className="rule">
                        {
                            dataList?.rule1.map((item, index) => {
                                return <div className="rule-item" key={item}>
                                    <div>{index + 1}.</div>
                                    <div>{item}</div>
                                </div>
                            })
                        }
                    </div>
                    <div className="title">
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-title-left.png" alt="" />
                        <div className="word">组织架构</div>
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-title-right.png" alt="" />
                    </div>
                    <div className="rule2">
                        {
                            dataList?.rule2.map((item) => {
                                return <div className="rule2-item" key={item.label}>
                                    <div className="label">{item.label}:</div>
                                    <div className="value">{item.value}</div>
                                </div>
                            })
                        }
                    </div>
                    <div className="bottom">
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ycwl3/ycwl3-part3-bottom.png" alt="" />
                    </div>
                </div>

            </div>
        }

    </Ycwl3DetailStyle>
}

export default Ycwl3Detail